import React, {useState, useEffect} from "react";
import {getCookie, isAuth} from "../../../auth/helpers";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Container, Dialog, Slide, TextField, CircularProgress} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";
import AddSchoolFormDialog from "../Schools/Components/AddFormDialog";
//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from "axios";
import CustomAutocomplete from "../../../components/CustomAutocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const RepresentativeVisits = ({schoolId, handleAddVisit}) => {
  const user = isAuth();
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [RowID, setRowID] = useState(0); //current row
  const [modal_Title, setmodal_Title] = useState("Add"); //modal title
  const [formTitle, setFormTitle] = useState("Add Visit");
  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [schools, setSchools] = useState([]);
  const [pagingInfo, setPagingInfo] = useState({
    page: 0,
    limit: 20,
    skip: 0,
    count: 20,
  }); //Pagination Info
  const [searchEntry, setSearchEntry] = useState(""); //searchEntry
  const [openSchool, setOpenSchool] = useState(false);
  const handleCloseSchool = () => {
    setOpenSchool(false);
  };
  const [users, setUsers] = useState([]);
  const [usersFilter, setUsersFilter] = useState([]);
  const [schoolsFilter, setSchoolsFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/representativeVisits?limit=${pagingInfo.limit}&skip=${
          pagingInfo.skip
        }&searchEntry=${searchEntry}&schoolId=${schoolId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            schoolsFilter: schoolsFilter.toString(),
            dateFilter: dateFilter,
            usersFilter: usersFilter.toString(),
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.count});
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [open, pagingInfo.page, pagingInfo.limit, searchEntry, schoolsFilter, dateFilter, usersFilter]);

  const getSchools = async () => {
    axios(
      `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/schools`,
      {
        responseType: "json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      const data = response?.data?.data;
      setSchools(data);
    });
  };

  const fetchUsers = async () => {
    await axios(
      `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/users?profile=2`,
      {
        responseType: "json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      const data = response?.data?.data;
      setUsers(data);
    });
  };

  useEffect(() => {
    if (schools.length == 0) getSchools();
    if (users.length == 0) fetchUsers();
  }, []);

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "user_name",
      label: "Representative",
    },
    {
      name: "school_name",
      label: "School Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleAdd("Edit Visit - " + tableMeta.rowData[3], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {name: "date"},

    {name: "meeting", label: "Meeting with"},

    {name: "action_taken", label: "Action Taken"},

    {name: "remarks", label: "Remarks"},
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          // add={schoolId}
          listener={() => {
            if (schoolId) {
              handleAddVisit();
            } else {
              handleAdd("Add Visit");
            }
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/representativeVisits/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // setItems(response?.data?.data);

          toast.success(response.data.Message);
          setSearchEntry("");
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
    // serverSide: true,
    // count:pagingInfo.count, // Use total number of items
    // page: pagingInfo.page,
    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPagingInfo({...pagingInfo,page:tableState.page,skip:tableState.page*pagingInfo.limit});
    //   }else if(action === "changeRowsPerPage"){
    //     setPagingInfo({...pagingInfo,limit:tableState.rowsPerPage});
    //   }
    // }
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };

  const handleClickOpen = (rowID = 1, modal_Title = "Add") => {
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, testimonialId) => {
    if (testimonialId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/representativeVisits/${testimonialId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          const data = response.data;

          setFormValues({
            serial: data.serial,
            date: data.date,
            school_serial: data.school_serial,
            action_taken: data.action_taken,
            meeting: data.meeting,
            delivering: data.delivering == 1,
            remarks: data.remarks,
          });
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        date: "",
        school_serial: "",
        action_taken: "",
        meeting: "",
        delivering: "",
        remarks: "",
      });
      setFormTitle(title);
      setOpen(true);
    }

    //setTestimonialId(testimonialId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Search component ---------------START--------------
  const handleChangeSearch = (e, newValue) => {
    setSearchEntry(newValue);
  };
  //Search component ---------------END--------------

  const customOnChangeSchoolsFilter = (newValue, name) => {
    setSchoolsFilter(newValue);
  };

  const customOnChangeUsersFilter = (newValue, name) => {
    setUsersFilter(newValue);
  };
  return schoolId ? (
    <>
      {!isLoading ? (
        <MuiThemeProvider theme={datatableTheme}>
          <MUIDataTable title="" data={items} columns={columns} options={options} />
        </MuiThemeProvider>
      ) : (
        <CircularProgress size={30} className="pageLoader" />
      )}{" "}
    </>
  ) : (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5 pt-5">
        <div className="row mb-5">
          <div className="col-sm-4">
            <CustomAutocomplete
              filedName="schoolsFilter"
              label="Filter by schools"
              list={schools}
              values={schoolsFilter}
              listKey="serial"
              description="name"
              customOnChange={customOnChangeSchoolsFilter}
            />
          </div>

          <div className="col-sm-4">
            <CustomAutocomplete
              filedName="usersFilter"
              label="Filter by representative"
              list={users}
              values={usersFilter}
              listKey="id"
              description="name"
              customOnChange={customOnChangeUsersFilter}
            />
          </div>

          <div className="col-sm-4">
            <label htmlFor="date"></label>
            <input
              type="date"
              className="form-control"
              id="date"
              placeholder="Date"
              onChange={(e) => setDateFilter(e.target.value)}
              defaultValue={dateFilter}
            />
          </div>
        </div>

        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AddFormDialog
              schools={user.profile == 2 ? schools.filter((school) => school.user_id == user._id) : schools}
              setOpenSchool={setOpenSchool}
              openSchool={openSchool}
              title={formTitle}
              handleClose={handleClose}
              formValues={formValues}
            />
          </Dialog>

          <Dialog fullScreen open={openSchool} onClose={handleCloseSchool} TransitionComponent={Transition}>
            <AddSchoolFormDialog
              getSchools={getSchools}
              title={"Add School"}
              handleClose={handleCloseSchool}
              formValues={[]}
            />
          </Dialog>

          {/*********************** FILTER start ****************************/}
          {/* <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} />
        </Dialog> */}
        </div>
      </div>
    </AdminLayout>
  );
};
export default RepresentativeVisits;
