import React from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";
import {countries} from "../../../../data/countries";
import {useState} from "react";
const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit, getValues, watch} = useForm();
  const [countriesList, setCountriesList] = useState(countries);
  const watchProfile = watch("profile");

  const handleOnSubmit = async (data) => {
    // console.log(props.formValues);
    // return;
    if (props.title.substring(0, 4) === "Edit") {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/users/${props.formValues.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/users`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  defaultValue={props.formValues.name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  {...register("email")}
                  defaultValue={props.formValues.email}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  {...register("password")}
                  defaultValue={props.formValues.password}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="profile">Profile</label>
                <select
                  className="form-select"
                  id="profile"
                  {...register("profile")}
                  defaultValue={props.formValues.profile}
                >
                  <option value={1}>Admin</option>
                  <option value={2}>Representative</option>
                </select>
              </div>
            </div>

            {watchProfile == 2 && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="country">Country</label>
                  <select
                    className="form-select"
                    id="country"
                    {...register("country")}
                    defaultValue={props?.formValues?.country}
                  >
                    {countriesList.map((e) => (
                      <option value={e.name} key={e.serial}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="col-12 d-flex flex-row-reverse">
              <button
                // type="button"
                className="btn btn-lg btn-primary"
                type="submit"
              >
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
