import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {CircularProgress} from "@material-ui/core";
import {getCookie} from "../../../auth/helpers";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";

const StudentsShareModal = (props) => {
  const token = getCookie("token");
  const quizId = props.selectedQuiz.serial;
  const [quizStudentsList, setQuizStudentsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studentsList, setStudentsList] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (props.show === true) {
        await axios
          .all([
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/myclass/${props.selectedQuiz.class_id}`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/quiz/students`
            ),
          ])
          .then((response) => {
            setStudentsList(response[0].data.myclass_students);
            if (response[1]?.data?.data.filter((e) => e.quizId == quizId).length) {
              setQuizStudentsList(response[1]?.data?.data.filter((e) => e.quizId == quizId));
            } else {
              setQuizStudentsList(
                response[0].data.myclass_students.map((e) => ({
                  studentId: e.serial,
                  quizId: quizId,
                  classId: e.myclass_id,
                  status: "new",
                }))
              );
            }
            setIsLoading(false);
          });
      }
    };
    fetchData();
  }, [props.show]);

  const handleChecked = async (event, eSub) => {
    const selectedRow = quizStudentsList.find((eSub) => eSub.studentId == event.serial);
    if (selectedRow && !eSub.target.checked) {
      if (selectedRow.status === "new") {
        setQuizStudentsList(quizStudentsList.filter((e) => e.studentId !== event.serial));
      } else {
        setQuizStudentsList(
          quizStudentsList.map((e) => {
            if (e.studentId == event.serial) e.status = "delete";
            return e;
          })
        );
      }
    } else if (!selectedRow && eSub.target.checked) {
      setQuizStudentsList([
        ...quizStudentsList,
        {
          studentId: event.serial,
          quizId: quizId,
          classId: event.myclass_id,
          email: event.email,
          status: "new",
        },
      ]);
    } else if (selectedRow && eSub.target.checked) {
      if (selectedRow.status === "delete") {
        setQuizStudentsList(
          quizStudentsList.map((e) => {
            if (e.studentId == event.serial) delete e.status;
            return e;
          })
        );
      }
    }
  };

  const handleSaveForm = async () => {
    setIsLoading(true);
    const dataToDelete = quizStudentsList
      .filter((e) => e.status === "delete")
      .map((e) => {
        delete e.status;
        return e;
      });
    if (dataToDelete.length) {
      dataToDelete.forEach((e) => {
        axios.delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/quiz/students/${e.serial}`,
          {
            responseType: "json",
            headers: {Authorization: `Bearer ${token}`},
          }
        );
      });
    }

    const dataToSave = quizStudentsList
      .filter((e) => e.status === "new")
      .map((e) => {
        delete e.status;
        e.mark = "";
        return e;
      });

    if (quizStudentsList.length) {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/quiz/students`,
        headers: {Authorization: `Bearer ${token}`},
        data: {data: dataToSave},
      })
        .then((response) => {
          toast.success("Saved Successfully");
          toast.info("Sharing Quiz Please Be Patient");
          // sendEmail(dataToSave[0]['quizId']);
          handleShareAllStudent(quizStudentsList[0]["quizId"]);
          setQuizStudentsList([]);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Error While Saving");
          console.log(error);
        });
    } else {
      setQuizStudentsList([]);
      setIsLoading(false);
      props.onHide();
    }
  };
  const sendEmail = async (quizId) => {
    // data.forEach(e => {
    //   const quizIdForLink = String(e.serial) + (Number(e.quizId) + Number(e.studentId) + Number(e.classId));
    //   axios.get(`${window?.location?.hostname?.includes('maroc') ? process.env.REACT_APP_API_MAROC : process.env.REACT_APP_API}/email/quizdone/${quizIdForLink}/${e.email}`)
    // })
  };
  const [isSharingAll, setIsSharingAll] = useState(false);
  const handleShareAllStudent = async (quizId) => {
    setIsSharingAll(true);

    await axios
      .post(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/email/quiz/${quizId}`
      )
      .then((response) => {
        toast.success("Emails Sent To All Students");
        setIsSharingAll(false);
        props.onHide();
      })
      .catch((response) => {
        setIsSharingAll(false);
        toast.error("Error While Sending Emails");
      });
  };
  return (
    <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" width="50">
                  #
                </th>
                <th scope="col">Student</th>
                <th scope="col">Email</th>
                <th scope="col">Checked</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {studentsList.map((e, i) => {
                let currentQuizStudent = quizStudentsList.find((eSub) => eSub.studentId == e.serial);
                const quizIdForLink = currentQuizStudent
                  ? String(currentQuizStudent.serial) +
                    (Number(currentQuizStudent.quizId) +
                      Number(currentQuizStudent.studentId) +
                      Number(currentQuizStudent.classId))
                  : 0;

                return (
                  <tr key={e.serial}>
                    <td scope="row">{i + 1} </td>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={currentQuizStudent && currentQuizStudent.status !== "delete" ? true : false}
                        onChange={(eSub) => handleChecked(e, eSub)}
                      />
                    </td>
                    <td>
                      {currentQuizStudent && currentQuizStudent.serial ? (
                        <a href={`${process.env.PUBLIC_URL}/student/quiz/${quizIdForLink}`} target="_blank">
                          Check Quiz
                        </a>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isSharingAll ? <CircularProgress /> : <Button onClick={handleSaveForm}>Submit</Button>}
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default StudentsShareModal;
