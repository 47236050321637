import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from './helpers';

const VisitorRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => 
            isAuth() && isAuth().role !== 'admin' && (props.location.pathname==="/signin" || props.location.pathname==="/register1" || props.location.pathname==="/student/quiz" || props.location.pathname==="/register2" || props.location.pathname==="/register3") ? (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location }
                    }}
                />
            ) : (
                <Component {...props} />
            )
        }
    ></Route>
);

export default VisitorRoute;
