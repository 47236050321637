import React, {useState, useEffect} from "react";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import {getCookie} from "../../../auth/helpers";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Dialog, Slide, TextField, CircularProgress, Tooltip, IconButton} from "@material-ui/core";
import {ToastContainer, toast} from "react-toastify";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";

//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import BookChaptersFormDialog from "./Components/BookChaptersFormDialog.js";

import axios from "axios";
import ImportExcelModal from "./ImportExcelModal";
import {CloudUpload} from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Book = () => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [bookChaptersIsOpen, setBookChaptersIsOpen] = useState(false); //for modal
  const [RowID, setRowID] = useState(0); //current row
  const [modal_Title, setmodal_Title] = useState("Add"); //modal title
  const [formTitle, setFormTitle] = useState("Add Book");
  const [formTitle2, setFormTitle2] = useState("Add Book");
  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items

  const [importing, setImporting] = useState(false);
  const [pagingInfo, setPagingInfo] = useState({
    page: 0,
    limit: 20,
    skip: 0,
    count: 20,
  }); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [bookID, setBookID] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/books?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.count});
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [open, pagingInfo.page, pagingInfo.limit, searchEntry]);

  const [importExcelFor, setImportExcelFor] = useState("");
  const [importExcelForTitle, setImportExcelForTitle] = useState("");

  const importExcel = (type, title) => {
    setImportExcelFor(type);
    setImportExcelForTitle(title);

    setImportModalVisible(true);
  };
  const handleCloseImportExcel = (refresh) => {
    setImportModalVisible(false);
    handleAdd(formTitle, bookID);
  };

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "cover",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              src={process.env.REACT_APP_FILES_PATH + "/Books/" + value}
              alt=""
              className="img-thumbnail rounded"
              style={{maxWidth: "100px", maxHeight: "100px"}}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "Name/Title",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleAdd("Edit Book - " + tableMeta.rowData[2], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {name: "author"},
    {
      name: "description",
      options: {
        display: false,
      },
    },
    {
      name: "summaryPDF",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={process.env.REACT_APP_FILES_PATH + "/Books/" + value} target="_blank">
              <i className="fas fa-file-pdf"></i> Download PDF
            </a>
          );
        },
      },
    },
    {
      name: "bookPDF",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={process.env.REACT_APP_FILES_PATH + "/Books/" + value} target="_blank">
              <i className="fas fa-file-pdf"></i> Download PDF
            </a>
          );
        },
      },
    },
    {name: "numOfPages"},
    {name: "grade"},
    {name: "year"},
    {name: "category"},
    {name: "priority"},
    {
      name: "book_chapters_count",
      label: "Chapters",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleBooksChapters("Books Chapters - " + tableMeta.rowData[2], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <>
          <CustomToolbar
            listener={() => {
              handleAdd("Add Book");
            }}
          />
        </>
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/books/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setItems(response?.data?.data);
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
    // serverSide: true,
    // count:pagingInfo.count, // Use total number of items
    // page: pagingInfo.page,
    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPagingInfo({...pagingInfo,page:tableState.page,skip:tableState.page*pagingInfo.limit});
    //   }else if(action === "changeRowsPerPage"){
    //     setPagingInfo({...pagingInfo,limit:tableState.rowsPerPage});
    //   }
    // }
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };

  const handleClickOpen = (rowID = 1, modal_Title = "Add") => {
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);
  };

  const [formValues, setFormValues] = useState({});
  const [formBookChapters, setFormBookChapters] = useState({});
  const [formBookChaptersBookId, setFormBookChaptersBookId] = useState({});
  const handleAdd = async (title, booksId) => {
    if (booksId) {
      setBookID(booksId);
      await axios({
        method: "GET",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/books/${booksId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setFormValues(response.data);
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        cover: "",
        summaryPDF: "",
        bookPDF: "",
        author: "",
        name: "",
        description: "",
        category: "",
        grade: "",
        year: "",
        numOfPages: "",
      });
      setFormTitle(title);
      setOpen(true);
    }

    //setBookId(bookId);
  };

  const handleBooksChapters = async (title, booksId) => {
    if (booksId) {
      await axios({
        method: "GET",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/books/get-chapters/${booksId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setFormBookChaptersBookId(booksId);
          setFormBookChapters(response.data);
          setFormTitle2(title);
          setBookChaptersIsOpen(true);
        })
        .catch((msg) => {
          toast.error("Error While Fetching Data");
          console.log(msg);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseBooksChapters = () => {
    setBookChaptersIsOpen(false);
  };

  //Search component ---------------START--------------
  const handleChangeSearch = (e, newValue) => {
    setSearchEntry(newValue);
  };
  //Search component ---------------END--------------
  return (
    <AdminLayout>
      <div className="m-5 pt-5">
        <ToastContainer />
        {/* <Autocomplete
        multiple
        freeSolo
        limitTags={3}
        id="tags-standard"
        options={[]}
        getOptionLabel={(option) => option}
        onChange={handleChangeSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Data"
            label="Filter by Name"
          />
        )}
      /> */}

        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} fullWidth />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AddFormDialog
              importModalVisible={importModalVisible}
              importExcel={importExcel}
              title={formTitle}
              handleClose={handleClose}
              formValues={formValues}
              importing={importing}
              setImporting={setImporting}
            />
          </Dialog>

          <Dialog
            fullScreen
            open={bookChaptersIsOpen}
            onClose={handleCloseBooksChapters}
            TransitionComponent={Transition}
          >
            <BookChaptersFormDialog
              title={formTitle2}
              handleClose={handleCloseBooksChapters}
              formBookChapters={formBookChapters}
              bookId={formBookChaptersBookId}
            />
          </Dialog>
          {/*********************** FILTER start ****************************/}
          {/* <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} />
        </Dialog> */}
          <Dialog
            fullWidth
            maxWidth={"md"}
            open={importModalVisible}
            onClose={handleCloseImportExcel}
            TransitionComponent={Transition}
          >
            <ImportExcelModal
              bookID={bookID}
              importExcelFor={importExcelFor}
              importExcelForTitle={importExcelForTitle}
              title="Import Excel"
              visible={importModalVisible}
              handleClose={handleCloseImportExcel}
              onClose={handleCloseImportExcel}
              importing={importing}
              setImporting={setImporting}
            />
          </Dialog>
        </div>
      </div>
    </AdminLayout>
  );
};
export default Book;
