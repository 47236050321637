import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const areEqual = (prevProps, nextProps) => {
  if (prevProps.values != nextProps.values || prevProps.list != nextProps.list){
    return false
  }
  return true;
 };

const CustomAutocomplete = props => {
  const filedName = props.filedName;
  const fieldLabel = props.label;
  const list = props.list;
  const fieldValues = props.values;
  const listKey = props.listKey;
  const listDescription = props.description;
  const customOnChange = props.customOnChange;
  console.log(fieldValues);

  return (
  <Autocomplete
      name={filedName}
      options={list} // Options List
      value={fieldValues}
      getOptionSelected={(option, value) => (value == option[listKey])}
      multiple
      onChange={(event, newValue) => {
        const newList = newValue?newValue.map((v)=>v[listKey]?v[listKey]:v):[];
        customOnChange(newList,filedName,event);
      }}
      
      getOptionLabel={(option) => option[listDescription]}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label={fieldLabel} />}
      renderTags={(tagValue)=>{
         const fillteredList = list.filter((v)=>(tagValue.includes(v[listKey]) || tagValue.includes(`${v[listKey]}`)))
         
      //    return (<div className={classes.chips}>
      //     {fillteredList.map((value) => (
      //       <Chip
      //         key={value[listKey]}
      //         label={value[listKey]}
      //         className={classes.chip}
      //       />
      //     ))}
          return (<div>
          {fillteredList.map((value) => (
            <Chip
              key={value[listKey]}
              label={value[listDescription]}
            />
          ))}
        </div>)
      }}
      />
      );
}

export default CustomAutocomplete;