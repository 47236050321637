import React, {useEffect} from "react";


const EmailConfirmed = ({history}) => {

    useEffect(() => {
        history.push({ pathname: "/", state: { type: "Email Confirmed!",message: "Your email has been confirmed. You can now use your credentials to log in." } });

      }, []);

    return <div></div>}
  export default EmailConfirmed