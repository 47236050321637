import React, {useState, useEffect} from "react";
import Layout from "../../core/Layout";
import {CircularProgress} from "@material-ui/core";
import {getCookie, isAuth} from "../../auth/helpers";
import {confirm} from "react-bootstrap-confirmation";
import {ToastContainer, toast} from "react-toastify";
import StudentsShareModal from "./Components/StudentsShareModal";
import StudentsQuizListModal from "./Components/StudentsQuizListModal";
import moment from "moment";
import axios from "axios";

const QuizzesList = (props) => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [quizzesList, setQuizzesList] = useState([]);
  const [quizQuestionsList, setQuizQuestionsList] = useState([]);
  const [quizStudentsList, setQuizStudentsList] = useState([]);
  const [chaptersList, setChaptersList] = useState([]);
  const [modalShareShow, setModalShareShow] = useState(false);
  const [modalListShow, setModalListShow] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quizs?teacher_id=${isAuth()._id}`,
            {
              headers: {Authorization: `Bearer ${token}`},
            }
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/questions`,
            {
              headers: {Authorization: `Bearer ${token}`},
            }
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/students`,
            {
              headers: {Authorization: `Bearer ${token}`},
            }
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/chapters`
          ),
        ])
        .then((response) => {
          setQuizzesList(response[0]?.data?.data);
          setQuizQuestionsList(response[1]?.data?.data);
          setQuizStudentsList(response[2]?.data?.data);
          setChaptersList(response[3]?.data?.data);
          setIsLoading(false);
        });
    };
    fetchData();
  }, [modalShareShow]);

  const handleDelete = async (myQuizId) => {
    const result = await confirm("Are you sure you want to delete this entry?");
    if (result === true) {
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/quizs/${myQuizId}?teacher_id=${isAuth()._id}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setQuizzesList(response?.data?.data);
          toast.success("Successfully Deleted");
        })
        .catch((err) => {
          toast.error("Error While Deleting");
          console.error(err);
        });
    }
  };

  const handleShare = async (e) => {
    setSelectedQuiz(e);
    setModalShareShow(true);
  };
  const handleListStudents = async (e) => {
    setSelectedQuiz(e);
    setModalListShow(true);
  };
  return (
    <Layout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
        draggable
        pauseOnHover
      />
      <div className="container pt-5 mt-5">
        <div id="register2" className="py-5 my-5">
          <div className="d-flex flex-column pt-5 mt-5">
            <div className="text-center">
              <h3 className="text-secondary mb-0">Teacher's Quiz</h3>
              <h2 className="text-primary mb-5">Quizzes List</h2>
            </div>
            <div className="d-flex ">
              <a className="btn btn-secondary ml-auto" href={`${process.env.PUBLIC_URL}/quizCreate`}>
                Create new Quiz
              </a>
            </div>
            {!isLoading ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" width="2%">
                      #
                    </th>
                    <th scope="col" width="20%">
                      Chapter
                    </th>
                    <th scope="col" width="20%">
                      Name
                    </th>
                    <th scope="col" width="10%">
                      Quiz Grade
                    </th>
                    <th scope="col" width="10%">
                      Nbr of Questions
                    </th>
                    <th scope="col" width="8%">
                      Nbr of Students
                    </th>
                    <th scope="col" width="10%">
                      Date
                    </th>
                    <th scope="col" width="20%"></th>
                  </tr>
                </thead>
                <tbody>
                  {quizzesList.length ? (
                    quizzesList.map((e, i) => {
                      const quizQuestions = quizQuestionsList.filter((eSub) => eSub.quiz_id === e.serial);
                      const quizMark = quizQuestions.length ? quizQuestions.map((e) => e.mark) : {};

                      let chapter = chaptersList.length
                        ? chaptersList.filter((eSub) => eSub.serial == e.chapter_id)
                        : [];
                      const studentsNumber = quizStudentsList.filter((eSub) => eSub.quizId == e.serial);
                      const studentsDidExamNumber = studentsNumber.length
                        ? studentsNumber.filter((eSub) => eSub.mark != null).length
                        : 0;

                      return (
                        <tr key={i}>
                          <td scope="row">{i + 1}</td>
                          <td>{chapter.length ? chapter[0].name : ""}</td>
                          <td>
                            <a href={`${process.env.PUBLIC_URL}/quiz/edit/${e.serial}`}>{e.name}</a>
                          </td>
                          <td>{quizMark.length ? quizMark.reduce((a, b) => (a && b ? a + b : a)) : 0}</td>
                          <td>{quizQuestions.length}</td>
                          <td onClick={() => handleListStudents(e)} role="button" className="link-primary">
                            {studentsDidExamNumber}/{studentsNumber.length}
                          </td>
                          <td style={{fontSize: ".85em"}}>{moment(e.fromDate).format("DD MMM YYYY")}</td>
                          <td>
                            <a
                              href={`${process.env.PUBLIC_URL}/quiz/edit/${e.serial}`}
                              title="Edit Quiz"
                              className="btnWithIcons"
                            >
                              <i className="fas fa-edit text-primary"></i>
                            </a>
                            <a
                              href={`${process.env.PUBLIC_URL}/quiz/clone/${e.serial}`}
                              title="Clone Quiz"
                              className="btnWithIcons"
                            >
                              <i className="fas fa-clone text-primary"></i>
                            </a>
                            <a
                              href={`${process.env.PUBLIC_URL}/questionsList/${e.serial}`}
                              title="Add questions"
                              className="btnWithIcons"
                            >
                              <i className="fas fa-plus-circle text-primary"></i>
                            </a>
                            <button
                              onClick={() => handleShare(e)}
                              title="Share Quiz"
                              className="btnWithIcons"
                            >
                              <i className="fas fa-share text-primary"></i>
                            </button>
                            <button
                              onClick={() => handleDelete(e.serial)}
                              title="Delete Quiz"
                              className="btnWithIcons"
                            >
                              <i className="fas fa-times text-danger"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      <small>No records found</small>
                    </div>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="text-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
      <StudentsShareModal
        show={modalShareShow}
        onHide={() => setModalShareShow(false)}
        selectedQuiz={selectedQuiz}
      />
      <StudentsQuizListModal
        show={modalListShow}
        onHide={() => setModalListShow(false)}
        selectedQuiz={selectedQuiz}
      />
    </Layout>
  );
};
export default QuizzesList;
