import React, {useState, useEffect} from "react";
import Layout from "../../core/Layout";
import {CircularProgress} from "@material-ui/core";
import {getCookie, isAuth} from "../../auth/helpers";
import Button from "react-bootstrap/Button";
import axios from "axios";
import ClassesAddModel from "./Components/ClassesAddModel";
import {confirm} from "react-bootstrap-confirmation";
import {ToastContainer, toast} from "react-toastify";

const ClassesList = (props) => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [classesList, setClassesList] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [formTitle, setFormTitle] = useState("Add My Class");

  const [formValues, setFormValues] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass`,
        {
          responseType: "json",
          headers: {Authorization: `Bearer ${token}`},
        }
      ).then((response) => {
        const responseFromDb = response?.data?.data;
        setClassesList(
          responseFromDb.length ? responseFromDb.filter((e) => e.teacher_id === isAuth()._id) : []
        );
        setIsLoading(false);
      });
    };
    fetchData();
  }, [modalShow]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/grades`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/students`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
        ])
        .then((response) => {
          setGradesList(response[0]?.data?.data);
          setStudentsList(response[1]?.data?.data);
        });
    };
    fetchData();
  }, []);
  const handleEdit = async (myclassId) => {
    await axios(
      `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/myclass/${myclassId}`,
      {
        responseType: "json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      setFormValues(response.data);
      setFormTitle("Edit Class");
      setModalShow(true);
    });
  };
  const handleDelete = async (myclassId) => {
    const result = await confirm("Are you sure you want to delete this entry?");
    if (result === true) {
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/myclass/${myclassId}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const responseFromDb = response?.data?.data;
          setFormValues(responseFromDb);
          setClassesList(
            responseFromDb.length ? responseFromDb.filter((e) => e.teacher_id === isAuth()._id) : []
          );
          toast.success(response.data.Message);
        })
        .catch((err) => {
          toast.error("Error While Deleting");
          console.error(err);
        });
    }
  };
  return (
    <Layout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
      />
      <div className="container pt-5 mt-5">
        <div id="register2" className="py-5 my-5">
          <div className="d-flex flex-column pt-5 mt-5">
            <div className="text-center">
              <h3 className="text-secondary mb-0">Teacher's Class</h3>
              <h2 className="text-primary mb-5">Classes List</h2>
            </div>
            <div className="d-flex ">
              <Button className="btn btn-secondary ml-auto" onClick={() => setModalShow(true)}>
                Create new Class
              </Button>
              <ClassesAddModel
                show={modalShow}
                onHide={() => setModalShow(false)}
                title={formTitle}
                formValues={formValues}
                setFormValues={setFormValues}
                classId={props.match.params.classId}
                studentsList={studentsList}
                gradesList={gradesList}
                modalShow={modalShow}
              />
            </div>
            {!isLoading ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>

                    <th scope="col">Grade</th>
                    <th scope="col">Section</th>
                    <th scope="col">School</th>
                    <th scope="col">Nbr of Students</th>
                    <th width="100"></th>
                  </tr>
                </thead>
                <tbody>
                  {classesList.map((e, i) => {
                    const grade = gradesList.filter((eSub) => eSub.serial === e.grade_id);
                    return (
                      <tr>
                        <th scope="row">{i + 1}</th>
                        <td>{grade.length ? grade[0].name : "-"}</td>
                        <td>{e.name}</td>

                        <td>{e.school}</td>
                        <td>{e.Myclass_students.length}</td>
                        <td>
                          <button
                            onClick={() => handleEdit(e.serial)}
                            title="Edit Class"
                            className="btnWithIcons"
                          >
                            <i className="fas fa-edit text-primary"></i>
                          </button>
                          <button
                            onClick={() => handleDelete(e.serial)}
                            title="Delete Class"
                            className="btnWithIcons"
                          >
                            <i className="fas fa-times text-danger"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="text-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ClassesList;
