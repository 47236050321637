import React from "react";
import {AppBar, Toolbar, TextField, CircularProgress} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useState} from "react";
import {useEffect} from "react";
import {Autocomplete} from "@material-ui/lab";
import {toast} from "react-toastify";
const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit} = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [category, setCategory] = useState([]);
  const [target, setTarget] = useState([]);
  const [booksList, setBooksList] = useState([]);
  const [books, setBooks] = useState([]);
  const [allBooks, setAllBooks] = useState(0);
  const targets = [
    {serial: 1, name: "Teachers"},
    {serial: 2, name: "Students"},
    {serial: 3, name: "Both"},
  ];

  useEffect(() => {
    setBooks(props.formValues.books);
    let foundTarget = targets.find((x) => x.serial == props.formValues.target);
    if (foundTarget) setTarget(foundTarget);

    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/books`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setBooksList(response?.data?.data);
      });

      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/extra_resources_categories`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setCategoriesList(response?.data?.data);

        const chosenCategory = response?.data?.data.filter(
          (category) => category.serial == props.formValues.category_id
        );

        setCategory(chosenCategory[0]);
      });
    };
    fetchData();
  }, []);

  const handleSelectAllBooks = () => {
    setAllBooks(1);
    setBooks(booksList);
  };
  const handleRemoveAllBooks = () => {
    setAllBooks(0);
    setBooks([]);
  };

  const handleOnSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    if (data.icon && data.icon[0]) formData.append("icon", data.icon[0]);
    if (data.attachment && data.attachment[0]) formData.append("attachment", data.attachment[0]);
    if (data.description) formData.append("description", data.description);
    if (category) formData.append("category_id", category.serial);
    if (target) formData.append("target", target.serial);
    if (data.link) formData.append("link", data.link);
    if (data.description) formData.append("description", data.description);
    if (data.priority) formData.append("priority", data.priority);

    books?.map((book) => formData.append("books[]", JSON.stringify(book)));

    if (props.title.substring(0, 4) === "Edit") {
      formData.append("_method", "PUT");
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/extra_resources/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props.handleClose();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error.response);
          if (error?.response?.data?.description) toast.error(error.response.data.description[0]);
          setIsLoading(false);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/extra_resources`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props.handleClose();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error.response);
          if (error?.response?.data?.description) toast.error(error.response.data.description[0]);
          setIsLoading(false);
        });
    }
  };
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="categories"
                  fullWidth
                  options={categoriesList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setCategory({
                      serial: newValue.serial,
                      name: newValue.name,
                    });
                  }}
                  value={category}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Category" placeholder="Category" />
                  )}
                />
              </div>
            </div>

            <div className="col-sm-6">
              {booksList && booksList?.length > 0 && (
                <div className="form-group">
                  <Autocomplete
                    multiple
                    id="books"
                    fullWidth
                    options={booksList}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setBooks(
                        newValue.map((e) => ({
                          serial: e.serial,
                          name: e.name,
                        }))
                      );
                    }}
                    value={books}
                    filterSelectedOptions
                    renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="Books" placeholder="Books" />
                    )}
                  />
                  {allBooks === 0 ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelectAllBooks();
                      }}
                    >
                      <i className="fa fa-square"></i> Select All Books
                    </a>
                  ) : (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveAllBooks();
                      }}
                    >
                      <i className="fa fa-check-square"></i> Remove All Books
                    </a>
                  )}
                </div>
              )}
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Link</label>
                <input
                  type="text"
                  className="form-control"
                  id="link"
                  placeholder="Link"
                  {...register("link")}
                  defaultValue={props.formValues.link}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="attachment">
                  Attachment
                  {props.formValues.attachment ? (
                    <a
                      href={
                        process.env.REACT_APP_FILES_PATH + "/ExtraResources/" + props.formValues.attachment
                      }
                      target="_blank"
                    >
                      {" "}
                      - <i className="fas fa-file-pdf"></i> Check current attachment
                    </a>
                  ) : null}
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="attachment"
                  placeholder="Attachment"
                  {...register("attachment")}
                />
              </div>
            </div>

            <div className="col-sm-6">
              {props.formValues.icon ? (
                <img
                  style={{width: 50, height: 50}}
                  src={process.env.REACT_APP_FILES_PATH + "/ExtraResources/" + props.formValues.icon}
                  alt=""
                />
              ) : null}

              <div className="form-group">
                <label htmlFor="icon">Icon</label>
                <input
                  type="file"
                  className="form-control"
                  id="icon"
                  placeholder="Icon"
                  {...register("icon")}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  {...register("description")}
                  defaultValue={props.formValues.description}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <input
                  type="text"
                  className="form-control"
                  id="priority"
                  placeholder="Priority number"
                  {...register("priority")}
                  defaultValue={props.formValues.priority}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="target"
                  fullWidth
                  options={targets}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setTarget({
                      serial: newValue.serial,
                      name: newValue.name,
                    });
                  }}
                  value={target}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Target" placeholder="Target" />
                  )}
                />
              </div>
            </div>

            {isLoading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="col-12 d-flex flex-row-reverse">
                <button className="btn btn-lg btn-primary" type="submit">
                  <i className="fas fa-save"></i> Save
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                  <i className="fas fa-times"></i> Close
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
