import React, {useState, useEffect} from "react";
import {Dialog, AppBar, Toolbar, Slide} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import CustomToolbar from "../../../../components/CustomToolbar";
import {ToastContainer, toast} from "react-toastify";
import {useForm} from "react-hook-form";

import AddQuiz from "./AddQuiz.js";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../../components/datatable-theme.js";
import {MuiThemeProvider} from "@material-ui/core/styles";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuizsComponent = (props) => {
  const token = getCookie("token");
  const [openAddQuiz, setOpenAddQuiz] = useState(false);

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              onClick={() => {
                handleOpenAddQuiz("Edit Chapters - " + tableMeta.rowData[2], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {name: "note", label: "Remarks"},
    {
      name: "teacher_id",
      label: "Teacher",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let teacherObj = props.teachersList.filter((e) => e.serial === value);
          return teacherObj.length ? teacherObj[0].name : "";
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [10, 20, 50],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleOpenAddQuiz("Add Quiz");
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => props.quizsList[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/quizs/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: "Sorry, there is no matching data to display",
      },
    },
  };

  const [formValues, setFormValues] = useState({});
  const handleOpenAddQuiz = async (title, quizId) => {
    setOpenAddQuiz(true);
    setFormValues(props.quizsList.filter((e) => e.serial === quizId)[0]);
  };

  const handleCloseAddQuiz = () => {
    setOpenAddQuiz(false);
  };

  return (
    <>
      <ToastContainer />
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">Quizzes</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <MuiThemeProvider theme={datatableTheme}>
          <MUIDataTable title="" data={props.quizsList} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
      <div>
        <Dialog fullScreen open={openAddQuiz} onClose={handleCloseAddQuiz} TransitionComponent={Transition}>
          <AddQuiz
            title=""
            handleClose={handleCloseAddQuiz}
            formValues={formValues}
            teachersList={props.teachersList}
          />
        </Dialog>
      </div>
    </>
  );
};

export default QuizsComponent;
