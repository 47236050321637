import React, {useState, useEffect, useMemo} from "react";
import {TextField, AppBar, Toolbar} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ToastContainer, toast} from "react-toastify";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import NestedTable from "./NestedTable.js";
import axios from "axios";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const [questions, setQuestions] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const {register, handleSubmit, reset} = useForm({
    defaultValues: useMemo(() => {
      return props.formValues;
    }, [props]),
  });
  const [addMore, setAddMore] = useState(0);
  const [API1submitted, setAPI1submitted] = useState(0);
  const [quizId, setQuizId] = useState(props.formValues.serial);

  useEffect(() => {
    reset(props.formValues);
    setChapters(props.chaptersList.filter((e) => e.serial === props.formValues.chapter_id)[0] || []);
    setTeachers(props.teachersList.filter((e) => e.serial === props.formValues.teacher_id)[0] || []);
    props.setQuestionsList(props.questionsList.filter((e) => e.chapter_id === props.formValues.chapter_id));
  }, [props.formValues]);

  const handleOnSubmit = async (data, e) => {
    if (props.formValues.serial) {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/quizs/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, chapter_id: chapters.serial, teacher_id: teachers.serial},
      })
        .then((response) => {
          props.setFormValues({});
          setAPI1submitted(1);
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/quizs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, chapter_id: chapters.serial, teacher_id: teachers.serial},
      })
        .then((response) => {
          setQuizId(response.data.serial);
          props.setFormValues({});
          setAPI1submitted(1);
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (API1submitted === 1) {
        const dataToAdd = questions
          .filter((e) => e.status === "new")
          .map((e) => {
            delete e.status;
            return e;
          });
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/quiz/questions`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {data: dataToAdd},
        })
          .then((response) => {
            props.setFormValues({});
            reset({name: "", note: ""});
            setQuestions([]);
            setAPI1submitted(0);
            toast.success(response.data.message);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    fetchData();
  }, [API1submitted]);
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="quiz">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="note">Remark</label>
                <input
                  className="form-control"
                  type="text"
                  id="note"
                  placeholder="Remark"
                  {...register("note")}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="chapters"
                  fullWidth
                  options={props.chaptersList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setChapters({serial: newValue.serial, name: newValue.name});
                  }}
                  value={chapters}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Chapters" placeholder="Chapters" />
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="teachers"
                  fullWidth
                  options={props.teachersList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setTeachers({serial: newValue.serial, name: newValue.name});
                  }}
                  value={teachers}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Teachers" placeholder="Teachers" />
                  )}
                />
              </div>
            </div>

            <div className="col-12 my-5">
              <NestedTable
                arrayName={questions}
                setArrayName={setQuestions}
                questionsList={props.questionsList}
                addMore={addMore}
                setAddMore={setAddMore}
                quizId={quizId}
              />
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-lg btn-primary" type="submit" name="submitAndClose">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
