import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {getCookie, isAuth} from "../../../auth/helpers";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const QuestionsAddModal = (props) => {
  const token = getCookie("token");
  const [quizQuestionsList, setQuizQuestionsList] = useState([]);
  let history = useHistory();

  useEffect(() => {
    setQuizQuestionsList(props.quizQuestionsList);
  }, [props.show]);

  const handleChecked = async (e, eSub) => {
    const selectedRow = quizQuestionsList.length
      ? quizQuestionsList.filter((eSub) => eSub.question_id === e.serial).length
        ? quizQuestionsList.filter((eSub) => eSub.question_id === e.serial)[0]
        : {}
      : [];
    if (eSub.target.type === "checkbox") {
      if (!selectedRow.length && eSub.target.checked === true) {
        if (quizQuestionsList.length) {
          setQuizQuestionsList([
            ...quizQuestionsList,
            {
              question_id: e.serial,
              quiz_id: props.quizId,
              mark: e.mark,
              status: "new",
            },
          ]);
        } else {
          setQuizQuestionsList([
            {
              question_id: e.serial,
              quiz_id: props.quizId,
              mark: e.mark,
              status: "new",
            },
          ]);
        }
      }
      if (selectedRow && eSub.target.checked === false) {
        axios
          .delete(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/questions/${selectedRow.serial}`,
            {
              responseType: "json",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setQuizQuestionsList(
              quizQuestionsList.filter((eUnchecked) => eUnchecked.question_id !== e.serial)
            );
          });
      }
    } else {
      setQuizQuestionsList(
        quizQuestionsList.map((eUpdated) => {
          if (e.serial === eUpdated.question_id) {
            eUpdated.mark = eSub.target.value;
          }
          return eUpdated;
        })
      );
    }
  };
  const handleSaveForm = async () => {
    let valid = true;
    quizQuestionsList.map((e) => {
      if (!e.mark) {
        valid = false;
      }
    });
    if (!valid) {
      toast.error("Please Fill The Marks Of Checked Questions");
      return 1;
    }

    const dataToSave = quizQuestionsList
      .filter((e) => e.status === "new")
      .map((e) => {
        delete e.status;
        return e;
      });
    await axios({
      method: "post",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/quiz/questions`,
      headers: {Authorization: `Bearer ${token}`},
      data: {data: dataToSave},
    })
      .then((response) => {
        props.setModalShow(false);
        props.setQuizQuestionsList(quizQuestionsList);
        history.push("/quizList");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkAllQuestions = (e) => {
    let toBeAdded = [];
    props.questionsList.map((e, i) => {
      const currentQuizQuestion = quizQuestionsList.length
        ? quizQuestionsList.filter((eSub) => eSub.question_id === e.serial)
        : [];
      const checked = currentQuizQuestion.length ? true : false;
      if (!checked) {
        toBeAdded.push({
          question_id: e.serial,
          quiz_id: props.quizId,
          mark: e.mark,
          status: "new",
        });
      }
    });
    if (toBeAdded.length > 0) {
      if (quizQuestionsList.length) {
        setQuizQuestionsList([...quizQuestionsList, ...toBeAdded]);
      } else {
        setQuizQuestionsList(toBeAdded);
      }
    }
  };
  return (
    <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <ToastContainer />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-striped">
          <thead>
            <tr>
              <th width="2%" scope="col">
                #
              </th>
              <th width="55%" scope="col">
                Image
              </th>
              <th width="25%" scope="col">
                Question
              </th>
              <th width="8%" scope="col">
                <Button onClick={checkAllQuestions}>Check All</Button>
              </th>
              <th width="10%" scope="col">
                Mark
              </th>
            </tr>
          </thead>
          <tbody>
            {props.questionsList.map((e, i) => {
              const currentQuizQuestion = quizQuestionsList.length
                ? quizQuestionsList.filter((eSub) => eSub.question_id === e.serial)
                : [];
              const currentQuizQuestionsanswersList = props.answersList.length
                ? props.answersList.filter((eSub) => eSub.question_id === e.serial)
                : [];
              const checked = currentQuizQuestion.length ? true : false;
              return (
                <tr key={e.serial}>
                  <td scope="row">{i + 1} </td>
                  <td>
                    <img
                      src={process.env.REACT_APP_FILES_PATH + "/Questions/" + e.image}
                      alt=""
                      className="img-thumbnail rounded question-img"
                      style={{width: "80px", height: "80px"}}
                    />
                  </td>
                  {/* <td><img src={process.env.REACT_APP_FILES_PATH + "/Questions/1636702134.jpg"} alt="" className="img-thumbnail rounded question-img" style={{ width: "80px", height: "80px" }} /></td> */}

                  <td>
                    {e.question}
                    <br />
                    <br />
                    Answers:
                    <ul>
                      {currentQuizQuestionsanswersList.map((row) => {
                        return (
                          <li
                            style={{
                              color: row.correct == 1 ? "#47d115" : "inherit",
                            }}
                          >
                            {row.answer}
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td className={"text-center"}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={checked}
                      onChange={(eSub) => handleChecked(e, eSub)}
                    />
                  </td>
                  <td>
                    <div className="form-floating" style={{width: 100}}>
                      <input
                        type="number"
                        className="form-control"
                        id="note"
                        placeholder=""
                        value={
                          currentQuizQuestion.length && checked == true ? currentQuizQuestion[0].mark : ""
                        }
                        defaultValue={
                          currentQuizQuestion.length && checked == true ? currentQuizQuestion[0].mark : null
                        }
                        onChange={(eSub) => handleChecked(e, eSub)}
                      />
                      <label htmlFor="note">Mark*</label>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSaveForm}>Submit</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default QuestionsAddModal;
