import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from './helpers';

const UserRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => 
            isAuth() ? (
                isAuth().role === 'admin' ? <Redirect
                to={{
                    pathname: '/admin/users',
                    state: { from: props.location }
                }}
            /> : <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location }
                    }}
                />
            )
        }
    ></Route>
);

export default UserRoute;
