import React, {useState, useEffect, useMemo} from "react";
import {TextField, CircularProgress} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ToastContainer, toast} from "react-toastify";
import {Modal, Button} from "react-bootstrap";
import {getCookie, isAuth} from "../../../auth/helpers";
import {useForm} from "react-hook-form";
import NestedTable from "./NestedTable.js";
import axios from "axios";

const ClassesAddModel = (props) => {
  const token = getCookie("token");
  const [rowStudents, setRowStudents] = useState({});
  const [students, setStudents] = useState([]);
  const {register, handleSubmit, reset} = useForm({
    defaultValues: useMemo(() => {
      return props.formValues;
    }, [props]),
  });
  const [grade, setGrade] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [API1submitted, setAPI1submitted] = useState(0);
  const [classId, setClassId] = useState(0);

  useEffect(() => {
    const defaultGrade = props.gradesList.filter((e) => e.serial === props.formValues.grade_id);
    setGrade(defaultGrade.length ? defaultGrade[0] : {});
    setStudents(
      props.formValues.myclass_students
        ? props.formValues.myclass_students.map((e) => {
            e.status = "old";
            return e;
          })
        : []
    );
    reset(props.formValues);
  }, [props.formValues]);

  const handleOnSubmit = async (data, e) => {
    setIsSubmitting(true);
    if (props.formValues.serial) {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, teacher_id: isAuth()._id, grade_id: grade.serial},
      })
        .then((response) => {
          setClassId(props.formValues.serial);
          setAPI1submitted(1);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, teacher_id: isAuth()._id, grade_id: grade.serial},
      })
        .then((response) => {
          setClassId(response.data.serial);
          setAPI1submitted(1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleClose = () => {
    props.setFormValues([]);
    props.onHide();
  };
  useEffect(() => {
    const fetchData = async () => {
      if (API1submitted === 1) {
        const dataToAdd = students
          .filter((e) => e.status === "new")
          .map((e) => {
            delete e.serial;
            delete e.status;
            e.myclass_id = classId;
            return e;
          });
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/myclass/students`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {data: dataToAdd},
        })
          .then((response) => {
            props.setFormValues({});
            setIsSubmitting(false);
            setAPI1submitted(0);
            props.onHide();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    fetchData();
  }, [API1submitted]);
  return (
    <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
        <ToastContainer />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Classes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{padding: "50px"}}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <Autocomplete
                    id="grade"
                    fullWidth
                    options={props.gradesList}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(event, newValue) => setGrade({serial: newValue.serial, name: newValue.name})}
                    value={grade}
                    filterSelectedOptions
                    renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="Grade" placeholder="Grade" />
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="name">Section</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    {...register("name")}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="school">School</label>
                  <input
                    type="text"
                    className="form-control"
                    id="school"
                    placeholder="School"
                    {...register("school")}
                  />
                </div>
              </div>
              <div className="col-12 my-5">
                <NestedTable
                  document="students"
                  arrayName={students}
                  setArrayName={setStudents}
                  rowData={rowStudents}
                  setRowData={setRowStudents}
                  studentsList={props.studentsList}
                  title="Students"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>
            <i className="fas fa-times"></i> Close
          </Button>
          <div>
            {!isSubmitting ? (
              <Button type="submit">
                <i className="fas fa-save"></i> Save
              </Button>
            ) : (
              <CircularProgress />
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default ClassesAddModel;
