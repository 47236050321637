import React from "react";
import CustomToolbar from "../../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import { Add } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  TableFooter,
  TableRow,
  TablePagination,
  TableCell,
} from "@material-ui/core";

const NestedTableBookChapters = (props) => {
  const add1Row = () => {
    if (props.arrayName && props.arrayName.length) {
      console.log(
        props.rowData.name && props.rowData.priority && props.rowData.file_name
      );
      if (
        props.rowData.name &&
        props.rowData.priority &&
        props.rowData.file_name
      ) {
        props.setArrayName([
          ...props.arrayName.filter((e) => e.name && e.priority && e.file_name),
          {
            name: props.rowData.name,
            priority: props.rowData.priority,
            file_name: props.rowData.file_name,
          },
          { name: "", priority: "", file_name: "" },
        ]);
        props.setRowData({});
      } else if (props.arrayName[props.arrayName.length - 1].serial) {
        props.setArrayName([
          ...props.arrayName,
          { name: "", priority: "", file_name: "" },
        ]);
        props.setRowData({});
      } else {
        toast.error(
          `Fill all fields in ${props.title} before adding a new row`
        );
      }
    } else {
      props.setArrayName([{ name: "", priority: "", file_name: "" }]);
    }
  };
  const handleBlurTextfield = (e, tableMeta) => {
    const { name, value } = e.target;
    if (tableMeta.rowData[0]) {
      props.setArrayName(
        props.arrayName.map((eSub) => {
          if (eSub.serial == tableMeta.rowData[0]) {
            if (name === "name") eSub.name = value;
            else if (name === "priority") eSub.priority = value;
          }
          return eSub;
        })
      );
    } else {
      props.setRowData({ ...props.rowData, [name]: value });
    }
  };

  const handleImageChange1 = (e, tableMeta) => {
    e.preventDefault();

    if (tableMeta.rowData[0]) {
      props.setArrayName(
        props.arrayName.map((eSub) => {
          if (eSub.serial == tableMeta.rowData[0]) {
            // let reader = new FileReader();
            let file = e.target.files[0];
            eSub.file_name = file;
            // reader.onloadend = () => {
            //   // setFile1(file);

            // };
            // reader.readAsDataURL(file);
          }
          return eSub;
        })
      );
    } else {
      // let reader = new FileReader();
      let file = e.target.files[0];
      props.setRowData({ ...props.rowData, file_name: file });
      // reader.onloadend = () => {
      //   props.setRowData({ ...props.rowData, "file_name": file })
      // };
      // reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      <ToastContainer />
      <MUIDataTable
        data={props.arrayName}
        title={props.title}
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    onBlur={(e) => handleBlurTextfield(e, tableMeta)}
                    defaultValue={value}
                  />
                );
              },
            },
          },
          {
            name: "priority",
            label: "Priority",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <input
                    type="number"
                    className="form-control"
                    name="priority"
                    placeholder="Priority"
                    onBlur={(e) => handleBlurTextfield(e, tableMeta)}
                    defaultValue={value}
                  />
                );
              },
            },
          },
          {
            name: "file_name",
            label: "File Name",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <>
                    {value ? (
                      <a
                        href={
                          process.env.REACT_APP_FILES_PATH + "/Books/" + value
                        }
                        target="_blank"
                      >
                        {" "}
                        - <i className="fas fa-file-pdf"></i> Check current PDF
                      </a>
                    ) : null}
                    <input
                      type="file"
                      className="form-control"
                      name="file_name"
                      placeholder="Chapter File"
                      onChange={(e) => handleImageChange1(e, tableMeta)}
                    />
                  </>
                );
              },
            },
          },
        ]}
        options={{
          filter: false,
          rowsPerPage: 100,
          rowsPerPageOptions: [20, 50, 100],
          customToolbar: () => {
            return <CustomToolbar listener={add1Row} />;
          },
          customFooter: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage,
            textLabels
          ) => {
            return (
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <TablePagination
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={(_, page) => changePage(page)}
                      onChangeRowsPerPage={(event) =>
                        changeRowsPerPage(event.target.value)
                      }
                      rowsPerPageOptions={[10, 15, 100]}
                    />
                  </TableCell>
                  <TableCell>
                    <a
                      style={{
                        textAlign: "right",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={() => add1Row()}
                      title="add new row"
                    >
                      <Add />
                    </a>
                  </TableCell>
                </TableRow>
              </TableFooter>
            );
          },
          textLabels: {
            body: {
              noMatch:
                !props.isLoading &&
                "Sorry, there is no matching data to display",
            },
          },
          onRowsDelete: (rowsDeleted, dataRows) => {
            const idsToDelete = rowsDeleted.data.map(
              (d) => props.arrayName[d.dataIndex].serial
            );
            const rowsToKeep = props.arrayName.filter(
              (e) => !idsToDelete.includes(e.serial)
            );
            props.setArrayName(rowsToKeep);
          },
        }}
      />
    </div>
  );
};

export default NestedTableBookChapters;
