import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {ToastContainer, toast} from "react-toastify";
import "../../assets/css/styles.css";
import axios from "axios";

const ChangePassword = ({history}) => {
  const {register, handleSubmit, watch} = useForm();
  const handleOnSubmit = async (data) => {
    await axios({
      method: "post",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/forgetpass`,
      data,
    })
      .then((response) => {
        toast.success(response.data);
        history.push({
          pathname: "/",
          state: {type: "Pending Request!", message: response.data},
        });
      })
      .catch((error) => {
        toast.error(error.response.data);
        console.log(error);
      });
  };
  const handleError = (errors) => {
    if (errors.email) toast.error(errors.email.message);
    else if (errors.password) toast.error(errors.password.message);
    else if (errors.rePassword) toast.error(errors.rePassword.message);
  };
  const validationOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
        message: "Enter a valid email address",
      },
    },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
    rePassword: {
      required: "Retype Password",
      validate: (e) => e === watch("password") || "Password fields don't match",
    },
  };

  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
      />
      <div className="row">
        <div className="col-md-5 offset-md-1 d-flex flex-column p-5">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo.jpg`}
              alt="L'info Pour Tous"
              style={{height: "9rem"}}
            />
          </a>
          <div className="flex-grow-1 d-flex flex-column justify-content-center">
            <h3 className="text-secondary">RESET PASSWORD</h3>
            <p className="mb-5">Enter your information below to reset your password</p>

            <form onSubmit={handleSubmit(handleOnSubmit, handleError)} encType="multipart/form-data">
              <div className="mb-4 d-flex">
                <div className="form-check px-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="typeTeacher"
                    value={"teacher"}
                    {...register("type")}
                  />
                  <label className="form-check-label" htmlFor="typeTeacher">
                    Teacher
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="typeStudent"
                    value={"student"}
                    {...register("type")}
                    defaultChecked={true}
                  />
                  <label className="form-check-label" htmlFor="typeStudent">
                    Student
                  </label>
                </div>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="signinUsername"
                  placeholder=""
                  {...register("email", validationOptions.email)}
                />
                <label htmlFor="signinUsername">Email*</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="password"
                  className="form-control"
                  id="signinPassword"
                  placeholder=""
                  {...register("password", validationOptions.password)}
                />
                <label htmlFor="signinPassword">New password*</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="password"
                  className="form-control"
                  id="registerPassword2"
                  placeholder=""
                  {...register("rePassword", validationOptions.rePassword)}
                />
                <label htmlFor="registerPassword2">Re-Type password*</label>
              </div>
              <div className="d-flex justify-content-between pb-5">
                <button className="btn btn-secondary" type="submit">
                  CHANGE PASSWORD
                </button>
              </div>
            </form>
          </div>
          <div className="d-flex justify-content-between">
            <a href="/" className="readMore">
              <i className="fas fa-arrow-left"></i> &nbsp; Back
            </a>
            <div>
              Not sign up yet? <a href="/register1">Click here!</a>
            </div>
          </div>
        </div>
        <div
          className="col-md-5 offset-md-1 signinImg"
          style={{
            background: `url(${process.env.PUBLIC_URL + "/assets/images/loginImg.png"})`,
          }}
        ></div>
      </div>
    </div>
  );
};
export default ChangePassword;
