import React, {useState, useEffect} from "react";
import {getCookie} from "../../../../auth/helpers";
import {AppBar, CircularProgress, Toolbar} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {datatableTheme} from "../../../../components/datatable-theme.js";
import moment from "moment";
import axios from "axios";
import MUIDataTable from "mui-datatables";

const ListOfTickets = (props) => {
  const token = getCookie("token");
  const [ticketsList, setTicketsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagingInfo, setPagingInfo] = useState({page: 0, limit: 100, skip: 0, count: 100}); //Pagination Info

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/tickets/get_details/${props.ticketsId}?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.counts});
        setTicketsList(response.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [pagingInfo.page, pagingInfo.limit]);

  const columns = [
    {name: "ticket_number", label: "Ticket Number"},
    {name: "bookName", label: "Book"},
    {name: "studentName", label: "Student name"},
    {name: "studentEmail", label: "Student email"},
    {
      name: "registration_date",
      label: "Registration Date",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("DD MMM YYYY") : null;
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [50, 100, 200],
    downloadOptions: {
      filename: "list_of_tickets.csv",
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
    // serverSide: true,
    // count:pagingInfo.count, // Use total number of items
    // page: pagingInfo.page,
    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPagingInfo({...pagingInfo,page:tableState.page,skip:tableState.page*pagingInfo.limit});
    //   }else if(action === "changeRowsPerPage"){
    //     setPagingInfo({...pagingInfo,limit:tableState.rowsPerPage});
    //   }
    // }
  };

  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={ticketsList} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}
      </div>
    </>
  );
};

export default ListOfTickets;
