import React, {useState, useEffect} from "react";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import {Dialog, Slide, TextField, CircularProgress} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";
import {getCookie} from "../../../auth/helpers";

//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import {MuiThemeProvider} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import moment from "moment";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const User = () => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [RowID, setRowID] = useState(0); //current row
  const [modal_Title, setmodal_Title] = useState("Add"); //modal title
  const [formTitle, setFormTitle] = useState("Add User");
  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [pagingInfo, setPagingInfo] = useState({
    page: 0,
    limit: 20,
    skip: 0,
    count: 20,
  }); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry
  const [usersId, setUsersId] = useState([]);
  const profiles = ["Admin", "Representative"];
  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/users?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.count});
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [open, pagingInfo.page, pagingInfo.limit, searchEntry]);

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleAdd("Edit User - " + tableMeta.rowData[1], tableMeta.rowData[0]);
                }}
              >
                {value}
              </a>
            </div>
          );
        },
      },
    },
    {
      name: "email",
    },
    {
      name: "profile",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{profiles[value - 1]}</div>;
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <>
          <CustomToolbar
            listener={() => {
              handleAdd("Add User");
            }}
          />
        </>
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].id); // array of all ids to to be deleted

      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/users/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setItems(response?.data?.data);
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
    // serverSide: true,
    // count:pagingInfo.count, // Use total number of items
    // page: pagingInfo.page,
    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPagingInfo({...pagingInfo,page:tableState.page,skip:tableState.page*pagingInfo.limit});
    //   }else if(action === "changeRowsPerPage"){
    //     setPagingInfo({...pagingInfo,limit:tableState.rowsPerPage});
    //   }
    // }
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };

  const handleClickOpen = (rowID = 1, modal_Title = "Add") => {
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, userId) => {
    if (userId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/users/${userId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          setFormValues({...response.data, password: ""});
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        name: "",
        emails: "",
      });
      setFormTitle(title);
      setOpen(true);
    }

    //setUserId(userId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Search component ---------------START--------------
  const handleChangeSearch = (e, newValue) => {
    setSearchEntry(newValue);
    // if(newValue.length===0) setItems(itemsBackup); else{
    //   let valueToSearch=[]
    //   newValue.forEach(newValueEntry=>{
    //     valueToSearch.push(...itemsBackup.filter((e,i) => {
    //       if(!valueToSearch.map(eSearch=>eSearch.serial).includes(e.serial)){
    //         if (e.name.toLowerCase().includes(newValueEntry.toLowerCase())){
    //           return true;
    //         }
    //       }
    //     }))
    //   })
    //   setItems(valueToSearch)
    // }
  };
  //Search component ---------------END--------------
  return (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5 pt-5">
        {/* <Autocomplete
        multiple
        freeSolo
        limitTags={3}
        id="tags-standard"
        options={[]}
        getOptionLabel={(option) => option}
        onChange={handleChangeSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Data"
            label="Filter by Name"
          />
        )}
      /> */}

        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AddFormDialog title={formTitle} handleClose={handleClose} formValues={formValues} />
          </Dialog>
          {/*********************** FILTER start ****************************/}
          {/* <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} />
        </Dialog> */}
        </div>
      </div>
    </AdminLayout>
  );
};
export default User;
