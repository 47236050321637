import React, {useState, useEffect} from "react";
import {TextField, AppBar, Toolbar} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const [grades, setGrades] = useState([]);
  const [books, setBooks] = useState([]);
  const {register, handleSubmit} = useForm();

  useEffect(() => {
    setGrades(props.gradesList.filter((e) => e.serial === props.formValues.grade_id)[0] || []);
    setBooks(props.booksList.filter((e) => e.serial === props.formValues.book_id)[0] || []);
  }, []);

  const handleOnSubmit = async (data) => {
    if (props.title.substring(0, 4) === "Edit") {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/chapters/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, grade_id: grades.serial, book_id: books.serial},
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/chapters`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, grade_id: grades.serial, book_id: books.serial},
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  defaultValue={props.formValues.name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="number">Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  placeholder="Number"
                  {...register("number")}
                  defaultValue={props.formValues.number}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="grades"
                  fullWidth
                  options={props.gradesList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setGrades({serial: newValue.serial, name: newValue.name});
                  }}
                  value={grades}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Grade" placeholder="Grade" />
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="books"
                  fullWidth
                  options={props.booksList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setBooks({serial: newValue.serial, name: newValue.name});
                  }}
                  value={books}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Book" placeholder="Book" />
                  )}
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-lg btn-primary" type="submit">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
