import React from "react";
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";

import Signup from "./auth/Signup";
import Signin from "./auth/Signin";
import Homepage from "./pages/Homepage/Homepage";
import EmailConfirmed from "./pages/Homepage/EmailConfirmed";
import PasswordUpdated from "./pages/Homepage/PasswordUpdated";
import TeacherBookDetails from "./pages/Books/TeacherBookDetails";
import BookDetailsFrontend from "./pages/Books/BookDetails";
import TeachersBook from "./pages/Books/TeachersBook";
import Quiz from "./pages/Quiz/Quiz";
import QuizzesList from "./pages/Quiz/QuizzesList";
import ClassesList from "./pages/Classes/ClassesList";
import QuestionsList from "./pages/Quiz/QuestionsList";
import StudentsQuiz from "./pages/Quiz/StudentsQuiz";
import StudentsQuizDone from "./pages/Quiz/StudentsQuizDone";
import SigninFrontend from "./pages/Auth/Signin";
import Register1Frontend from "./pages/Auth/Register1";
import Register2Frontend from "./pages/Auth/Register2";
import Register3Frontend from "./pages/Auth/Register3";
import AccountConfirmed from "./pages/Auth/AccountConfirmed";
import ChangePassword from "./pages/Auth/ChangePassword";
import Users from "./pages/admin/Users/Users";
import Books from "./pages/admin/Books/Books";
import Tickets from "./pages/admin/Tickets/Tickets";
import Teachers from "./pages/admin/Teachers/Teachers";
import Grades from "./pages/admin/Grades/Grades";
import Students from "./pages/admin/Students/Students";
import ExtraResourcesCategories from "./pages/admin/ExtraResourcesCategories/ExtraResourcesCategories";
import ExtraResources from "./pages/admin/ExtraResources/ExtraResources";
import Sliders from "./pages/admin/Sliders/Sliders";
import Testimonials from "./pages/admin/Testimonials/Testimonials";
import ScopeAndResources from "./pages/admin/ScopeAndResources/ScopeAndResources";
import Footer from "./pages/admin/Footers/Footers";
import WhyUs from "./pages/admin/WhyUs/WhyUs";
import MyClass from "./pages/admin/MyClass/MyClass";
import Chapters from "./pages/admin/Chapters/Chapters";
import Questions from "./pages/admin/Questions/Questions";
import Quizzes from "./pages/admin/Quizzes/Quizzes";
import QuizzesResult from "./pages/admin/Quizzes/QuizzesResult";
import Answers from "./pages/admin/Answers/Answers";
import EducationalCompanies from "./pages/admin/EducationalCompanies/EducationalCompanies";
import Admin from "./core/Admin";
import AdminRoute from "./auth/AdminRoute";
import UserRoute from "./auth/UserRoute";
import VisitorRoute from "./auth/VisitorRoute";
import VerifyFrontend from "./pages/Auth/Verify";
import Schools from "./pages/admin/Schools/Schools";
import RepresentativeVisits from "./pages/admin/RepresentativeVisits/RepresentativeVisits";

const hist = createBrowserHistory();
const Routes = () => {
  // const maroc = "zero1-maroc.education";
  // console.log(window?.location?.hostname);
  // console.log("- - - >", maroc.includes("maroc"));
  return (
    <Router history={hist}>
      {/* <Router>  */}
      <Switch>
        <VisitorRoute path="/" exact component={Homepage} />
        <VisitorRoute path="/emailConfirmed" exact component={EmailConfirmed} />
        <VisitorRoute path="/passwordUpdated" exact component={PasswordUpdated} />
        {/* <VisitorRoute path="/books" exact component={BooksFrontend} /> */}
        <UserRoute path="/books/:id" exact component={BookDetailsFrontend} />
        <UserRoute path="/teacherBookDetails/:id" exact component={TeacherBookDetails} />
        <VisitorRoute path="/confirmed" exact component={AccountConfirmed} />
        <VisitorRoute path="/changePassword" exact component={ChangePassword} />
        <VisitorRoute path="/signin" exact component={SigninFrontend} />
        <VisitorRoute path="/verify/:serial" exact component={VerifyFrontend} />
        <VisitorRoute path="/register1" exact component={Register1Frontend} />
        <VisitorRoute path="/register2" exact component={Register2Frontend} />
        <VisitorRoute path="/register3" exact component={Register3Frontend} />
        <VisitorRoute path="/teachersBook/:id" exact component={TeachersBook} />
        <VisitorRoute path="/quizCreate" exact component={Quiz} />
        <VisitorRoute path="/quiz/:method/:id" exact component={Quiz} />
        <VisitorRoute path="/quizList" exact component={QuizzesList} />
        <VisitorRoute path="/classList" exact component={ClassesList} />
        <VisitorRoute path="/questionsList/:quizId" exact component={QuestionsList} />
        <VisitorRoute path="/student/quiz/:quizId" exact component={StudentsQuiz} />
        <VisitorRoute path="/student/quiz/done/:quizId" exact component={StudentsQuizDone} />
        {/* <Route path="/" exact component={App} /> */}
        <AdminRoute path="/admin" exact component={Admin} />
        {/* when fixing the admin login from backend chenge all the below to AdminRoute */}
        <Route path="/admin/signup" exact component={Signup} />
        <Route path="/admin/signin" exact component={Signin} />
        <AdminRoute path="/admin/users" exact component={Users} />
        <AdminRoute path="/admin/books" exact component={Books} />
        <AdminRoute path="/admin/tickets" exact component={Tickets} />
        <AdminRoute path="/admin/grades" exact component={Grades} />
        <AdminRoute path="/admin/teachers" exact component={Teachers} />
        <AdminRoute path="/admin/students" exact component={Students} />
        <AdminRoute path="/admin/ExtraResourcesCategories" exact component={ExtraResourcesCategories} />

        <AdminRoute path="/admin/ExtraResources" exact component={ExtraResources} />

        <AdminRoute path="/admin/sliders" exact component={Sliders} />
        <AdminRoute path="/admin/testimonials" exact component={Testimonials} />

        <AdminRoute path="/admin/scopeAndResources" exact component={ScopeAndResources} />
        <AdminRoute path="/admin/footer" exact component={Footer} />
        <AdminRoute path="/admin/whyus" exact component={WhyUs} />
        <AdminRoute path="/admin/myclass" exact component={MyClass} />
        <AdminRoute path="/admin/chapters" exact component={Chapters} />
        <AdminRoute path="/admin/questions" exact component={Questions} />
        <AdminRoute path="/admin/quizzes" exact component={Quizzes} />
        <AdminRoute path="/admin/quizzesResult" exact component={QuizzesResult} />
        <AdminRoute path="/admin/answers" exact component={Answers} />
        <AdminRoute path="/admin/questions/:chapterId" exact component={Questions} />

        <AdminRoute path="/admin/educationalCompanies" exact component={EducationalCompanies} />

        <AdminRoute path="/admin/schools" exact component={Schools} />

        <AdminRoute path="/admin/representativeVisits" exact component={RepresentativeVisits} />
      </Switch>
    </Router>
  );
};

export default Routes;
