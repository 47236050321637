import {createMuiTheme} from "@material-ui/core/styles";

export const datatableTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: "1.3rem",
      },
      h6: {
        fontSize: "1.3rem",
      },
    },
    MuiSelect: {
      select: {
        fontSize: "1.3rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "1.3rem",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "2.2rem",
      },
    },

    MuiTableCell: {
      head: {
        backgroundColor: "#e4eaec !important",
      },
      root: {
        fontSize: "1.3rem",
      },
    },
    MuiButton: {
      label: {
        fontSize: "1.3rem",
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        minWidth: "200px",
        textAlign: "center",
        margin: "1px 0",
      },
      data: {
        textTransform: "capitalize",
        fontWeight: "bold",
        fontSize: "1.3rem",
      },
      // sortAction: {
      //   width: "100%"
      // }
    },
    MUIDataTableBodyCell: {
      root: {
        minWidth: "200px",
        textAlign: "center",
        margin: "1px 0",
        fontSize: "1.3rem",
      },
    },
    MUIDataTableToolbar: {
      root: {
        width: "50%",
        position: "absolute",
        right: 0,
        top: "-59px",
      },
    },
    MUIDataTable: {
      paper: {
        position: "relative",
        height: "calc(100vh - 12rem)",
      },
      responsiveBase: {
        height: "80% !important",
        width: "100% !important",
        overflow: "scroll",
      },
    },
    MUIDataTableToolbar: {
      regular: {
        width: "100%",
      },
    },
    MUIDataTableFooter: {
      root: {
        position: "fixed",
        left: 0,
        bottom: 0,
      },
    },
  },
});
