import React, {useState, useEffect} from "react";
import {getCookie} from "../../../auth/helpers";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Dialog, Slide, CircularProgress, TablePagination, LinearProgress} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {ToastContainer, toast} from "react-toastify";
import AddSchoolFormDialog from "../Schools/Components/AddFormDialog";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from "axios";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import CustomAutocomplete from "../../../components/CustomAutocomplete.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schoolTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Teacher = () => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [openAddForm, setOpenAddForm] = useState(false); //for modal
  const [teacherId, setTeacherId] = useState(); //modal title
  const [formTitle, setFormTitle] = useState("Add"); //modal title
  const [filterDialog, setFilterDialog] = useState(false);
  const [items, setItems] = useState([]); //table items
  const [itemsBackup, setItemsBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [pagingInfo, setPagingInfo] = useState({
    page: 0,
    limit: 50,
    skip: 0,
    count: 0,
  }); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry
  const [formValues, setFormValues] = useState({});
  const [allGrades, setAllGrades] = useState([]);
  const [gradesFilter, setGradesFilter] = useState([]);

  const [openSchool, setOpenSchool] = useState(false);
  const handleCloseSchool = () => {
    setOpenSchool(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/teachers?page=${pagingInfo.page}&limit=${pagingInfo.limit}&skip=${
          pagingInfo.skip
        }&searchEntry=${searchEntry}&gradesFilter=${gradesFilter}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filters: filters,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response?.data?.count});
        const data = response?.data?.data;
        if (data)
          for (let index = 0; index < data?.length; index++) {
            data[index].booksAssignedCount = data[index].books.length;
          }
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [openAddForm, pagingInfo.page, pagingInfo.limit, searchEntry, gradesFilter, filters]);

  useEffect(() => {
    const fetchData = async () => {
      axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/grades`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        const data = response?.data?.data;
        setAllGrades(data);
      });
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Full Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleAdd("Edit Teacher - " + tableMeta.rowData[2], tableMeta.rowData[0]);
                }}
              >
                {value}
              </a>
            </div>
          );
        },
      },
    },

    {name: "firstName", label: "First Name"},
    {name: "lastName", label: "Last Name"},
    {name: "gender", label: "Gender"},
    {name: "country", label: "Country"},

    {name: "email"},
    {name: "school"},
    {name: "phone"},
    {
      name: "booksAssignedCount",
      label: "Books Assigned",
      // options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     return value.length;
      //   },
      // },
    },
    {name: "login_count", label: "Login Counts"},
    {name: "last_login", label: "Last Login"},
    {
      name: "verified",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "manual",
      label: "Manual",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },

    {
      name: "active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {name: "latest_active_at", label: "Lastest activation"},

    {
      name: "activation_request",
      label: "Re-Activation requested",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
  ];

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  const downloadListener = () => {
    setIsLoading(true);
    axios
      .get(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/export/teachers`,
        {
          params: {
            filters: filters,
            gradesFilter: gradesFilter,
          },
        }
      )
      .then((response) => {
        const type = response.headers["content-type"];
        const blob = new Blob(["\uFEFF" + response.data], {type: type, encoding: "UTF-8"});
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "teachers.csv";
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const options = {
    search: false,
    filter: true,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [50, 100, 200, 300, 400, 500],
    selectToolbarPlacement: "replace",

    customToolbar: () => {
      return (
        <>
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={() => {
              downloadListener();
            }}
          >
            Export all
          </button>
          <CustomToolbar
            listener={() => {
              handleAdd("Add New Teacher");
            }}
          />
        </>
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/teachers/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.Message);
          setItems(response?.data?.data);
          setPagingInfo({...pagingInfo, count: response.data.count});
        });
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <LinearProgress /> : "Sorry, there is no matching data to display",
      },
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    serverSide: true,
    count: pagingInfo.count, // Use total number of items
    page: pagingInfo.page,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPagingInfo({...pagingInfo, page: tableState.page, skip: tableState.page * pagingInfo.limit});
      } else if (action === "changeRowsPerPage") {
        setPagingInfo({...pagingInfo, limit: tableState.rowsPerPage});
      }
    },

    onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
      // console.log(changedColumn, filterList, type, changedColumnIndex, displayData);

      const key = changedColumn;

      const value = filterList[changedColumnIndex][0];

      setFilters((prev) => ({...prev, [key]: value}));
    },

    // customFooter: (countTable, pageTable, rowsPerPageTable, changeRowsPerPageTable, changePageTable) => {
    //   return (
    //     <tfoot>
    //       <tr>
    //         <TablePagination
    //           count={countTable}
    //           rowsPerPage={rowsPerPageTable}
    //           page={page}
    //           onChangePage={(_, page) => {
    //             // setPage(page);
    //             setPagingInfo((prev) => ({...prev, page: page}));
    //           }}
    //           rowsPerPageOptions={[50]}
    //           // labelDisplayedRows={() => { return "" }}
    //           // nextIconButtonProps={{ disabled: data.length < rowsPerPageTable }}
    //           // backIconButtonProps={{ disabled: page == 0 }}
    //         />
    //       </tr>
    //     </tfoot>
    //   );
    // },
    // count: pagingInfo.count,
  };

  const handleFilter = () => {
    setFilterDialog(true);
  };

  const handleAdd = async (title, teacherId) => {
    if (teacherId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/teachers/${teacherId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          setFormValues(response.data);
          setFormTitle(title);
        })
        .then((response) => {
          setOpenAddForm(true);
        });
    } else {
      setFormValues({
        name: "",
        email: "",
        password: "",
        school: "",
        school_serial: "",
        phone: "",
        notes: "",
        assistant_director: "",
        grades: [],
        books: [],
      });
      setFormTitle(title);
      setOpenAddForm(true);
    }

    //setBookId(bookId);
  };
  const handleCloseAddForm = () => setOpenAddForm(false);

  //Search component ---------------START--------------
  // const handleChangeSearch = (e, newValue) => {
  //   if (newValue.length === 0) setItems(itemsBackup);
  //   else {
  //     let valueToSearch = [];
  //     newValue.forEach((newValueEntry) => {
  //       valueToSearch.push(
  //         ...itemsBackup.filter((e, i) => {
  //           if (!valueToSearch.map((eSearch) => eSearch.serial).includes(e.serial)) {
  //             if (e.name.toLowerCase().includes(newValueEntry.toLowerCase())) {
  //               return true;
  //             }
  //           }
  //         })
  //       );
  //     });
  //     setItems(valueToSearch);
  //   }
  // };

  const [searchTerm, setSearchTerm] = useState("");
  const handleChangeSearch = (e) => {
    let value = e.target.value;
    setSearchTerm(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchEntry(searchTerm);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const customOnChange = (newValue, name) => {
    setIsLoading(true);
    setGradesFilter(newValue);
  };
  //Search component ---------------END--------------
  return (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5 pt-5">
        <div className="row">
          <div className="col-sm-6">
            <TextField
              fullWidth
              value={searchTerm}
              variant="standard"
              onChange={handleChangeSearch}
              label="Search"
            />
          </div>
          <div className="col-sm-6">
            <CustomAutocomplete
              filedName="gradesFilter"
              label="Filter by Grades"
              list={allGrades}
              values={gradesFilter}
              listKey="serial"
              description="name"
              customOnChange={customOnChange}
            />
          </div>
        </div>
        {/* {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )} */}
        <MuiThemeProvider theme={datatableTheme}>
          <MUIDataTable title="" data={items} columns={columns} options={options} />
        </MuiThemeProvider>
        <div>
          <Dialog fullScreen open={openAddForm} onClose={handleCloseAddForm} TransitionComponent={Transition}>
            <AddFormDialog
              setOpenSchool={setOpenSchool}
              openSchool={openSchool}
              title={formTitle}
              handleClose={handleCloseAddForm}
              teacherId={teacherId}
              formValues={formValues}
            />
          </Dialog>

          <Dialog
            fullScreen
            open={openSchool}
            onClose={handleCloseSchool}
            TransitionComponent={schoolTransition}
          >
            <AddSchoolFormDialog title={"Add School"} handleClose={handleCloseSchool} formValues={[]} />
          </Dialog>
          {/*********************** FILTER start ****************************/}
          {/* <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} />
        </Dialog> */}
        </div>
      </div>
    </AdminLayout>
  );
};
export default Teacher;
