import React from "react";
import Layout from '../../core/Layout';

const AccountConfirmed = (props) => {
    return (
      <Layout>
        <div className="container pt-5 mt-5">
          <div id="register3" className="py-5 mt-5">
            <div className="d-flex flex-column pt-5 mt-5">
              <div className="text-center">
                <h3 className="text-secondary mb-5">Congratulations</h3>
                <p className="w-50 mx-auto">Your account has been activated successfully .<br/>You can now login to your account</p>
              </div>
            </div>
          </div>
            <div className="mb-5 d-flex justify-content-center">
                <a href="/signin" className="btn btn-secondary px-5"> Login</a>
            </div>
        </div>
      </Layout>
    );
  }
  export default AccountConfirmed