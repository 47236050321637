import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {CircularProgress} from "@material-ui/core";
import Layout from "../../core/Layout";
import {ToastContainer, toast} from "react-toastify";
import {useLocation} from "react-router-dom";
import {Carousel} from "react-bootstrap";
import ItemsCarousel from "react-items-carousel";
import axios from "axios";
import {TrendingUpTwoTone} from "@material-ui/icons";
import {countries} from "../../data/countries";
const Homepage = () => {
  const {register, handleSubmit} = useForm();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [booksList, setBooksList] = useState([]);
  const [footerList, setFooterList] = useState([]);
  const [sliderList, setSliderList] = useState([]);
  const [testimonialsList, setTestimonialsList] = useState([]);
  const [scopeAndResourcesList, setScopeAndResourcesList] = useState([]);
  const [whyusList, setWhyusList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [countriesList, setCountriesList] = useState(countries);
  const location = useLocation();

  const handleContactUs = async (data) => {
    setIsLoadingContact(TrendingUpTwoTone);
    await axios({
      method: "post",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/contact_us`,
      data,
    })
      .then((response) => {
        setIsLoadingContact(false);
        toast.success("Thanks for contacting us! We will be in touch with you shortly.");
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const [testimonialsIndex, setTestimonialsIndex] = useState(0);
  const handleChangeSlide = (selectedIndex, e) => {
    setTestimonialsIndex(selectedIndex);
  };

  const handleErrorContactUs = (errors) => {
    if (errors.name) toast.error(errors.name.message);
    else if (errors.email) toast.error(errors.email.message);
    else if (errors.school) toast.error(errors.school.message);
  };
  const validationOptions = {
    name: {required: "Name is required"},
    email: {
      required: "Email is required",
      pattern: {
        value: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
        message: "Enter a valid email address",
      },
    },
    school: {required: "School is required"},
  };

  const [data, setData] = useState({});
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setData(res.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/booksList`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/sliders`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/testimonials`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/scopeAndResources`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/whyus`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/footer`
          ),
        ])
        .then((response) => {
          setBooksList(response[0]?.data?.data);
          setSliderList(response[1]?.data?.data);
          setTestimonialsList(response[2]?.data?.data);
          setScopeAndResourcesList(response[3]?.data?.data);
          setWhyusList(response[4]?.data?.data);
          setFooterList(response[5]?.data?.data);
          setIsLoading(false);
        });
    };
    fetchData();
    getData();
  }, []);

  const [currentCountry, setCurrentCountry] = useState(0);
  useEffect(() => {
    if (!data || data.length < 1) return;

    let CountryID = countries.find(
      (country) => country?.name?.toLowerCase() == data?.country_name?.toLowerCase()
    )?.serial;
    if (CountryID) setCurrentCountry(CountryID.toString());
  }, [data]);

  return (
    <Layout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
      />
      {location.state && openModal ? (
        <div className="modal-container">
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="icon-box">
                  <i className="fas fa-check"></i>
                </div>
                <h4 className="modal-title w-100">{location.state.type}</h4>
              </div>
              <div className="text-center p-5">{location.state.message}</div>
              <div className="d-flex justify-content-center">
                <button className="btn btn-secondary py-3 px-5" onClick={() => setOpenModal(false)}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="topSliderContainer">
        <div className="container" style={{height: "0", position: "relative"}}>
          <div className="socialMediaCont" style={{zIndex: "2000"}}>
            <a href="//www.facebook.com/LinfoPourTous" target="_blank">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="//www.instagram.com/LinfoPourTous" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="//twitter.com/Linfopourtous/" target="_blank">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="//www.linkedin.com/in/wissamdaccache" target="_blank">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="//www.youtube.com/linfopourtousonline" target="_blank">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>

        {sliderList && sliderList?.length ? (
          <Carousel id="home" style={{width: "100%"}}>
            {sliderList?.map((e) => (
              <Carousel.Item interval={4000} key={e.serial}>
                <img
                  className="d-block float-right sliderImg"
                  src={process.env.REACT_APP_FILES_PATH + "/Sliders/" + e.image}
                  alt=""
                />
                <Carousel.Caption style={{top: 0, width: "100%"}} className="d-flex align-items-center">
                  <div className="container">
                    <div className="row">
                      <div className="sliderContentText text-left text-dark col-sm-6">
                        <h1 className="text-primary">{e.name}</h1>
                        <p style={{marginTop: "1.5rem"}}>{e.text}</p>
                        <a href={e.link} className="btn btn-secondary">
                          REGISTER
                        </a>
                      </div>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : null}
      </div>
      <div className="container">
        <div id="introduction" className="d-flex flex-column flex-sm-row py-5 my-5">
          <div className="w-sm-50 pr-2">
            <h3 className="text-secondary">Introduction</h3>
            <h2 className="text-primary">Your right track to STEAM!</h2>
            <p>
              Register to our Digital Platform and explore the resources that support your teaching journey.
              Find the flipbook, the dynamic curriculum, tutorials and all kind of resources pictures needed
              in each student book.
            </p>
            <p className="mb-5 d-none">
              <a href="#" onClick={(e) => e.preventDefault()} className="readMore">
                Read more &nbsp; <i className="fas fa-arrow-right"></i>
              </a>
            </p>
            <div className="d-flex justify-content-between">
              <div className="text-center">
                <h2 className="text-primary">500+</h2>Courses
              </div>
              <div className="text-center">
                <h2 className="text-primary">10k+</h2>Registered students
              </div>
              <div className="text-center">
                <h2 className="text-primary">900+</h2>Active teachers
              </div>
            </div>
          </div>
          <div>
            <div className="section-img float-right">
              <img src={`${process.env.PUBLIC_URL}/assets/images/introduction-img.png`} alt="" />
            </div>
          </div>
        </div>
        {whyusList && whyusList?.length ? (
          <div id="whyUs" className="py-5 my-5">
            <h3 className="text-secondary text-center">Why us</h3>
            <h2 className="text-primary text-center pb-5 mb-5">Why register with us?</h2>
            <div className="row">
              {whyusList.map((e) => (
                <div className="col-md-4 mb-5" key={e.serial}>
                  <div className="whyus-icon">
                    <img src={process.env.REACT_APP_FILES_PATH + "/WhyUs/" + e.icon} alt={e.name} />
                  </div>
                  <h4>{e.name}</h4>
                  <p>{e.text}</p>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div id="who" className="d-flex flex-column flex-sm-row py-5 my-5">
          <div className="pr-2">
            <div className="section-img">
              <img src={`${process.env.PUBLIC_URL}/assets/images/who-img.png`} alt="" />
            </div>
          </div>
          <div className="w-sm-50">
            <h3 className="text-secondary">WHO?</h3>
            <h2 className="text-primary">Who can register with us?</h2>
            <p>By having an active Ticket Number, you can have FREE access to the Digital Paltform.</p>
            <p className="mb-5">
              <a href="/register1" className="btn btn-secondary">
                REGISTER
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="#" onClick={(e) => e.preventDefault()} className="readMore d-none">
                Read more &nbsp; <i className="fas fa-arrow-right"></i>
              </a>
            </p>
          </div>
        </div>
        {scopeAndResourcesList && scopeAndResourcesList?.length ? (
          <div id="scopeAndSequence" className="d-grid py-5 my-5 ss">
            <div className="ss_sub ss_coding">
              <img
                className="ss_sub-img"
                src={
                  process.env.REACT_APP_FILES_PATH + "/ScopeAndResources/" + scopeAndResourcesList[0].image
                }
                alt={scopeAndResourcesList[0].name}
              />
              <h5 className="ss_sub-h5">{scopeAndResourcesList[0].name}</h5>
              <a
                href={scopeAndResourcesList[0].link}
                onClick={(e) => e.preventDefault()}
                className="ss_sub-a"
              >
                <div className="ss_sub-a-p w-100">
                  <h5>{scopeAndResourcesList[0].name}</h5>
                  <span className="d-none d-md-inline">{scopeAndResourcesList[0].text}</span>
                </div>
              </a>
            </div>
            <div className="ss_sub ss_robotics">
              <img
                className="ss_sub-img"
                src={
                  process.env.REACT_APP_FILES_PATH + "/ScopeAndResources/" + scopeAndResourcesList[1].image
                }
                alt={scopeAndResourcesList[1].name}
              />
              <h5 className="ss_sub-h5">{scopeAndResourcesList[1].name}</h5>
              <a
                href={scopeAndResourcesList[1].link}
                onClick={(e) => e.preventDefault()}
                className="ss_sub-a"
              >
                <div className="ss_sub-a-p w-100">
                  <h5>{scopeAndResourcesList[1].name}</h5>
                  <span className="d-none d-md-inline">{scopeAndResourcesList[1].text}</span>
                </div>
              </a>
            </div>
            <div className="ss_sub ss_engineering">
              <img
                className="ss_sub-img"
                src={
                  process.env.REACT_APP_FILES_PATH + "/ScopeAndResources/" + scopeAndResourcesList[2].image
                }
                alt={scopeAndResourcesList[2].name}
              />
              <h5 className="ss_sub-h5">{scopeAndResourcesList[2].name}</h5>
              <a
                href={scopeAndResourcesList[2].link}
                onClick={(e) => e.preventDefault()}
                className="ss_sub-a"
              >
                <div className="ss_sub-a-p w-100">
                  <h5>{scopeAndResourcesList[2].name}</h5>
                  <span className="d-none d-md-inline">{scopeAndResourcesList[2].text}</span>
                </div>
              </a>
            </div>
            <div className="ss_sub ss_microcontrol">
              <img
                className="ss_sub-img"
                src={
                  process.env.REACT_APP_FILES_PATH + "/ScopeAndResources/" + scopeAndResourcesList[3].image
                }
                alt={scopeAndResourcesList[3].name}
              />
              <h5 className="ss_sub-h5">{scopeAndResourcesList[3].name}</h5>
              <a
                href={scopeAndResourcesList[3].link}
                onClick={(e) => e.preventDefault()}
                className="ss_sub-a"
              >
                <div className="ss_sub-a-p w-100">
                  <h5>{scopeAndResourcesList[3].name}</h5>
                  <span className="d-none d-md-inline">{scopeAndResourcesList[3].text}</span>
                </div>
              </a>
            </div>
            <div className="ss_sub ss_drawing">
              <img
                className="ss_sub-img"
                src={
                  process.env.REACT_APP_FILES_PATH + "/ScopeAndResources/" + scopeAndResourcesList[4].image
                }
                alt={scopeAndResourcesList[4].name}
              />
              <h5 className="ss_sub-h5">{scopeAndResourcesList[4].name}</h5>
              <a
                href={scopeAndResourcesList[4].link}
                onClick={(e) => e.preventDefault()}
                className="ss_sub-a"
              >
                <div className="ss_sub-a-p w-100">
                  <h5>{scopeAndResourcesList[4].name}</h5>
                  <span className="d-none d-md-inline">{scopeAndResourcesList[4].text}</span>
                </div>
              </a>
            </div>
          </div>
        ) : null}
        {booksList && booksList?.length ? (
          <div id="takeTour" className="py-5 my-5">
            <div className="d-flex flex-column">
              <div className="w-sm-50">
                <h3 className="text-secondary">Take tour</h3>
                <h2 className="text-primary">Discover our Digital Paltform, Select a book!</h2>
                <p>
                  Select a book to take a tour and discover its content and all the related digital resources.
                </p>
              </div>
              <div className="mb-5">
                <a href="#" onClick={(e) => e.preventDefault()} className="readMore d-none">
                  Read more &nbsp; <i className="fas fa-arrow-right"></i>
                </a>
                <div className="float-right">
                  Do you have a ticket? &nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/register1" className="btn btn-secondary">
                    REGISTER A BOOK
                  </a>
                </div>
              </div>
              <div>
                <ItemsCarousel
                  requestToChangeActive={setActiveItemIndex}
                  activeItemIndex={activeItemIndex}
                  numberOfCards={5}
                  gutter={20}
                  rightChevron={
                    <i className="fas fa-angle-right text-primary" style={{fontSize: "40px"}}></i>
                  }
                  leftChevron={<i className="fas fa-angle-left text-primary" style={{fontSize: "40px"}}></i>}
                  outsideChevron
                  chevronWidth={40}
                  disableSwipe={false}
                  showSlither={true}
                >
                  {booksList &&
                    booksList?.length &&
                    booksList.map((e) => {
                      if (e.summaryPDF && e.cover) {
                        return !e.countries || e.countries == 0 || e.countries.includes(currentCountry) ? (
                          <a
                            className="text-center d-block"
                            key={e.serial}
                            href={`${process.env.REACT_APP_FILES_PATH}/summary/${e.serial}`}
                            target="_blank"
                          >
                            <img
                              src={`${process.env.REACT_APP_FILES_PATH}/Books/${e.cover}`}
                              alt=""
                              width="96%"
                            />
                            {e.name}
                          </a>
                        ) : null;
                      }
                    })}
                </ItemsCarousel>
              </div>
            </div>
          </div>
        ) : null}

        {testimonialsList && testimonialsList?.length ? (
          <div className="pb-5 mb-5 w-100" id="testimonials">
            <div className="d-flex flex-column flex-sm-row">
              <div className="pr-2 w-sm-50">
                <div className="section-img">
                  <img
                    src={
                      process.env.REACT_APP_FILES_PATH +
                      "/Testimonials/" +
                      testimonialsList[testimonialsIndex].image
                    }
                    alt=""
                    width="100%"
                  />
                </div>
              </div>
              <div className="w-sm-50 sliderContentText text-left text-dark">
                <h3 className="text-secondary">Testimonials</h3>
                <h2 className="text-primary">See what our teachers and students say about us</h2>

                <div className="position-relative">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/quotes.png`}
                    alt=""
                    className="testimonials_quotes"
                  />
                  <Carousel controls={false} onSelect={handleChangeSlide}>
                    {testimonialsList.map((e) => (
                      <Carousel.Item interval={6000} key={e.serial}>
                        <p className="mt-5">{e.text}</p>
                        <p className="mb-5">
                          <strong>
                            {e.name}
                            <br />
                            {e.school}
                          </strong>
                        </p>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div id="contactUs" className="py-5 my-5">
          <h3 className="text-secondary text-center">Contact us</h3>
          <h2 className="text-primary text-center">Lets get in touch.</h2>
          <p className="text-center pb-5 mb-5">
            Asking for details or facing a registration problem?
            <br />
            Send us a message, our team is waiting to serve you.
          </p>
          <div className="row justify-content-center">
            <div className="col-sm-8">
              <form
                onSubmit={handleSubmit(handleContactUs, handleErrorContactUs)}
                encType="multipart/form-data"
              >
                <div className="row g-3">
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="contactName"
                        placeholder=""
                        {...register("name", validationOptions.name)}
                      />
                      <label htmlFor="contactName">Name *</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="contactPosition"
                        aria-label="Floating label select"
                        {...register("position")}
                      >
                        <option value="teacher">Teacher</option>
                        <option value="student">Student</option>
                      </select>
                      <label htmlFor="contactPosition">Select your position</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="contactEmail"
                        placeholder=""
                        {...register("email", validationOptions.email)}
                      />
                      <label htmlFor="contactName">Email*</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="contactSubject"
                        placeholder=""
                        {...register("subject")}
                      />
                      <label htmlFor="contactName">Subject</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="contactPhone"
                        placeholder=""
                        {...register("phone")}
                      />
                      <label htmlFor="contactPhone">Phone (Int. format)</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="contactSchool"
                        placeholder=""
                        {...register("school", validationOptions.school)}
                      />
                      <label htmlFor="contactSchool">School*</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <select className="form-select" id="contactCountry" {...register("country")}>
                        {/* <option key="0">Open a country</option> */}
                        {countriesList.map((e) => (
                          <option value={e.name} key={e.serial}>
                            {e.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="contactCountry">Country*</label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder=""
                        id="contactMessage"
                        style={{height: "100px"}}
                        {...register("message")}
                      ></textarea>
                      <label htmlFor="contactMessage"> Type your message here </label>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="d-grid col-6 mx-auto">
                      {!isLoadingContact ? (
                        <button className="btn btn-secondary" type="submit">
                          SUBMIT FORM
                        </button>
                      ) : (
                        <CircularProgress size={20} className="mx-auto" />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Homepage;
