import React, {useState, useEffect} from "react";
import {getCookie} from "../../../../auth/helpers";
import {TextField, AppBar, Toolbar, Switch, Slide, Dialog} from "@material-ui/core";
import {useForm} from "react-hook-form";
import axios from "axios";
import NestedTable from "./NestedTable.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {countries} from "../../../../data/countries";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit, setValue, getValues, watch} = useForm({
    defaultValues: {
      school_serial: props?.formValues?.school_serial,
    },
  });

  const [books, setBooks] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [grades, setGrades] = useState([]);
  const [allGrades, setAllGrades] = useState(0);
  const genders = [{name: "Male"}, {name: "Female"}];
  const [countriesList, setCountriesList] = useState(countries);
  const [schools, setSchools] = useState([]);
  useEffect(() => {
    setGrades(props?.formValues?.grades);
    setBooks(props?.formValues?.books);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/grades`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setGrades(
          response?.data?.data.filter((e) => props?.formValues?.grades?.map((e) => e.name).includes(e.name))
        );
        setGradesList(response?.data?.data);
      });
    };
    fetchData();
  }, []);

  const handleOnSubmit = async (data) => {
    data.name = data.firstName + " " + data.lastName;
    let booksArray = books.length
      ? books.map((e) => ({
          serial: e.booksId || e.bookId,
          fromDate: e.fromDate,
          toDate: e.toDate,
        }))
      : [];
    data.verified = data.verified === true ? 1 : 0;
    data.active = data.active === true ? 1 : 0;
    let gradesArray = grades.length ? grades.map((e) => e.serial) : [];
    // setGrades(grades=grades.length?grades.map(e=>{e.serial=0; return e}):[])
    if (props.title.substring(0, 4) === "Edit") {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/teachers/${props.formValues?.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, books: booksArray, grades: gradesArray},
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/teachers`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, books: booksArray, grades: gradesArray},
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSelectAllGrades = () => {
    setAllGrades(1);
    setGrades(gradesList);
  };
  const handleRemoveAllGrades = () => {
    setAllGrades(0);
    setGrades([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/schools`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        const data = response?.data?.data;
        setSchools(data);
      });
    };
    fetchData();
  }, [props.openSchool]);

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      console.log("value", value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  {...register("firstName")}
                  defaultValue={props.formValues?.firstName}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last Name"
                  {...register("lastName")}
                  defaultValue={props.formValues?.lastName}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="gender">Gender</label>
                <select
                  className="form-select"
                  id="gender"
                  {...register("gender")}
                  defaultValue={props.formValues?.gender}
                >
                  <option value={null}></option>
                  {genders.map((e, i) => (
                    <option value={e.name} key={i}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  defaultValue={props.formValues?.name}
                />
              </div>
            </div> */}
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  {...register("password")}
                  defaultValue={props.formValues?.password}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                  {...register("phone")}
                  defaultValue={props.formValues?.phone}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  {...register("email")}
                  defaultValue={props.formValues?.email}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="school">School</label>
                <input
                  type="text"
                  className="form-control"
                  id="school"
                  placeholder="School"
                  {...register("school")}
                  defaultValue={props.formValues?.school}
                />
              </div>
            </div>

            <div className="col-sm-5">
              {/* <div className="form-group">
                <label htmlFor="school_serial">Listed School</label>
                {schools.length > 0 ? (
                  <select className="form-select" id="school_serial" {...register("school_serial")}>
                    <option value="" disabled>
                      Select School
                    </option>

                    {schools.map((e) => (
                      <option value={e.serial} key={e.serial}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select className="form-select" id="school_serial">
                    <option value={"0"}>-</option>
                  </select>
                )}
              </div> */}
              <label htmlFor="school_serial">Listed School</label>
              {schools.length > 0 && (
                <Autocomplete
                  style={{width: "100%"}}
                  {...register("school_serial")}
                  id="school_serial"
                  options={[{serial: 0, name: "-"}, ...schools]}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={(event, newValue) => setValue("school_serial", newValue.serial)}
                  value={schools.find((school) => school.serial == getValues("school_serial"))}
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => <TextField {...params} label="" placeholder="Schools" />}
                />
              )}
            </div>
            <div className="col-sm-1 align-self-end">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={() => props.setOpenSchool(true)}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                {gradesList?.length > 0 && (
                  <Autocomplete
                    multiple
                    id="grades"
                    fullWidth
                    options={gradesList}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setGrades(newValue.map((e) => ({serial: e.serial, name: e.name})));
                    }}
                    value={grades}
                    filterSelectedOptions
                    renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" label="Grades" placeholder="Grades" />
                    )}
                  />
                )}
                {allGrades === 0 ? (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelectAllGrades();
                    }}
                  >
                    <i className="fa fa-square"></i> Select All Grades
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveAllGrades();
                    }}
                  >
                    <i className="fa fa-check-square"></i> Remove All Grades
                  </a>
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <select
                  className="form-select"
                  id="country"
                  {...register("country")}
                  defaultValue={props.formValues?.country}
                >
                  <option>Open a country</option>
                  {countriesList.map((e) => (
                    <option value={e.name} key={e.serial}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="assistant_director">Name of the Academic Director</label>
                <input
                  type="text"
                  className="form-control"
                  id="assistant_director"
                  placeholder="Name of the Academic Director"
                  {...register("assistant_director")}
                  defaultValue={props.formValues?.assistant_director}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="notes">Notes</label>
                <textarea
                  className="form-control"
                  id="notes"
                  rows="3"
                  {...register("notes")}
                  defaultValue={props.formValues?.notes}
                ></textarea>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="ml-4 form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="verified"
                  defaultChecked={props.formValues?.verified ? true : false}
                  {...register("verified")}
                />
                <label className="form-check-label" htmlFor="verified">
                  Verified
                </label>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="ml-4 form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="manual"
                  defaultChecked={props.formValues?.manual ? true : false}
                  disabled
                />
                <label className="form-check-label" htmlFor="manual">
                  Manual
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <NestedTable arrayName={books} setArrayName={setBooks} title="Books" dbTable="books" />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="ml-4 form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="active"
                  defaultChecked={props.formValues?.active ? true : false}
                  {...register("active")}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button
                // type="button"
                className="btn btn-lg btn-primary"
                type="submit"
              >
                <i className="fas fa-save"></i> Save
              </button>
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
