import React, {useState, useEffect} from "react";
import {getCookie, isAuth} from "../../../auth/helpers";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Container, Dialog, Slide, TextField, CircularProgress, AppBar, Toolbar} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";
import AddVisitFormDialog from "../RepresentativeVisits/Components/AddFormDialog";
//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from "axios";
import RepresentativeVisits from "../RepresentativeVisits/RepresentativeVisits";
import {countries} from "../../../data/countries";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Schools = () => {
  const token = getCookie("token");
  const user = isAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [openAddVisit, setOpenAddVisit] = useState(false); //for modal
  const [openVisits, setOpenVisits] = useState(false); //for modal
  const [RowID, setRowID] = useState(0); //current row
  const [modal_Title, setmodal_Title] = useState("Add"); //modal title
  const [formTitle, setFormTitle] = useState("Add School");

  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items

  const handleCloseAddVisit = () => {
    setOpenAddVisit(false);
  };
  const handleAddVisit = () => {
    setOpenAddVisit(true);
  };
  const [pagingInfo, setPagingInfo] = useState({
    page: 0,
    limit: 20,
    skip: 0,
    count: 20,
  }); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry

  const [filters, setFilters] = useState({
    countries: [],
    users: [],
    adoptions: [],
    using: null,
    schoolTypes: [],
    visitsNumber: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/schools?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ...filters,
            using: filters?.using?.id,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.count});
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [open, pagingInfo.page, pagingInfo.limit, searchEntry, filters]);

  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    await axios(
      `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/users?profile=2`,
      {
        responseType: "json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      const data = response?.data?.data;
      setUsers(data);
    });
  };

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },

    {
      name: "name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return user._id == tableMeta.rowData[3] || user.profile == 1 ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleAdd("Edit School - " + tableMeta.rowData[1], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          ) : (
            <span>{value}</span>
          );
        },
      },
    },

    {
      name: "educational_company",
      label: "Educational Company",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => <span>{value?.name}</span>,
        display: false,
      },
    },

    {
      name: "user_id",
      label: "user_id",
      options: {
        display: false,
      },
    },
    {
      name: "email",
      options: {
        display: false,
      },
    },

    {
      name: "country",
      options: {
        display: false,
      },
    },

    {
      name: "address",
      options: {
        display: false,
      },
    },
    {
      name: "boys_section",
      label: "Boys Section",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },

    {
      name: "girls_section",
      label: "Girls Section",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },

    {
      name: "school_type",
      label: "School Type",
      options: {
        display: false,
      },
    },
    {
      name: "user_name",
      label: "Representative",
    },
    {
      name: "visits",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta.rowData);
          return user._id == tableMeta.rowData[3] || user.profile == 1 ? (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleVisits(tableMeta.rowData[1] + " Visits", tableMeta.rowData[0]);
              }}
            >
              {/* {value?.length} visits */}
              {value} visits
            </a>
          ) : (
            // <span href="#">{value?.length} visits</span>
            <span href="#">{value} visits</span>
          );
        },
      },
    },

    {
      name: "users",
      label: "Using books",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },

    {
      name: "academic_director1",
      label: "Academic Director (Boys) Name",
      options: {
        display: false,
      },
    },
    {
      name: "academic_director1_email",
      label: "Academic Director (Boys) Email",
      options: {
        display: false,
      },
    },
    {
      name: "academic_director1_whatsapp",
      label: "Academic Director (Boys) Whatsapp",
      options: {
        display: false,
      },
    },

    {
      name: "academic_director2",
      label: "Academic Director (Girls) Name",
      options: {
        display: false,
      },
    },

    {
      name: "academic_director2_email",
      label: "Academic Director (Girls) Email",
      options: {
        display: false,
      },
    },
    {
      name: "academic_director2_whatsapp",
      label: "Academic Director (Girls) Whatsapp",
      options: {
        display: false,
      },
    },

    {
      name: "adoption",
      label: "Adoption",
    },
    {
      name: "periods_per_week",
      label: "Periods per week",
      options: {
        display: false,
      },
    },
    {
      name: "labs",
      label: "Labs #",
      options: {
        display: false,
      },
    },

    {
      name: "mBot2",
      label: "mBot2 used",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "Microbit",
      label: "Microbit used",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "Kitronik",
      label: "Kitronik used",

      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "Arduino",
      label: "Arduino used",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "date_offered",
      label: "Date offered",
      options: {
        display: false,
      },
    },

    {
      name: "mBot2_offered",
      label: "mBot2 offered",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "Microbit_offered",
      label: "Microbit offered",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "Kitronik_offered",
      label: "Kitronik offered",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
    {
      name: "Arduino_offered",
      label: "Arduino offered",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative"}} className="fas fa-times text-danger"></i>
          ),
      },
    },

    {
      name: "remarks",
      label: "Remarks",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    selectableRows: user.role === "admin" && user.profile == 1 ? true : "none",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleAdd("Add School");
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/schools/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setItems(response?.data?.data);
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
    // serverSide: true,
    // count:pagingInfo.count, // Use total number of items
    // page: pagingInfo.page,
    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPagingInfo({...pagingInfo,page:tableState.page,skip:tableState.page*pagingInfo.limit});
    //   }else if(action === "changeRowsPerPage"){
    //     setPagingInfo({...pagingInfo,limit:tableState.rowsPerPage});
    //   }
    // }
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };

  const handleClickOpen = (rowID = 1, modal_Title = "Add") => {
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, id) => {
    if (id) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/schools/${id}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          const data = response.data;
          setFormValues({
            serial: data.serial,
            user_id: data.user_id,
            educational_company_serial: data.educational_company_serial,
            name: data.name,
            country: data.country,
            address: data.address,
            email: data.email,

            academic_director1: data.academic_director1,
            academic_director1_email: data.academic_director1_email,
            academic_director1_whatsapp: data.academic_director1_whatsapp,

            academic_director2: data.academic_director2,
            academic_director2_email: data.academic_director2_email,
            academic_director2_whatsapp: data.academic_director2_whatsapp,

            educational_representative_name: data.educational_representative_name,
            educational_representative_email: data.educational_representative_email,
            educational_representative_whatsapp: data.educational_representative_whatsapp,
            boys_section: data.boys_section == 1,
            girls_section: data.girls_section == 1,
            school_type: data.school_type,
            users: data.users == 1,
            adoption: data.adoption,
            periods_per_week: data.periods_per_week,
            labs: data.labs,

            mBot2: data.mBot2 == 1,
            Microbit: data.Microbit == 1,
            Kitronik: data.Kitronik == 1,
            Arduino: data.Arduino == 1,

            mBot2_offered: data.mBot2_offered == 1,
            Microbit_offered: data.Microbit_offered == 1,
            Kitronik_offered: data.Kitronik_offered == 1,
            Arduino_offered: data.Arduino_offered == 1,

            date_offered: data.date_offered,

            remarks: data.remarks,
          });
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        user_id: "",
        educational_company_serial: 0,
        name: "",
        country: "",
        address: "",
        email: "",
        academic_director1: "",
        academic_director1_email: "",
        academic_director1_whatsapp: "",
        academic_director2: "",
        academic_director2_email: "",
        academic_director2_whatsapp: "",
        educational_representative_name: "",
        educational_representative_email: "",
        educational_representative_whatsapp: "",
        boys_section: "",
        girls_section: "",
        school_type: "",
        users: "",
        adoption: "",
        periods_per_week: "",
        labs: "",
        mBot2: "",
        Microbit: "",
        Kitronik: "",
        Arduino: "",
        mBot2_offered: "",
        Microbit_offered: "",
        Kitronik_offered: "",
        Arduino_offered: "",
        date_offered: "",
        remarks: "",
      });
      setFormTitle(title);
      setOpen(true);
    }
  };

  const [schoolId, setSchoolId] = useState(0);
  const handleVisits = (title, id) => {
    setFormTitle(title);
    setSchoolId(id);
    setOpenVisits(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseVisitis = () => {
    setOpenVisits(false);
  };

  //Search component ---------------START--------------
  const handleChangeSearch = (e, newValue) => {
    setSearchEntry(newValue);
  };
  //Search component ---------------END--------------

  const customOnChange = (newValue, name) => {
    setFilters((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  useEffect(() => {
    if (users == 0) fetchUsers();
  }, []);

  return (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5">
        <div className="row mb-5">
          <div className="col-sm-4">
            <CustomAutocomplete
              filedName="countries"
              label="Filter by countries"
              list={countries}
              values={filters.countries}
              listKey="name"
              description="name"
              customOnChange={customOnChange}
            />
          </div>

          <div className="col-sm-4">
            <CustomAutocomplete
              filedName="users"
              label="Filter by representative"
              list={users}
              values={filters.users}
              listKey="id"
              description="name"
              customOnChange={customOnChange}
            />
          </div>

          <div className="col-sm-4">
            <CustomAutocomplete
              filedName="adoptions"
              label="Filter by adoptions"
              list={[
                {id: "righ_click", name: "Right Click"},
                {id: "zero_one", name: "ZERO1"},
                {id: "passeport_informatique", name: "Passeport Informatique"},
                {id: "zakaa", name: "ZAKAA"},
              ]}
              values={filters.adoptions}
              listKey="id"
              description="name"
              customOnChange={customOnChange}
            />
          </div>

          <div className="col-sm-4">
            <CustomAutocomplete
              filedName="schoolTypes"
              label="Filter by school type"
              list={[
                {id: "national", name: "National"},
                {id: "international", name: "International"},
                {id: "national_international", name: "National/International"},
              ]}
              values={filters.schoolTypes}
              listKey="id"
              description="name"
              customOnChange={customOnChange}
            />
          </div>

          <div className="col-sm-4">
            <Autocomplete
              id="using"
              fullWidth
              options={[
                {id: 2, name: "Not using"},
                {id: 1, name: "Using"},
              ]}
              value={filters.using}
              getOptionLabel={(option) => option.name || null}
              onChange={(e, newValue) => setFilters((prev) => ({...prev, using: newValue}))}
              renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
              renderInput={(params) => (
                <TextField {...params} label="Filter by using books" variant="standard" />
              )}
            />
          </div>

          <div className="col-sm-4">
            <TextField
              type="number"
              style={{width: "100%"}}
              id="number_of_visits"
              label="Filter by number of visits"
              variant="standard"
              value={filters.visitsNumber}
              onChange={(e) => setFilters((prev) => ({...prev, visitsNumber: e.target.value}))}
            />
          </div>
        </div>

        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AddFormDialog title={formTitle} handleClose={handleClose} formValues={formValues} />
          </Dialog>
          {/*********************** FILTER start ****************************/}
          {/* <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} />
        </Dialog> */}

          <Dialog fullScreen open={openVisits} onClose={handleCloseVisitis} TransitionComponent={Transition}>
            <AppBar className="position-relative">
              <Toolbar>
                <button type="button" className="close" onClick={handleCloseVisitis}>
                  &times;
                </button>
                <h4 className="text-white mx-3 my-0">{formTitle}</h4>
              </Toolbar>
            </AppBar>

            <RepresentativeVisits handleAddVisit={handleAddVisit} schoolId={schoolId} />
          </Dialog>

          <Dialog
            fullScreen
            open={openAddVisit}
            onClose={handleCloseAddVisit}
            TransitionComponent={Transition}
          >
            <AddVisitFormDialog
              title={"Add Visit"}
              setOpenSchool={setOpen}
              schools={user.profile == 2 ? items.filter((item) => item.user_id == user._id) : items}
              handleClose={handleCloseAddVisit}
              formValues={{
                date: "",
                school_serial: schoolId,
                action_taken: "",
                meeting: "",
                delivering: "",
                remarks: "",
              }}
            />
          </Dialog>
        </div>
      </div>
    </AdminLayout>
  );
};
export default Schools;
