import React from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit} = useForm();

  const handleOnSubmit = async (data) => {
    if (props.title.substring(0, 4) === "Edit") {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/grades/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/grades`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Name/Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  defaultValue={props.formValues.name}
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-lg btn-primary" type="submit">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
