import React, {useState, useEffect} from "react";
import {getCookie} from "../../../../auth/helpers";
import {TextField, CircularProgress} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import CustomToolbar from "../../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {ToastContainer, toast} from "react-toastify";
import moment from "moment";
import axios from "axios";

const NestedTable = (props) => {
  const token = getCookie("token");
  const [dataList, setDataList] = useState([]);
  const [rowData, setRowData] = useState({
    serial: 0,
    booksId: "",
    name: "",
    fromDate: "",
    toDate: "",
    grade: "",
    ticketNumber: "",
  });
  const add1Row = () => {
    if (!rowData.name) {
      props.setArrayName([
        ...props.arrayName,
        {serial: 0, booksId: "", name: "", fromDate: "", toDate: "", grade: "", ticketNumber: ""},
      ]);
    }
  };
  const handleChangeName = (e, newValue, currentSerial) => {
    if (currentSerial) {
      props.setArrayName(
        props.arrayName.map((e) => {
          if (e.serial === currentSerial) {
            e.booksId = newValue.serial;
          }
          return e;
        })
      );
    } else {
      setRowData({...rowData, booksId: newValue.serial, name: newValue.name, grade: newValue.grade});
    }
  };
  const handleChangeTextfield = (e, currentSerial) => {
    const {name, value} = e.target;
    if (currentSerial) {
      props.setArrayName(
        props.arrayName.map((e) => {
          if (e.serial === currentSerial) {
            if (name === "fromDate") e.fromDate = value;
            else if (name === "toDate") e.toDate = value;
          }
          return e;
        })
      );
    } else {
      setRowData({...rowData, [name]: value});
    }
  };
  const handleAddRow = async (e) => {
    if (rowData.name) {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/validateTicket`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {ticket_number: rowData.ticketNumber},
      })
        .then((response) => {
          if (response.data.valid === true) {
            props.setArrayName([...props.arrayName.filter((e) => e.name !== ""), rowData]);
            setRowData({
              serial: 0,
              booksId: "",
              name: "",
              fromDate: "",
              toDate: "",
              grade: "",
              ticketNumber: "",
            });
            toast.success("Valid ticket number");
          } else {
            toast.error("Ticket number not valid");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let dbTable = props.dbTable;
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/${dbTable}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setDataList(response?.data?.data);
        return response?.data?.data;
      });
    };
    fetchData();
  }, []);

  return (
    <>
      <ToastContainer />
      <MUIDataTable
        data={props.arrayName}
        title={props.title}
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Autocomplete
                    defaultValue={{name: value || ""}}
                    id={`${props.title}Input`}
                    name="name"
                    options={dataList || {}}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, newValue) => handleChangeName(e, newValue, tableMeta.rowData[0])}
                    renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                    renderInput={(params) => <TextField {...params} label={`Add new ${props.title}`} />}
                  />
                );
              },
            },
            // options: {
            //   filter: false,
            //   customBodyRender: (value, tableMeta, updateValue) => {
            //     if (value == "") {
            //       return (
            //         <div>
            //           <Autocomplete
            //             id={`${props.title}Input`}
            //             name="name"
            //             options={dataList || {}}
            //             getOptionLabel={(option) => option.name}
            //             onChange={handleChangeName}
            //             renderInput={(params) => (
            //               <TextField
            //                 {...params}
            //                 label={`Add new ${props.title}`}
            //               />
            //             )}
            //           />
            //         </div>
            //       );
            //     } else {
            //       return value;
            //     }
            //   },
            // },
          },
          {
            name: "fromDate",
            label: "From",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const defaultValue = value ? moment(value).format("YYYY-MM-DD") : "";
                return (
                  <TextField
                    id="fromDate"
                    label="From"
                    name="fromDate"
                    type="date"
                    fullWidth
                    defaultValue={defaultValue}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => handleChangeTextfield(e, tableMeta.rowData[0])}
                  />
                );
              },
            },
          },
          {
            name: "toDate",
            label: "To",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const defaultValue = value ? moment(value).format("YYYY-MM-DD") : "";
                return (
                  <TextField
                    id="toDate"
                    label="To"
                    name="toDate"
                    type="date"
                    fullWidth
                    defaultValue={defaultValue}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => handleChangeTextfield(e, tableMeta.rowData[0])}
                  />
                );
              },
            },
          },
          {
            name: "grade",
            label: "Grade",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return tableMeta.rowData[1] ? value : rowData.grade;
              },
            },
          },
          {
            name: "ticketNumber",
            label: "Ticket Number",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (value == "") {
                  return (
                    <input
                      type="text"
                      className="form-control"
                      name="ticketNumber"
                      placeholder="Tiket Number"
                      onChange={handleChangeTextfield}
                    />
                  );
                } else {
                  return value;
                }
              },
            },
          },
          {
            name: "",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (tableMeta.rowData[1]) {
                  return "";
                } else {
                  return (
                    <button type="button" className="btn btn-sm btn-primary" onClick={handleAddRow}>
                      <i className="fa fa-search"></i> Validate
                    </button>
                  );
                }
              },
            },
          },
        ]}
        options={{
          filter: false,
          customToolbar: () => {
            return <CustomToolbar listener={add1Row} />;
          },
          textLabels: {
            body: {
              noMatch: !props.isLoading && "Sorry, there is no matching data to display",
            },
          },
          onRowsDelete: (rowsDeleted, dataRows) => {
            const idsToDelete = rowsDeleted.data.map((d) => props.arrayName[d.dataIndex].serial);
            const rowsToKeep = props.arrayName.filter((e) => !idsToDelete.includes(e.serial));
            props.setArrayName(rowsToKeep);
          },
        }}
      />
    </>
  );
};

export default NestedTable;
