import React, {useState, useEffect} from "react";
import Layout from "../../core/Layout";
import {CircularProgress} from "@material-ui/core";
import {getCookie, isAuth} from "../../auth/helpers";
import Button from "react-bootstrap/Button";
import axios from "axios";
import QuestionsAddModal from "./Components/QuestionsAddModal";

const QuestionsList = (props) => {
  const token = getCookie("token");
  const [questionsList, setQuestionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [quizzesList, setQuizzesList] = useState([]);
  const [quizQuestionsList, setQuizQuestionsList] = useState([]);
  const [answersList, setAnswersList] = useState([]);
  // const [chaptersList, setChaptersList] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quizs/${props.match.params.quizId}`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/questions`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/question/${props.match.params.quizId}`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/answers`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/chapters`
          ),
        ])
        .then((response) => {
          const chapterId = response[0].data.chapter_id;
          // setQuizzesList(response[0].data);
          setQuestionsList(response[1]?.data?.data.filter((e) => e.chapter_id === chapterId));
          setQuizQuestionsList(response[2].data);
          setAnswersList(response[3]?.data?.data);
          // setChaptersList(response[1]?.data?.data.length?response[1]?.data?.data.filter(e=>e.serial==chapterId):[]);
          return response[2].data;
        })
        .then((response) => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <div className="container pt-5 mt-5">
        <div id="register2" className="py-5 my-5">
          <div className="d-flex flex-column pt-5 mt-5">
            <div className="text-center">
              <h3 className="text-secondary mb-0">Teacher's Quiz</h3>
              <h2 className="text-primary mb-5">Selected Questions</h2>
            </div>
            <div className="d-flex ">
              <Button className="btn btn-secondary ml-auto" onClick={() => setModalShow(true)}>
                Select Questions
              </Button>
              <QuestionsAddModal
                answersList={answersList}
                show={modalShow}
                onHide={() => setModalShow(false)}
                questionsList={questionsList}
                quizQuestionsList={quizQuestionsList}
                setQuizQuestionsList={setQuizQuestionsList}
                quizId={props.match.params.quizId}
                setModalShow={setModalShow}
              />
            </div>
            {!isLoading ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" width="50">
                      #
                    </th>
                    <th scope="col" width="100">
                      Image
                    </th>
                    <th scope="col">Question</th>
                    <th scope="col">Question Grade</th>
                    <th scope="col">Answers</th>
                  </tr>
                </thead>
                <tbody>
                  {quizQuestionsList.length ? (
                    questionsList.map((e, i) => {
                      const currentQuizQuestion = quizQuestionsList.filter(
                        (eSub) => eSub.question_id === e.serial
                      );
                      const currentQuestionAnswers = answersList.filter(
                        (eSub) => eSub.question_id === e.serial
                      );

                      return currentQuizQuestion.length ? (
                        <tr key={e.serial}>
                          <td scope="row">{i + 1} </td>
                          <td>
                            <img
                              src={process.env.REACT_APP_FILES_PATH + "/Questions/" + e.image}
                              alt=""
                              className="img-thumbnail rounded"
                              style={{maxWidth: "80px", maxHeight: "80px"}}
                            />
                          </td>
                          <td>{e.question}</td>
                          <td>{currentQuizQuestion[0].mark}</td>
                          <td>
                            {currentQuestionAnswers.map((e, i) => (
                              <div className={`font-weight-bold ${e.correct ? "text-success" : null}`}>
                                {e.answer}
                              </div>
                            ))}
                          </td>
                        </tr>
                      ) : null;
                    })
                  ) : (
                    <td colspan="5" className="text-center">
                      <small>You haven't picked any quetion for this quiz yet</small>
                    </td>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="text-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default QuestionsList;
