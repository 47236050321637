import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import {NavLink, Link} from "react-router-dom";
import {isAuth} from "../auth/helpers";

const AdminSidebar = () => {
  const openSubMenu = (idOfSubMenu) => {
    if (document.getElementById("sub_" + idOfSubMenu).style.marginTop != "0px") {
      document.getElementById(idOfSubMenu).style.height = "auto";
      document.getElementById("sub_" + idOfSubMenu).style.marginTop = 0;
    } else {
      // const subSelector = document.querySelectorAll(".sub_subMenuLink");
      // subSelector.forEach((e) => (e.style.marginTop = "-150%"));

      document.getElementById("sub_" + idOfSubMenu).style.marginTop = "-150%";
    }
  };

  const adminUser = isAuth();

  //   const openSubMenu = (idOfSubMenu) => {
  //   const subSelector = document.querySelectorAll(".sub_subMenuLink");
  //   subSelector.forEach((e) => (e.style.marginTop = "-150%"));

  //   document.getElementById(idOfSubMenu).style.height = "auto";
  //   document.getElementById("sub_" + idOfSubMenu).style.marginTop = 0;
  // };
  return (
    //////////////ICON LIST: https://mdbootstrap.com/docs/react/content/icons-list/
    <CDBSidebar textColor="#fff" backgroundColor="#333">
      {adminUser && adminUser.role === "admin" && (
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <Link to="/" className="nav-link d-inline-block sidebar_pageTitle" style={{padding: "0 45px"}}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo_admin.jpg`} alt="" />
          </Link>
        </CDBSidebarHeader>
      )}
      {adminUser && adminUser.role === "admin" && (
        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink
              exact
              to="/admin/users"
              activeClassName="activeClicked"
              style={{display: adminUser.profile == 1 ? "" : "none"}}
            >
              <CDBSidebarMenuItem icon="users">Users</CDBSidebarMenuItem>
            </NavLink>
            <div
              style={{display: adminUser.profile == 1 ? "" : "none"}}
              className="menuLink"
              onClick={() => openSubMenu("Education")}
            >
              Digital Platform
            </div>
            <div
              style={{display: adminUser.profile == 1 ? "" : "none"}}
              className="subMenuLink"
              id="Education"
            >
              <div
                style={{display: adminUser.profile == 1 ? "" : "none"}}
                className="sub_subMenuLink"
                id="sub_Education"
              >
                <NavLink exact to="/admin/books" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="book">Books</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/tickets" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="ticket">Tickets</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/grades" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="ruler-horizontal">Grades</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/teachers" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="chalkboard-teacher">Teachers</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/students" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="street-view">Students</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/admin/ExtraResourcesCategories" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="list">Categories for Extra Resources</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/admin/ExtraResources" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="plus">Extra Resources</CDBSidebarMenuItem>
                </NavLink>
              </div>
            </div>
            <div
              style={{display: adminUser.profile == 1 ? "" : "none"}}
              className="menuLink"
              onClick={() => openSubMenu("Sections")}
            >
              Frontend
            </div>
            <div
              style={{display: adminUser.profile == 1 ? "" : "none"}}
              className="subMenuLink"
              id="Sections"
            >
              <div className="sub_subMenuLink" id="sub_Sections">
                <NavLink exact to="/admin/sliders" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="columns">Sliders</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/testimonials" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="columns">Testimonials</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/scopeAndResources" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="columns">Scope And Resources</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/whyus" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="question-circle">Why Us</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/footer" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="columns">Footer</CDBSidebarMenuItem>
                </NavLink>
              </div>
            </div>
            <div
              style={{display: adminUser.profile == 1 ? "" : "none"}}
              className="menuLink"
              onClick={() => openSubMenu("Personal")}
            >
              Quiz Generator
            </div>
            <div
              style={{display: adminUser.profile == 1 ? "" : "none"}}
              className="subMenuLink"
              id="Personal"
            >
              <div className="sub_subMenuLink" id="sub_Personal">
                <NavLink exact to="/admin/chapters" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="question-circle">Chapter/Grade</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/quizzes" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="question-circle">Quizzes</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/myclass" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="question-circle">Classes</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/admin/quizzesResult" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="question-circle">Quizzes Results</CDBSidebarMenuItem>
                </NavLink>
              </div>
            </div>

            <div
              style={{display: adminUser.profile == 1 ? "" : "none"}}
              className="menuLink"
              onClick={() => openSubMenu("Representative")}
            >
              Representative
            </div>
            <div style={{height: "auto"}} className="subMenuLink" id="Representative">
              <div style={{marginTop: 0}} className="sub_subMenuLink" id="sub_Representative">
                <NavLink exact to="/admin/educationalCompanies" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="building">Educational Companies</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/admin/schools" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="school">Schools</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/admin/representativeVisits" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="pen">Visits</CDBSidebarMenuItem>
                </NavLink>
              </div>
            </div>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      )}
      {adminUser && adminUser.role === "admin" && (
        <CDBSidebarFooter>
          <CDBSidebarMenuItem icon="user">{adminUser.name || ""}</CDBSidebarMenuItem>
        </CDBSidebarFooter>
      )}
    </CDBSidebar>
  );
};

export default AdminSidebar;
