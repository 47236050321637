import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {authenticate, isAuth} from "../../auth/helpers";
import {ToastContainer, toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import "../../assets/css/styles.css";
import axios from "axios";

const SigninFrontend = ({history}) => {
  const {register, handleSubmit, getValues} = useForm();
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);
  const [activationRequest, setActivationRequest] = useState(false);

  const handleOnSubmit = async (data) => {
    await axios({
      method: "post",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/user/login`,
      data: {...data},
    })
      .then((response) => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;

        authenticate(response, () => {
          isAuth() && isAuth().role !== "admin"
            ? isAuth().role === "teacher"
              ? history.push(`/teachersBook/${response.data.user._id}`)
              : history.push(`/books/${response.data.user._id}`)
            : history.push("/");
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(error?.response?.data?.error);
        }

        if (error.response.status === 403) {
          setActivationRequest(true);

          toast.info(
            <div className="ml-auto text-center">
              <p>{error.response.data.error}</p>
              <button
                className="btn btn-warning"
                onClick={() => requestActivation(error.response.data.serial)}
              >
                <strong>Request a free re-activation</strong>
              </button>
            </div>,
            {
              autoClose: 60000,
            }
          );
          // toast.error(error.response.data.error);
        }
      });
  };

  const verifyRecaptcha = async (e) => {
    if (e) {
      setRecaptchaSuccess(true);
    }
    let result = await axios(
      {
        method: "post",
        url: "https://www.google.com/recaptcha/api/siteverify",
        params: {
          secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
          response: e,
        },
      },
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      }
    );
    let data = result.data || {};
    if (!data.success) {
      throw {
        success: false,
        error: "response not valid",
      };
    }
  };

  const requestActivation = async (serial) => {
    history.push({
      pathname: `/verify/${serial}`,
      state: serial,
    });

    return;
    setActivationRequest(false);
    const email = getValues("email");

    await axios({
      method: "post",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/user/request-activation`,
      data: {email},
    })
      .then((response) => {
        toast.success(
          <div className="ml-auto text-center">
            <p>
              Your request has been sent to the admin for approval, allow us 24 hours to process your approval
            </p>
          </div>
        );
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong, please try again");
      });
  };
  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
      />
      <div className="row">
        <div className="col-md-5 offset-md-1 d-flex flex-column p-5">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo.jpg`}
              alt="L'info Pour Tous"
              style={{height: "9rem"}}
            />
          </a>
          <div className="flex-grow-1 d-flex flex-column justify-content-center">
            <h3 className="text-secondary">LOG IN</h3>
            <h2 className="text-primary mb-5">Welcome back</h2>

            <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
              <div className="mb-4 d-flex">
                <div className="form-check px-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="typeTeacher"
                    value={"teacher"}
                    {...register("type")}
                  />
                  <label className="form-check-label" htmlFor="typeTeacher">
                    Teacher
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="typeStudent"
                    value={"student"}
                    {...register("type")}
                    defaultChecked={true}
                  />
                  <label className="form-check-label" htmlFor="typeStudent">
                    Student
                  </label>
                </div>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="signinUsername"
                  placeholder=""
                  {...register("email")}
                />
                <label htmlFor="signinUsername">Username</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="password"
                  className="form-control"
                  id="signinPassword"
                  placeholder=""
                  {...register("password")}
                />
                <label htmlFor="signinPassword">Password</label>
              </div>
              {/* {activationRequest && (
                <div className="col-sm-4 mb-4 pl-0">
                  <a href="#" onClick={requestActivation}>
                    <strong>Request re-activation</strong>
                  </a>
                </div>
              )} */}
              <div className="col-sm-4 mb-4 pl-0" id="recaptcha">
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={verifyRecaptcha} />
              </div>

              {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
                <button className="btn btn-secondary" type="submit">
                  LOGIN
                </button>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between pb-5">
                <button
                  className="btn btn-secondary"
                  type="submit"
                  disabled={recaptchaSuccess ? false : true}
                >
                  LOGIN
                </button>
                <a href="/changePassword">Forgot password?</a>
              </div>
            </form>
          </div>
          <div className="d-flex justify-content-between">
            <a href="/" className="readMore">
              <i className="fas fa-arrow-left"></i> &nbsp; Back
            </a>
            <div>
              Not sign up yet? <a href="/register1">Click here!</a>
            </div>
          </div>
        </div>
        <div
          className="col-md-5 offset-md-1 signinImg"
          style={{
            background: `url(${process.env.PUBLIC_URL + "/assets/images/loginImg.png"})`,
          }}
        ></div>
      </div>
    </div>
  );
};
export default SigninFrontend;
