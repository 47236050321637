import React, {useState, useEffect} from "react";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Container, Dialog, Slide, TextField, Chip, CircularProgress} from "@material-ui/core";
import {getCookie} from "../../../auth/helpers";
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";

//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import QuizDetails from "./Components/QuizDetails.js";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuizzesResult = (props) => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [formTitle, setFormTitle] = useState("");
  const [formSerial, setFormSerial] = useState("");
  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [pagingInfo, setPagingInfo] = useState({page: 0, limit: 20, skip: 0, count: 20}); //Pagination Info
  const [quizQuestionsList, setQuizQuestionsList] = useState([]);
  const [quizStudentsList, setQuizStudentsList] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [chaptersList, setChaptersList] = useState([]);
  const [teachersList, setTeachersList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quizs`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/questions`,
            {
              headers: {Authorization: `Bearer ${token}`},
            }
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/students`,
            {
              headers: {Authorization: `Bearer ${token}`},
            }
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/chapters`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/teachers`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
        ])
        .then((response) => {
          setItems(response[0]?.data?.data);
          setQuizQuestionsList(response[1]?.data?.data);
          setQuizStudentsList(response[2]?.data?.data);
          setChaptersList(response[3]?.data?.data);
          setTeachersList(response[4]?.data?.data);
          setIsLoading(false);
        });
    };
    fetchData();
  }, [open]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //       await axios.all([
  //         axios.get(`${window?.location?.hostname?.includes('maroc') ? process.env.REACT_APP_API_MAROC : process.env.REACT_APP_API}/chapters`,{headers: {Authorization: `Bearer ${token}`}}),
  //         axios.get(`${window?.location?.hostname?.includes('maroc') ? process.env.REACT_APP_API_MAROC : process.env.REACT_APP_API}/teachers`,{headers: {Authorization: `Bearer ${token}`}}),
  //         axios.get(`${window?.location?.hostname?.includes('maroc') ? process.env.REACT_APP_API_MAROC : process.env.REACT_APP_API}/questions`,{headers: {Authorization: `Bearer ${token}`}})
  //       ]).then(response => {
  //         setChaptersList(response[0]?.data?.data)
  //         setTeachersList(response[1]?.data?.data)
  //         setQuestionsList(response[2]?.data?.data)
  //       })
  //   };
  //   fetchData();
  // }, []);

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Quiz's Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              onClick={() => {
                handleAdd("Quiz Details - " + tableMeta.rowData[1], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "chapter_id",
      label: "Chapter's Title",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const chapter = chaptersList.filter((e) => e.serial === value);
          return chapter.length ? chapter[0].name : "-";
        },
      },
    },
    {
      name: "teacher_id",
      label: "Teacher's Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const teacher = teachersList.filter((e) => e.serial === value);
          return teacher.length ? teacher[0].name : "-";
        },
      },
    },
    {name: "note", label: "Remarks"},
  ];

  const options = {
    filter: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return;
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, quizzesId) => {
    setFormTitle(title);
    setFormSerial(quizzesId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Search component ---------------END--------------
  return (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5 pt-5">
        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <QuizDetails
              title={formTitle}
              serial={formSerial}
              handleClose={handleClose}
              chaptersId={props.match.params.chapterId}
              formValues={formValues}
              setFormValues={setFormValues}
              questionsList={questionsList}
              setQuestionsList={setQuestionsList}
              chaptersList={chaptersList}
              teachersList={teachersList}
            />
          </Dialog>
        </div>
      </div>
    </AdminLayout>
  );
};
export default QuizzesResult;
