import React from "react";
import CustomToolbar from "../../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {Add} from "@material-ui/icons";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {TableFooter, TableRow, TablePagination, TableCell} from "@material-ui/core";

const NestedTable = (props) => {
  const add1Row = () => {
    if (props.arrayName && props.arrayName.length) {
      if (props.rowData.name && props.rowData.link && props.rowData.priority) {
        props.setArrayName([
          ...props.arrayName.filter((e) => e.name && e.link && e.priority),
          {name: props.rowData.name, link: props.rowData.link, priority: props.rowData.priority},
          {name: "", link: "", priority: ""},
        ]);
        props.setRowData({});
      } else if (props.arrayName[props.arrayName.length - 1].serial) {
        props.setArrayName([...props.arrayName, {name: "", link: "", priority: ""}]);
        props.setRowData({});
      } else {
        toast.error(`Fill all fields in ${props.title} before adding a new row`);
      }
    } else {
      props.setArrayName([{name: "", link: "", priority: ""}]);
    }
  };
  const handleBlurTextfield = (e, tableMeta) => {
    const {name, value} = e.target;
    if (tableMeta.rowData[0]) {
      props.setArrayName(
        props.arrayName.map((eSub) => {
          if (eSub.serial == tableMeta.rowData[0]) {
            if (name === "name") eSub.name = value;
            else if (name === "link") eSub.link = value;
            else if (name === "priority") eSub.priority = value;
          }
          return eSub;
        })
      );
    } else {
      props.setRowData({...props.rowData, [name]: value});
    }
  };
  return (
    <div>
      <ToastContainer />
      <MUIDataTable
        data={props.arrayName}
        title={props.title}
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Description"
                    onBlur={(e) => handleBlurTextfield(e, tableMeta)}
                    defaultValue={value}
                  />
                );
              },
            },
          },
          {
            name: "link",
            label: "Link",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <input
                    type="text"
                    className="form-control"
                    name="link"
                    placeholder="Link"
                    onBlur={(e) => handleBlurTextfield(e, tableMeta)}
                    defaultValue={value}
                  />
                );
              },
            },
          },
          {
            name: "priority",
            label: "Priority",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <input
                    type="number"
                    className="form-control"
                    name="priority"
                    onBlur={(e) => handleBlurTextfield(e, tableMeta)}
                    defaultValue={value}
                  />
                );
              },
            },
          },
        ]}
        options={{
          filter: false,
          rowsPerPage: 100,
          rowsPerPageOptions: [20, 50, 100],
          customToolbar: () => {
            return (
              <>
                <CustomToolbar listener={add1Row} />
                {props.import}
              </>
            );
          },
          customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
            return (
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <TablePagination
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={(_, page) => changePage(page)}
                      onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
                      rowsPerPageOptions={[10, 15, 100]}
                    />
                  </TableCell>
                  <TableCell>
                    <a
                      style={{textAlign: "right", display: "block", cursor: "pointer"}}
                      onClick={() => add1Row()}
                      title="add new row"
                    >
                      <Add />
                    </a>
                  </TableCell>
                </TableRow>
              </TableFooter>
            );
          },
          textLabels: {
            body: {
              noMatch: !props.isLoading && "Sorry, there is no matching data to display",
            },
          },
          onRowsDelete: (rowsDeleted, dataRows) => {
            const idsToDelete = rowsDeleted.data.map((d) => props.arrayName[d.dataIndex].serial);
            const rowsToKeep = props.arrayName.filter((e) => !idsToDelete.includes(e.serial));
            props.setArrayName(rowsToKeep);
          },
        }}
      />
    </div>
  );
};

export default NestedTable;
