import React, {useEffect} from "react";


const PasswordUpdated = ({history}) => {

    useEffect(() => {
        history.push({ pathname: "/", state: { type: "Password Updated!",message: "Your password has been changed successfully. Use your new password to log in." } });

      }, []);

    return <div></div>}
  export default PasswordUpdated