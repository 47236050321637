import React, {Fragment} from "react";
import {Link, withRouter} from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import {isAuth, signout} from "../auth/helpers";
import "../assets/css/stylesAdmin.css";

const AdminLayout = ({children, match, history}) => {
  const user = isAuth();
  const isActive = (path) => {
    if (match.path === path) {
      return {color: "#000"};
    } else {
      return {color: "#fff"};
    }
  };

  const nav = () => (
    <ul className="nav nav-tabs bg-primary accounts-links flex-row-reverse">
      {!user ||
        (user?.role !== "admin" && (
          <Fragment>
            <li className="nav-item">
              <Link to="/admin/signin" className="nav-link" style={isActive("/admin/signin")}>
                Sign in
              </Link>
            </li>
          </Fragment>
        ))}

      {user && user?.role === "admin" && user?.profile == 1 ? (
        <li className="nav-item">
          <Link className="nav-link" style={isActive("/admin/users")} to="/admin/users">
            {user ? user?.name : ""}
          </Link>
        </li>
      ) : (
        <li className="nav-item">
          <Link
            className="nav-link"
            style={isActive("/admin/representativeVisits")}
            to="/admin/representativeVisits"
          >
            {user ? user?.name : ""}
          </Link>
        </li>
      )}

      {user && user?.role === "admin" && (
        <li className="nav-item">
          <span
            className="nav-link"
            style={{cursor: "pointer", color: "#fff"}}
            onClick={() => {
              signout(() => {
                history.push("/admin/signin");
              });
            }}
          >
            Sign out
          </span>
        </li>
      )}
    </ul>
  );

  return (
    <div className="d-flex adminInterface" style={{height: "100vh"}}>
      <AdminSidebar match={match} />
      <div className="bodyContainer">
        {nav()}
        {children}
      </div>
    </div>
  );
};

export default withRouter(AdminLayout);
