import React from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";
import {countries} from "../../../../data/countries";
import {useState} from "react";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit} = useForm();
  const [countriesList, setCountriesList] = useState(countries);
  const handleOnSubmit = async (data) => {
    const formData = new FormData();

    if (data.name) formData.append("name", data.name);
    if (data.country) formData.append("country", data.country);
    if (data.remarks) formData.append("remarks", data.remarks);

    if (props.title.substring(0, 4) === "Edit") {
      formData.append("_method", "PUT");
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/educationalCompanies/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/educationalCompanies`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Educational Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  defaultValue={props.formValues.name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <select
                  className="form-select"
                  id="country"
                  {...register("country")}
                  defaultValue={props.formValues.country}
                >
                  <option>Open a country</option>
                  {countriesList.map((e) => (
                    <option value={e.name} key={e.serial}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="text">Remarks</label>
                <textarea
                  className="form-control"
                  id="remarks"
                  rows="3"
                  placeholder="Remarks"
                  {...register("remarks")}
                  defaultValue={props.formValues.remarks}
                ></textarea>
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button className="btn btn-lg btn-primary" type="submit">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
