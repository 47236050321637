import React from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";
import {countries} from "../../../../data/countries";
import {useState} from "react";
import {useEffect} from "react";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit} = useForm({
    defaultValues: {
      delivering: props.formValues.delivering,
      school_serial: props.formValues.school_serial,
    },
  });

  const handleOnSubmit = async (data) => {
    const formData = new FormData();

    for (var key in data) {
      if (typeof data[key] == "boolean") {
        formData.append(key, data[key] ? 1 : 0);
      } else {
        formData.append(key, data[key]);
      }
    }

    if (props.title.substring(0, 4) === "Edit") {
      formData.append("_method", "PUT");
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/representativeVisits/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/representativeVisits`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  console.log(props);
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-5">
              <div className="form-group">
                <label htmlFor="school_serial">School</label>
                <select className="form-select" id="school_serial" {...register("school_serial")}>
                  <option value="" disabled>
                    Select School
                  </option>

                  {props?.schools?.map((e) => (
                    <option value={e.serial} key={e.serial}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-1 align-self-end">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  onClick={() => props.setOpenSchool(true)}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="date"
                  placeholder="Date"
                  {...register("date")}
                  defaultValue={props.formValues.date}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="text">Action Taken</label>
                <textarea
                  className="form-control"
                  id="action_taken"
                  rows="3"
                  placeholder="Action"
                  {...register("action_taken")}
                  defaultValue={props.formValues.action_taken}
                ></textarea>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="meeting">Meeting with</label>
                <input
                  type="text"
                  className="form-control"
                  id="meeting"
                  placeholder="Name"
                  {...register("meeting")}
                  defaultValue={props.formValues.meeting}
                />
              </div>
            </div>

            <div className="col-sm-6 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="delivering" {...register("delivering")} className="mx-3" />
                <label htmlFor="delivering">Delivering Specimen </label>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="text">Remarks</label>
                <textarea
                  className="form-control"
                  id="remarks"
                  rows="3"
                  placeholder="Remarks"
                  {...register("remarks")}
                  defaultValue={props.formValues.remarks}
                ></textarea>
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button className="btn btn-lg btn-primary" type="submit">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
