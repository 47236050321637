import React, {useState, useEffect} from "react";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Dialog, Slide, CircularProgress} from "@material-ui/core";
import {getCookie} from "../../../auth/helpers";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {ToastContainer, toast} from "react-toastify";

import QuestionsComponent from "./Components/QuestionsDialog.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Chapters = () => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [gradesList, setGradesList] = useState([]);
  const [booksList, setBooksList] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [teachersList, setTeachersList] = useState([]);
  const [questionsListClicked, setQuestionsListClicked] = useState([]);
  const [formTitle, setFormTitle] = useState("Add Chapters");
  const [questionsDialog, setQuestionsDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [pagingInfo, setPagingInfo] = useState({page: 0, limit: 20, skip: 0, count: 20}); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/chapters?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.count});
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [open, pagingInfo.page, pagingInfo.limit, searchEntry]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/books`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/questions`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/teachers`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/grades`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
        ])
        .then((response) => {
          setBooksList(response[0]?.data?.data);
          setQuestionsList(response[1]?.data?.data);
          setTeachersList(response[2]?.data?.data);
          setGradesList(response[3]?.data?.data);
        });
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Chapter's Title",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              onClick={() => {
                handleAdd("Edit Chapters - " + tableMeta.rowData[2], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {name: "number"},
    {
      name: "grade_id",
      label: "Grade",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const grade = gradesList.filter((e) => e.serial === value);
          return grade.length ? grade[0].name : value;
        },
      },
    },
    {
      name: "book_id",
      label: "Book",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const book = booksList.filter((e) => e.serial === value);
          return book.length ? book[0].name : value;
        },
      },
    },
    {
      name: "Question",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const chapterId = tableMeta.rowData[0];
          const chapterQuestions = questionsList.filter((e) => e.chapter_id === chapterId);
          return (
            <a href={`/admin/questions/${tableMeta.rowData[0]}`} title="Questions">
              <i className="fas fa-edit text-primary"></i> {chapterQuestions.length}
            </a>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleAdd("Add Chapter");
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/chapters/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setItems(response?.data?.data);
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
  };
  const handleCloseQuestions = () => {
    setQuestionsDialog(false);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, chaptersId) => {
    if (chaptersId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/chapters/${chaptersId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          setFormValues(response.data);
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        icon: "",
        name: "",
        text: "",
      });
      setFormTitle(title);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5 pt-5">
        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AddFormDialog
              title={formTitle}
              handleClose={handleClose}
              formValues={formValues}
              gradesList={gradesList}
              booksList={booksList}
            />
          </Dialog>
          <Dialog
            fullScreen
            open={questionsDialog}
            onClose={handleCloseQuestions}
            TransitionComponent={Transition}
          >
            <QuestionsComponent
              handleClose={handleCloseQuestions}
              questionsList={questionsListClicked}
              teachersList={teachersList}
            />
          </Dialog>
        </div>
      </div>
    </AdminLayout>
  );
};
export default Chapters;
