import React from "react";
import {TextField} from "@material-ui/core";
import {getCookie} from "../../../auth/helpers";
import {CloudUpload, CloudDownload} from "@material-ui/icons";
import CustomToolbar from "../../../components/CustomToolbar";
import {Button} from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import {ToastContainer, toast} from "react-toastify";
import XLSX from "xlsx";
import axios from "axios";
import "react-toastify/dist/ReactToastify.min.css";

const NestedTable = (props) => {
  const token = getCookie("token");
  const add1Row = () => {
    props.setArrayName([...props.arrayName, {serial: 0, name: "", email: "", status: "new"}]);
  };

  const handleChange = async (e, tableMeta) => {
    const {name, value} = e.target;
    const serial = tableMeta.rowData[0];
    const status = tableMeta.rowData[1];
    const rowIndex = tableMeta.rowIndex;

    if (status === "old") {
      let data = {...props.arrayName.filter((e) => e.serial === serial)[0], [name]: value};
      delete data.serial;
      delete data.status;
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass/students/${serial}`,
        headers: {Authorization: `Bearer ${token}`},
        data: data,
      })
        .then((response) => {
          toast.success("Succesfully Updated");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      props.setArrayName(
        props.arrayName.map((e, i) => {
          if (i === rowIndex && name === "name") e.name = value;
          if (i === rowIndex && name === "email") e.email = value;
          return e;
        })
      );
    }
  };
  const handleImportExcel = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const {result} = event.target;
        const workbook = XLSX.read(result, {type: "binary"});
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }
        props.setArrayName(
          data.map((e) => ({
            email: e.Email,
            name: e.Name,
            serial: 0,
            status: "new",
          }))
        );
      } catch (e) {
        toast.error("The file type is incorrect!");
      }
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <div className="d-flex">
        <div style={{overflow: "hidden", position: "relative", cursor: "pointer", marginRight: 15}}>
          <a href="assets/studentsTemplate.xlsx" className="btn btn-primary" download>
            <CloudDownload fontSize="large" /> Download Template
          </a>
        </div>
        <div style={{overflow: "hidden", position: "relative", cursor: "pointer", marginRight: 15}}>
          <input
            type="file"
            onChange={handleImportExcel}
            style={{position: "absolute", opacity: 0, top: 0, right: 0, bottom: 0, left: 0}}
          />
          <button type="button" className="btn btn-secondary">
            <CloudUpload fontSize="large" /> Import Database
          </button>
        </div>
        <p>
          <small>NB: Importing new excel will override the existing students</small>
        </p>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
      />
      <MUIDataTable
        data={props.arrayName}
        title={props.title}
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "status",
            options: {
              display: false,
            },
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div className="form-group">
                    <label htmlFor={`${props.title}Input`}>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${props.title}Input`}
                      name="name"
                      defaultValue={value}
                      onBlur={(e) => handleChange(e, tableMeta)}
                    />
                  </div>
                );
              },
              customHeadLabelRender: (columnMeta) => {
                return <span style={{fontSize: "1.5em", fontWeight: "bold"}}>{columnMeta.label}</span>;
              },
            },
          },
          {
            name: "email",
            label: "Email",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div className="form-group">
                    <label htmlFor={`${props.title}Input`}>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${props.title}Input`}
                      name="email"
                      defaultValue={value}
                      onBlur={(e) => handleChange(e, tableMeta)}
                    />
                  </div>
                );
              },
              customHeadLabelRender: (columnMeta) => {
                return <span style={{fontSize: "1.5em", fontWeight: "bold"}}>{columnMeta.label}</span>;
              },
            },
          },
        ]}
        options={{
          filter: false,
          rowsPerPage: 100,
          rowsPerPageOptions: [20, 50, 100],
          customToolbar: () => {
            return <CustomToolbar listener={add1Row} />;
          },
          textLabels: {
            body: {
              noMatch: !props.isLoading && "Sorry, there is no matching data to display",
            },
          },
          onRowsDelete: (rowsDeleted, dataRows) => {
            const idsToDelete = rowsDeleted.data.map((d) => props.arrayName[d.dataIndex].serial);
            const rowsToKeep = props.arrayName.filter((e) => !idsToDelete.includes(e.serial));
            props.setArrayName(rowsToKeep);
          },
        }}
      />
    </div>
  );
};

export default NestedTable;
