import React, {useState, useEffect} from "react";
import {TextField, AppBar, Toolbar} from "@material-ui/core";
import {ToastContainer, toast} from "react-toastify";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import NestedTable from "./NestedTable.js";
import axios from "axios";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const [answers, setAnswers] = useState([]);
  const {register, handleSubmit, reset} = useForm();
  const [addMore, setAddMore] = useState(0);
  const [API1submitted, setAPI1submitted] = useState(0);
  const [questionId, setQuestionId] = useState(props.formValues.serial);

  // useEffect(()=>{
  //   console.log("answers",answers)
  // },[answers])

  const handleOnSubmit = async (data, e) => {
    if (answers.filter((e) => e.correct === 1).length) {
      const formData = new FormData();
      if (data.image[0]) formData.append("image", data.image[0]);
      if (data.question) formData.append("question", data.question);
      if (props.chaptersId) formData.append("chapter_id", props.chaptersId);
      if (data.show_in_student === true) formData.append("show_in_student", 1);
      else formData.append("show_in_student", 0);
      if (data.has_notes === true) formData.append("has_notes", 1);
      else formData.append("has_notes", 0);

      if (props.formValues.serial) {
        formData.append("_method", "PUT");
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/questions/${props.formValues.serial}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: formData,
        })
          .then((response) => {
            props.setFormValues({});
            setAPI1submitted(1);
            if (e.nativeEvent.submitter.name === "submitAndClose") props.handleClose();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/questions`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: formData,
        })
          .then((response) => {
            setQuestionId(response.data.serial);
            props.setFormValues({});
            setAPI1submitted(1);
            if (e.nativeEvent.submitter.name === "submitAndClose") props.handleClose();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      toast.error("You should check 1 correct answer");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (API1submitted === 1) {
        const dataToAdd = answers
          .filter((e) => e.status === "new" && e.answer != "")
          .map((e) => {
            delete e.status;
            e.question_id = questionId;
            return e;
          });
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/answers`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {data: dataToAdd},
        })
          .then((response) => {
            props.setFormValues({});
            reset({image: "", question: "", showInStudent: 0});
            setAnswers([]);
            setAPI1submitted(0);
            toast.success(response.data.message);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    fetchData();
  }, [API1submitted]);
  return (
    <>
      <ToastContainer />
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            {props.formValues.image ? (
              <div className="col-sm-1">
                <img
                  src={process.env.REACT_APP_FILES_PATH + "/Questions/" + props.formValues.image}
                  alt=""
                  className="img-thumbnail rounded"
                />
              </div>
            ) : null}
            <div className={props.formValues.image ? "col-sm-5" : "col-sm-6"}>
              <div className="form-group">
                <label htmlFor="image">Image</label>
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  placeholder="Image"
                  {...register("image")}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="question">Question</label>
                <input
                  type="text"
                  className="form-control"
                  id="question"
                  placeholder="Question"
                  {...register("question")}
                  defaultValue={props.formValues.question}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="show_in_student"
                  {...register("show_in_student")}
                  defaultChecked={props.formValues.show_in_student === 1 ? true : false}
                />
                <label className="form-check-label" htmlFor="show_in_student">
                  Show In Students
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="has_notes"
                  {...register("has_notes")}
                  defaultChecked={props.formValues.has_notes === 1 ? true : false}
                />
                <label className="form-check-label" htmlFor="has_notes">
                  Add Remarks
                </label>
              </div>
            </div>
            <div className="col-12 my-5">
              <NestedTable
                arrayName={answers}
                setArrayName={setAnswers}
                addMore={addMore}
                setAddMore={setAddMore}
                questionId={questionId}
              />
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-lg btn-primary" type="submit" name="submitAndAdd">
                <i className="fas fa-save"></i> Save and Add more
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-primary" type="submit" name="submitAndClose">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
