export const countries = [
  { serial: 1, name: "Lebanon" },
  { serial: 2, name: "Egypt" },
  { serial: 3, name: "Jordan" },
  { serial: 4, name: "Kingdom of Saudi Arabia" },
  { serial: 5, name: "Morocco" },
  { serial: 6, name: "United Arab Emirates" },
  { serial: 7, name: "Qatar" },
  { serial: 8, name: "Bahrain" },
  { serial: 9, name: "Others" },
];
