import React, {useState, useEffect} from "react";
import Layout from "../../core/Layout";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {ToastContainer, toast} from "react-toastify";
import {CircularProgress} from "@material-ui/core";
import axios from "axios";
import {countries} from "../../data/countries";
const VerifyFrontend = ({history, match, location}) => {
  const [gradesList, setGradesList] = useState([]);
  const [grades, setGrades] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allGrades, setAllGrades] = useState(0);
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);
  const [countriesList, setCountriesList] = useState(countries);

  const gender = [{name: "Male"}, {name: "Female"}];

  const {register, handleSubmit, watch, setValue} = useForm({
    defaultValues: {},
  });
  useEffect(() => {
    const fetchData = async () => {
      if (history.location.state && history.location.state.error) toast.error(history.location.state.error);

      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/get_grades`,
        {
          responseType: "json",
        }
      ).then((response) => {
        setGradesList(response.data.grades);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  const verifyRecaptcha = async (e) => {
    if (e) {
      setRecaptchaSuccess(true);
    }
    let result = await axios(
      {
        method: "post",
        url: "https://www.google.com/recaptcha/api/siteverify",
        params: {
          secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
          response: e,
        },
      },
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      }
    );
    let data = result.data || {};
    if (!data.success) {
      throw {
        success: false,
        error: "response not valid",
      };
    }
  };

  const close = () => {
    history.push("/");
  };

  const handleOnSubmit = async (data) => {
    data = {
      ...data,
      serial: match.params.serial,
      name: data.firstName + " " + data.lastName,
    };

    if (grades.length) {
      let gradesArray = grades.map((e) => e.serial);
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/user/update`,
        data: {...data, grades: gradesArray},
      })
        .then(async (response) => {
          await axios({
            method: "post",
            url: `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/user/request-activation`,
            data: {email: response.data.email},
          })
            .then((response) => {
              toast.success(
                <div className="ml-auto text-center">
                  <div className="ml-auto text-center">
                    <p>
                      Your request has been sent to the admin for approval, allow us 24 hours to process your
                      approval
                    </p>
                  </div>
                  <button className="btn" onClick={close}>
                    <strong>OK</strong>
                  </button>
                </div>,
                {
                  autoClose: 60000,
                }
              );

              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong, please try again");
            });
        })
        .catch((error) => {
          toast.error(error.response.data.msg);
        });
    } else {
      toast.error("Grade is required");
    }
  };

  useEffect(async () => {
    console.log(location.state);
    if (!location.state) {
      close();
    }
    await axios({
      method: "post",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/user/info`,
      data: {serial: match.params.serial},
    })
      .then((response) => {
        setValue("serial", response.data.serial);

        setValue("firstName", response.data.firstName);
        setValue("lastName", response.data.lastName);
        setValue("gender", response.data.gender);

        setValue("school", response.data.school);
        setValue("country", response.data.country);
        setValue("phone", response.data.phone);
        setValue("assistant_director", response.data.assistant_director);

        setGrades(response.data.grades);
      })
      .catch((error) => {});
  }, [match.params.serial]);

  const handleError = (errors) => {
    // if (errors.name) toast.error(errors.name.message);
    if (errors.firstName) toast.error(errors.firstName.message);
    else if (errors.lastName) toast.error(errors.lastName.message);
    if (errors.gender) toast.error(errors.gender.message);
    else if (errors.school) toast.error(errors.school.message);
    else if (errors.country) toast.error(errors.country.message);
    else if (errors.phone) toast.error(errors.phone.message);
  };
  const validationOptions = {
    // name: { required: "Name is required" },
    firstName: {required: "First Name is required"},
    lastName: {required: "Last Name is required"},
    gender: {required: "Gender is required"},

    school: {required: "School is required"},
    country: {required: "Country is required"},
  };
  //Search component ---------------END--------------
  return (
    <Layout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
      />
      {!isLoading ? (
        <div className="container pt-5 mt-5">
          <div id="register1" className="py-5 my-5">
            <div className="d-flex flex-column pt-5 mt-5">
              <form onSubmit={handleSubmit(handleOnSubmit, handleError)}>
                <div className="text-center">
                  <h3 className="text-secondary mb-0">Verify you information</h3>
                  <h2 className="text-primary mb-5 pb-5">
                    Update your information before requesting a verification
                  </h2>
                </div>

                <div className="row justify-content-md-center">
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder=""
                        {...register("firstName", validationOptions.firstName)}
                      />
                      <label htmlFor="firstName">First Name*</label>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder=""
                        {...register("lastName", validationOptions.lastName)}
                      />
                      <label htmlFor="lastName">Last Name*</label>
                    </div>
                  </div>
                  {watch("type") === "teacher" ? (
                    <div className="col-sm-4 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="registerPhone"
                          placeholder=""
                          {...register("phone", {
                            required: "Phone is required",
                          })}
                        />
                        <label htmlFor="registerPhone">Phone (Int. format)*</label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-sm-4 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="registerPhone"
                          placeholder=""
                          {...register("phone")}
                        />
                        <label htmlFor="registerPhone"> Phone (Int. format)*</label>
                      </div>
                    </div>
                  )}

                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="registerCountry"
                        {...register("country", validationOptions.country)}
                      >
                        <option selected>Open a country</option>
                        {countriesList.map((e) => (
                          <option value={e.name} key={e.serial}>
                            {e.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="registerCountry">Country *</label>
                    </div>
                  </div>

                  {watch("type") === "teacher" && (
                    <div className="col-sm-4 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="registerGender"
                          {...register("gender", validationOptions.gender)}
                        >
                          <option selected>Select a gender</option>
                          {gender.map((e) => (
                            <option value={e.name} key={e.serial}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="registerGender">Gender *</label>
                      </div>
                    </div>
                  )}

                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="registerSchoolName"
                        placeholder=""
                        {...register("school", validationOptions.school)}
                      />
                      <label htmlFor="registerSchoolName">School name (Full Description) *</label>
                    </div>
                  </div>

                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="assistant_director"
                        placeholder=""
                        {...register("assistant_director")}
                      />
                      <label htmlFor="assistant_director">Name of the Academic Director</label>
                    </div>
                  </div>

                  <div className="col-sm-12 mb-12">
                    <div className="form-group">
                      <Autocomplete
                        multiple
                        id="grades"
                        fullWidth
                        options={gradesList}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                          const newValuesId = newValue.map((e) => e.serial);
                          setGradesList(gradesList.filter((e) => !newValuesId.includes(e.serial)));

                          setGrades(
                            newValue.map((e) => ({
                              serial: e.serial,
                              name: e.name,
                            }))
                          );
                        }}
                        value={grades}
                        filterSelectedOptions
                        renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" label="Grades" placeholder="Grades" />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 mb-4" id="recaptcha">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={verifyRecaptcha}
                    />
                  </div>

                  <div className="col-sm-4">
                    {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
                      <button className="btn btn-secondary" type="submit">
                        Update & Request
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      className="btn btn-secondary"
                      type="submit"
                      style={{width: "100%", lineHeight: 2.5}}
                      disabled={recaptchaSuccess ? false : true}
                    >
                      Update & Request
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
    </Layout>
  );
};
export default VerifyFrontend;
