import React, {useState, useEffect, useRef} from "react";
import Layout from "../../core/Layout";
import {CircularProgress} from "@material-ui/core";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useForm} from "react-hook-form";
import {getCookie, isAuth} from "../../auth/helpers";
import moment from "moment";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";

const Quiz = (props) => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [classesList, setClassesList] = useState([]);
  const [selectedClass, setSelectedClass] = useState();

  const [chaptersListDb, setChaptersListDb] = useState([]);
  const [chaptersList, setChaptersList] = useState([]);
  const [chapter, setChapter] = useState({});
  const {register, handleSubmit, watch} = useForm();
  const [quizData, setQuizData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/chapters`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/myclass`
          ),
        ])
        .then((response) => {
          setChaptersListDb(response[0]?.data?.data);
          setChaptersList(response[0]?.data?.data);
          setClassesList(response[1]?.data?.data.filter((e) => e.teacher_id === isAuth()._id));
          if (!props.match.params.id) setIsLoading(false);
        });
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (props.match.params.id && chaptersListDb.length) {
        await axios(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/quizs/${props.match.params.id}`,
          {
            responseType: "json",
          }
        ).then((response) => {
          const chapterData = chaptersListDb.find((e) => e.serial === response.data.chapter_id);
          const date = moment(response.data.fromDate).format("YYYY-MM-DD");

          const fromTime = moment(response.data.fromDate).format("HH:mm");
          const toTime = moment(response.data.toDate).format("HH:mm");

          setQuizData({...response.data, date, fromTime, toTime});
          if (chapterData) {
            setChapter(chapterData);
            setSelectedClass(classesList.find((e) => e.grade_id === chapterData.grade_id));
          }
          setIsLoading(false);
        });
      }
    };
    fetchData();
  }, [classesList]);

  const handleOnSubmit = async (data) => {
    const fromDate = moment(data.date + " " + data.fromTime).format("YYYY-MM-DD HH:mm:ss");
    const toDate = moment(data.date + " " + data.toTime).format("YYYY-MM-DD HH:mm:ss");

    if (selectedClass && chapter && isAuth() && fromDate && toDate) {
      let dataToSubmit = {
        ...data,
        class_id: selectedClass.serial,
        chapter_id: chapter.serial,
        teacher_id: isAuth()._id,
        fromDate,
        toDate,
      };

      if (props.match.params.method === "edit") {
        await axios({
          method: "put",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/quizs/${props.match.params.id}`,
          data: dataToSubmit,
        })
          .then((response) => {
            props.history.push(`/quizList`);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/quizs`,
          data: dataToSubmit,
        })
          .then((response) => {
            props.history.push(`/questionsList/${response.data.serial}`);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      toast.error("Please Fill All The Fields");
    }
  };

  const handleChangeClass = async (e, newValue) => {
    setChapter([]);
    setSelectedClass(newValue);
    setChaptersList(chaptersListDb.filter((e) => e.grade_id === newValue.grade_id));
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="container pt-5 mt-5">
        <div id="register2" className="py-5 my-5">
          <div className="d-flex flex-column pt-5 mt-5">
            <div className="text-center">
              <h3 className="text-secondary mb-0">Teacher's Quiz</h3>
              {props.match.params.method === "edit" ? (
                <h2 className="text-primary mb-5">Edit Quiz</h2>
              ) : (
                <h2 className="text-primary mb-5">Create a Quiz</h2>
              )}
            </div>
            {!isLoading ? (
              <form onSubmit={handleSubmit(handleOnSubmit)}>
                <div className="row">
                  <div className="col-sm-6 mb-4">
                    <div className="form-group">
                      <Autocomplete
                        fullWidth
                        options={classesList}
                        value={selectedClass}
                        getOptionLabel={(option) => option.name || null}
                        onChange={handleChangeClass}
                        renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                        renderInput={(params) => <TextField {...params} label="Class" variant="standard" />}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 mb-4">
                    <div className="form-group">
                      <Autocomplete
                        fullWidth
                        options={chaptersList}
                        value={chapter}
                        getOptionLabel={(option) => option.name || null}
                        onChange={(e, newValue) => setChapter(newValue)}
                        renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                        renderInput={(params) => <TextField {...params} label="Chapter" variant="standard" />}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder=""
                            defaultValue={quizData.name}
                            {...register("name")}
                          />
                          <label htmlFor="name">Quiz name</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="remark"
                            placeholder=""
                            defaultValue={quizData.note}
                            {...register("note")}
                          />
                          <label htmlFor="remark">Remark</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="form-floating">
                          <input
                            type="date"
                            className="form-control"
                            id="fromDate"
                            placeholder=""
                            defaultValue={quizData.date}
                            {...register("date")}
                          />
                          <label htmlFor="remark">Date</label>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-floating">
                          <input
                            type="time"
                            className="form-control"
                            id="fromDate"
                            placeholder=""
                            defaultValue={quizData.fromTime}
                            {...register("fromTime")}
                          />
                          <label htmlFor="remark">From (Time)</label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-floating">
                          <input
                            type="time"
                            className="form-control"
                            id="toDate"
                            placeholder=""
                            defaultValue={quizData.toTime}
                            {...register("toTime")}
                          />
                          <label htmlFor="remark">To (Time)</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="d-grid col-3 mx-auto">
                      <button className="btn btn-secondary" type="submit">
                        {props.match.params.method === "edit" ? "SUBMIT" : "SUBMIT AND SELECT QUESTIONS"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="text-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Quiz;
