import React, { useState, useEffect } from "react";
import Layout from "../../core/Layout";

const Register3Frontend = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="container pt-5 mt-5">
        <div id="register3" className="py-5 mt-5">
          <div className="d-flex flex-column pt-5 mt-5">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/thank-you.jpg`}
              alt="Thank you"
              className="pb-5"
            />
            {props?.history?.location?.state?.type === "student" ? (
              <div className="text-center">
                <h3 className="text-secondary mb-5">
                  The first stage of your registration has been successful
                </h3>
                <p className="w-50 mx-auto text-left font-weight-bold">
                  It is time to check you Email to complete your registration
                  and to activate your account!
                </p>
                <p className="w-50 mx-auto text-left ">
                  <span className="font-weight-bold">
                    <u>In case you did not receive an email: </u>
                  </span>
                  <br />
                  <ol className="text-left">
                    <li>Check your Junk Mail or Spam.</li>
                    <li>
                      Contact us by email{" "}
                      <a href="mailto:support@zero1.education">
                        support@zero1.education
                      </a>{" "}
                      in order to help you activating your email.
                    </li>
                  </ol>
                </p>
              </div>
            ) : (
              <div className="text-center">
                <h3 className="text-secondary mb-5">
                  Thank you for registering on zero-one
                </h3>
                <p className="w-50 mx-auto">
                  Our team will contact you shortly to complete your account
                  setup, we appreciate your patience, in the meantime please
                  verify your email
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between mb-5">
          <a href="/" className="readMore">
            <i className="fas fa-arrow-left"></i> &nbsp; Go to Homepage
          </a>
        </div>
      </div>
    </Layout>
  );
};
export default Register3Frontend;
