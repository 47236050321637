import React, {useState, useEffect} from "react";
import CustomToolbar from "../../../../components/CustomToolbar";
import {getCookie} from "../../../../auth/helpers";
import MUIDataTable from "mui-datatables";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";

const NestedTable = (props) => {
  const token = getCookie("token");
  const [answersList, setAnswersList] = useState([]);

  const add1Row = () => {
    props.setArrayName([...props.arrayName, {question: "", correct: 0, status: "new"}]);
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/answers`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/question/${props.quizId}`,
            {
              headers: {Authorization: `Bearer ${token}`},
            }
          ),
        ])
        .then((response) => {
          setAnswersList(response[0]?.data?.data);
          if (response[1].data.length > 0) {
            props.setArrayName(response[1].data.map((e) => ({...e, status: "old"})));
          }
        });
    };
    fetchData();
  }, []);

  const handleChecked = async (e, tableMeta, questionQuizId) => {
    const serial = tableMeta.rowData[0];
    const status = tableMeta.rowData[1];
    const rowIndex = tableMeta.rowIndex;
    const {name, type} = e.target;

    const selectedRow = props.arrayName ? props.arrayName.filter((eSub) => eSub.question_id === serial) : [];
    if (type === "checkbox") {
      if (selectedRow.length === 0 && e.target.checked === true) {
        props.setArrayName([...props.arrayName, {question_id: serial, quiz_id: props.quizId, status: "new"}]);
      } else {
        const selectedQuestionId = questionQuizId.lengyh ? questionQuizId[0].serial : null;
        axios
          .delete(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/questions/${selectedQuestionId}`,
            {
              responseType: "json",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            toast.success("Successfully Deleted");
          });
        props.setArrayName(props.arrayName.filter((eUnchecked) => eUnchecked.question_id !== serial));
      }
    } else {
      props.setArrayName(
        props.arrayName.map((eUpdated) => {
          if (selectedRow.length && serial === eUpdated.question_id) {
            eUpdated.mark = e.target.value;
          }
          return eUpdated;
        })
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <MUIDataTable
        data={props.questionsList}
        title="Questions"
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "status",
            options: {
              display: false,
            },
          },
          {
            name: "question",
            label: "Question",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div style={{fontSize: 14}}>{value}</div>;
              },
            },
          },
          {
            name: "Answers",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const answers = answersList.filter((e) => e.question_id === tableMeta.rowData[0]);
                return answers.map((e, i) => (
                  <div className={`font-weight-bold ${e.correct ? "text-success" : null}`}>{e.answer}</div>
                ));
              },
            },
          },
          {
            name: "Check",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const checkedRow = props.arrayName.length
                  ? props.arrayName.filter((e) => e.question_id === tableMeta.rowData[0])
                  : [];
                return (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`${props.title}CheckQuestion`}
                      name="checkQuestion"
                      defaultChecked={checkedRow.length ? true : false}
                      onClick={(e) => handleChecked(e, tableMeta, checkedRow)}
                    />
                  </div>
                );
              },
            },
          },
          {
            name: "Mark",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const checkedRow = props.arrayName.length
                  ? props.arrayName.filter((e) => e.question_id === tableMeta.rowData[0])
                  : [];
                return (
                  <input
                    type="number"
                    className="form-control"
                    id="mark"
                    placeholder=""
                    defaultChecked={checkedRow ? checkedRow.mark : ""}
                    onBlur={(e) => handleChecked(e, tableMeta, checkedRow)}
                  />
                );
              },
            },
          },
        ]}
        options={{
          filter: false,
          customToolbar: () => {},
          textLabels: {
            body: {
              noMatch: !props.isLoading && "Sorry, there is no matching data to display",
            },
          },
          selectableRows: false,
        }}
      />
    </>
  );
};

export default NestedTable;
