import React from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit} = useForm();

  const handleOnSubmit = async (data) => {
    if (props.title.substring(0, 4) === "Edit") {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/footer/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/footer`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="text">about</label>
                <textarea
                  className="form-control"
                  id="about"
                  rows="3"
                  placeholder="about"
                  {...register("about")}
                  defaultValue={props.formValues.about}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="telephone">telephone</label>
                <input
                  type="text"
                  className="form-control"
                  id="telephone"
                  placeholder="telephone"
                  {...register("telephone")}
                  defaultValue={props.formValues.telephone}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="email"
                  {...register("email")}
                  defaultValue={props.formValues.email}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="address">address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="address"
                  {...register("address")}
                  defaultValue={props.formValues.address}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="facebook">facebook</label>
                <input
                  type="text"
                  className="form-control"
                  id="facebook"
                  placeholder="facebook"
                  {...register("facebook")}
                  defaultValue={props.formValues.facebook}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="instagram">instagram</label>
                <input
                  type="text"
                  className="form-control"
                  id="instagram"
                  placeholder="instagram"
                  {...register("instagram")}
                  defaultValue={props.formValues.instagram}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="twitter">twitter</label>
                <input
                  type="text"
                  className="form-control"
                  id="twitter"
                  placeholder="twitter"
                  {...register("twitter")}
                  defaultValue={props.formValues.twitter}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="linkedin">linkedin</label>
                <input
                  type="text"
                  className="form-control"
                  id="linkedin"
                  placeholder="linkedin"
                  {...register("linkedin")}
                  defaultValue={props.formValues.linkedin}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="youtube">youtube</label>
                <input
                  type="text"
                  className="form-control"
                  id="youtube"
                  placeholder="youtube"
                  {...register("youtube")}
                  defaultValue={props.formValues.youtube}
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-lg btn-primary" type="submit">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
