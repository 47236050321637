import React, {useState, useEffect, useRef} from "react";
import {getCookie} from "../../../../auth/helpers";
import {AppBar, CircularProgress, Toolbar} from "@material-ui/core";
import {useForm} from "react-hook-form";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import NestedTableBookChapters from "./NestedTableBookChapters.js";

const BookChaptersFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit} = useForm();

  const [formValues, setFormValues] = useState();
  const [booksChapters, setBooksChapters] = useState([]);
  const [rowBooksChapters, setRowBooksChapters] = useState({});

  const [currentId, setCurrentId] = useState("");
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  useEffect(() => {
    setBooksChapters(props.formBookChapters);
  }, []);

  const handleOnSubmit = async (data) => {
    const formData = new FormData();
    let counter = 0;
    let bookChaptersTosubmit = booksChapters
      ? booksChapters
          .map((e) => {
            if (!e.serial) e.serial = 0;
            if (e.name && e.priority && e.file_name) {
              formData.append(`bookChapters[${counter}][serial]`, e.serial);
              formData.append(`bookChapters[${counter}][name]`, e.name);
              formData.append(`bookChapters[${counter}][priority]`, e.priority);
              formData.append(`bookChapters[${counter}][file_name]`, e.file_name);
              counter++;
              return e;
            }
          })
          .filter((e) => e)
      : [];
    if (rowBooksChapters.name && rowBooksChapters.priority && rowBooksChapters.file_name) {
      rowBooksChapters.serial = 0;
      // bookChaptersTosubmit.push(rowBooksChapters)
      formData.append(`bookChapters[${counter}][serial]`, rowBooksChapters.serial);
      formData.append(`bookChapters[${counter}][name]`, rowBooksChapters.name);
      formData.append(`bookChapters[${counter}][priority]`, rowBooksChapters.priority);
      formData.append(`bookChapters[${counter}][file_name]`, rowBooksChapters.file_name);
      counter++;
    }

    setFormValues(data);
    setShowSaveLoader(true);

    // let softwareTosubmit = software ? software.map(e => {
    //   if (!e.serial) e.serial = 0
    //   if (e.name && e.link && e.priority) return e
    // }).filter(e => e) : []
    // if (rowSoftware.name && rowSoftware.link) {
    //   rowSoftware.serial = 0
    //   softwareTosubmit.push(rowSoftware)
    // }

    // const formData = new FormData();
    // formData.append('cover', formValues.cover[0]);

    await axios({
      method: "post",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/books/add-book-chapters/${props.bookId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((response) => {
        setShowSaveLoader(false);
        props.handleClose();
      })
      .catch((error) => {
        console.log(error);
        setShowSaveLoader(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-12 mb-4">
              <NestedTableBookChapters
                document="BookChapters"
                arrayName={booksChapters}
                setArrayName={setBooksChapters}
                rowData={rowBooksChapters}
                setRowData={setRowBooksChapters}
                title="Book Chapters"
              />
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              {!showSaveLoader ? (
                <button className="btn btn-lg btn-primary" type="submit">
                  <i className="fas fa-save"></i> Save
                </button>
              ) : (
                <CircularProgress size={30} className="pageLoader" />
              )}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BookChaptersFormDialog;
