import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {CircularProgress} from "@material-ui/core";
import {getCookie} from "../../../auth/helpers";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";

const StudentsQuizListModal = (props) => {
  const token = getCookie("token");
  const [quizStudentsList, setQuizStudentsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (props.show === true) {
        await axios
          .all([
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/quiz/students/byquiz/${props.selectedQuiz.serial}`
            ),
          ])
          .then((response) => {
            if (response[0].data.Success == false) {
              setQuizStudentsList([]);
            } else {
              setQuizStudentsList(response[0].data);
            }

            setIsLoading(false);
          });
      }
    };
    fetchData();
  }, [props.show]);

  const handleShare = async (quizId, email) => {
    await axios
      .all([
        axios.get(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/email/quiz/${quizId}/${email}`
        ),
      ])
      .then((response) => {
        toast.success("Email sent to student");
      });
  };
  const handleView = (e) => {
    console.log("View Quiz if done");
  };

  const [isSharingAll, setIsSharingAll] = useState(false);

  const handleShareAllStudent = async () => {
    setIsSharingAll(true);
    const quizId = quizStudentsList[0]["quizId"];
    await axios
      .post(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/email/quiz/${quizId}`
      )
      .then((response) => {
        toast.success("Emails Sent To All Students");
        setIsSharingAll(false);
      })
      .catch((response) => {
        setIsSharingAll(false);
        toast.error("Error While Sending Emails");
      });
  };

  const htmlToCSV = (filename) => {
    var data = [];
    console.log();
    var rows = document.getElementById("student_marks_table").rows;

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  };

  const downloadCSVFile = (csv, filename) => {
    var csv_file, download_link;

    csv_file = new Blob([csv], {type: "text/csv"});

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  };

  return (
    <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">View Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          className={"mb-3"}
          style={{float: "right"}}
          onClick={() => {
            htmlToCSV("results.csv");
          }}
        >
          Export
        </Button>
        {!isLoading ? (
          <table className="table table-striped" id="student_marks_table">
            <thead>
              <tr>
                <th scope="col" width="50">
                  #
                </th>
                <th scope="col">Student</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Mark</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {quizStudentsList.map((e, i) => {
                // let currentQuizStudent=quizStudentsList.find(eSub=>eSub.studentId==e.serial);
                const quizIdForLink =
                  String(e.serial) + (Number(e.quizId) + Number(e.studentId) + Number(e.classId));
                console.log(quizIdForLink);

                return (
                  <tr key={e.serial}>
                    <td scope="row">{i + 1} </td>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>
                      {e.mark != null ? (
                        <strong className="text-success">Completed</strong>
                      ) : (
                        <strong className="text-danger">Not done</strong>
                      )}
                    </td>
                    <td>{e.mark != null ? e.mark : null}</td>
                    <td>
                      {e.mark != null ? (
                        <a
                          href={`${process.env.PUBLIC_URL}/student/quiz/done/${quizIdForLink}`}
                          target="_blank"
                          title="View Completed Quiz"
                        >
                          <i className="fas fa-eye text-primary"></i>
                        </a>
                      ) : null}

                      {e.mark != null ? (
                        <button
                          onClick={() => handleShare(quizIdForLink, e.email)}
                          title="Share Completed Quiz"
                          className="btnWithIcons"
                        >
                          <i className="fas fa-share text-primary"></i>
                        </button>
                      ) : null}
                      {/* {currentQuizStudent&&currentQuizStudent.serial?<a href={`${process.env.PUBLIC_URL}/student/quiz/${quizIdForLink}`} target="_blank">Check Quiz</a>:null} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setIsSharingAll(false);
            props.onHide();
          }}
        >
          Close
        </Button>
        {isSharingAll ? (
          <CircularProgress />
        ) : (
          <button className="btn btn-secondary" onClick={handleShareAllStudent}>
            Share All
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default StudentsQuizListModal;
