import React, {useState, useEffect, useRef} from "react";
import {getCookie} from "../../../../auth/helpers";
import {AppBar, CircularProgress, IconButton, TextField, Toolbar, Tooltip} from "@material-ui/core";
import {useForm} from "react-hook-form";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import NestedTable from "./NestedTable.js";
import NestedTableLinks from "./NestedTableLinks.js";
import {countries} from "../../../../data/countries";
import {Autocomplete} from "@material-ui/lab";
import {CloudUpload} from "@material-ui/icons";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const {register, handleSubmit} = useForm();
  const [digitalResources, setDigitalResources] = useState([]);
  const [rowDigitalResources, setRowDigitalResources] = useState({});

  const [tutorials, setTutorials] = useState([]);
  const [rowTutorials, setRowTutorials] = useState({});

  const [software, setSoftware] = useState([]);
  const [rowSoftware, setRowSoftware] = useState({});

  const [specialInstructions, setSpecialInstructions] = useState([]);
  const [rowSpecialInstructions, setRowSpecialInstructions] = useState({});

  const [links, setLinks] = useState([]);
  const [rowLinks, setRowLinks] = useState({});

  const [currentId, setCurrentId] = useState("");
  //const [updateSubDoc, setUpdateSubDoc] = useState({})
  const [formValues, setFormValues] = useState();
  const [showSaveLoader, setShowSaveLoader] = useState(false);

  const [countriesList, setCountriesList] = useState(countries);
  const [selectedCountries, setSelectedCountries] = useState([]);

  useEffect(() => {
    setSelectedCountries(
      countries?.filter((country) => props?.formValues?.countries?.includes(country.serial))
    );
    setDigitalResources(props.formValues.digitalResources);

    setTutorials(props.formValues.tutorials);
    setSoftware(props.formValues.software);
    setSpecialInstructions(props.formValues.specialInstructions);
    setLinks(props.formValues.links);
  }, [props?.formValues]);

  const handleOnSubmit = async (data) => {
    setFormValues(data);
    setShowSaveLoader(true);
    let digitalResourcesTosubmit = digitalResources
      ? digitalResources
          .map((e) => {
            if (!e.serial) e.serial = 0;
            if (e.name && e.link && e.priority) return e;
          })
          .filter((e) => e)
      : [];

    if (rowDigitalResources.name && rowDigitalResources.link) {
      rowDigitalResources.serial = 0;
      digitalResourcesTosubmit.push(rowDigitalResources);
    }

    let tutorialsTosubmit = tutorials
      ? tutorials
          .map((e) => {
            if (!e.serial) e.serial = 0;
            if (e.name && e.link && e.priority) return e;
          })
          .filter((e) => e)
      : [];

    if (rowTutorials.name && rowTutorials.link) {
      rowTutorials.serial = 0;
      tutorialsTosubmit.push(rowTutorials);
    }

    let softwareTosubmit = software
      ? software
          .map((e) => {
            if (!e.serial) e.serial = 0;
            if (e.name && e.link && e.priority) return e;
          })
          .filter((e) => e)
      : [];
    if (rowSoftware.name && rowSoftware.link) {
      rowSoftware.serial = 0;
      softwareTosubmit.push(rowSoftware);
    }

    let specialInstructionsTosubmit = specialInstructions
      ? specialInstructions
          .map((e) => {
            if (!e.serial) e.serial = 0;
            if (e.name && e.link && e.priority) return e;
          })
          .filter((e) => e)
      : [];
    if (rowSpecialInstructions.name && rowSpecialInstructions.link) {
      rowSpecialInstructions.serial = 0;
      specialInstructionsTosubmit.push(rowSpecialInstructions);
    }

    let linksTosubmit = links
      ? links
          .map((e) => {
            if (!e.serial) e.serial = 0;
            if (e.name && e.link && e.priority) return e;
          })
          .filter((e) => e)
      : [];
    if (rowLinks.name && rowLinks.link) {
      rowLinks.serial = 0;
      linksTosubmit.push(rowLinks);
    }

    let countriesArr = selectedCountries.length ? selectedCountries.map((e) => e.serial) : [];

    let dataTojson = {
      author: data.author,
      name: data.name,
      description: data.description,
      category: data.category,
      grade: data.grade,
      year: data.year,
      numOfPages: data.numOfPages,
      priority: data.priority,
      digitalResources: digitalResourcesTosubmit,
      tutorials: tutorialsTosubmit,
      software: softwareTosubmit,
      specialInstructions: specialInstructionsTosubmit,
      links: linksTosubmit,
      countries: countriesArr.toString(),
    };

    if (props.title.substring(0, 4) === "Edit") {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/books/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataTojson,
      })
        .then((response) => {
          setCurrentId(response.data.serial);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/books`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataTojson,
      })
        .then((response) => {
          setCurrentId(response.data.serial);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const subDocToSubmitFirstRun = useRef(true);
  useEffect(() => {
    const fetchData = async () => {
      if (subDocToSubmitFirstRun.current) {
        subDocToSubmitFirstRun.current = false;
      } else {
        const formData = new FormData();
        if (formValues?.cover && formValues?.cover?.length) formData.append("cover", formValues.cover[0]);
        if (formValues?.summaryPDF && formValues?.summaryPDF?.length)
          formData.append("summaryPDF", formValues.summaryPDF[0]);
        if (formValues?.bookPDF && formValues?.bookPDF?.length)
          formData.append("bookPDF", formValues?.bookPDF[0]);

        formData.append("_method", "PUT");
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/booksUploads/${currentId}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: formData,
        })
          .then((response) => {
            //setCurrentId(response.data.serial)
            toast.success(`Books has been updated successfully`);
            props.handleClose();
            setShowSaveLoader(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    fetchData();
  }, [currentId]);

  return (
    <>
      <ToastContainer />
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            {props.formValues.cover ? (
              <div className="col-sm-1">
                <img
                  src={process.env.REACT_APP_FILES_PATH + "/Books/" + props.formValues.cover}
                  alt=""
                  className="img-thumbnail rounded"
                />
              </div>
            ) : null}
            <div className={props.formValues.cover ? "col-sm-5" : "col-sm-6"}>
              <div className="form-group">
                <label htmlFor="cover">
                  Cover <em>(400x480)</em>
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="cover"
                  placeholder="Cover"
                  {...register("cover")}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="summaryPDF">
                  Summary PDF{" "}
                  {props.formValues.cover ? (
                    <a
                      href={process.env.REACT_APP_FILES_PATH + "/Books/" + props.formValues.summaryPDF}
                      target="_blank"
                    >
                      {" "}
                      - <i className="fas fa-file-pdf"></i> Check current PDF
                    </a>
                  ) : null}
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="summaryPDF"
                  placeholder="Summary PDF"
                  {...register("summaryPDF")}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="bookPDF">
                  Book PDF{" "}
                  {props.formValues.cover ? (
                    <a
                      href={process.env.REACT_APP_FILES_PATH + "/Books/" + props.formValues.bookPDF}
                      target="_blank"
                    >
                      {" "}
                      - <i className="fas fa-file-pdf"></i> Check current PDF
                    </a>
                  ) : null}
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="bookPDF"
                  placeholder="Book PDF"
                  {...register("bookPDF")}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="author">Author</label>
                <input
                  type="text"
                  className="form-control"
                  id="author"
                  placeholder="Author"
                  {...register("author")}
                  defaultValue={props.formValues.author}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Title"
                  {...register("name")}
                  defaultValue={props.formValues.name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="category">Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="category"
                  placeholder="Category"
                  {...register("category")}
                  defaultValue={props.formValues.category}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="grade">Grade</label>
                <input
                  type="text"
                  className="form-control"
                  id="grade"
                  placeholder="Grade"
                  {...register("grade")}
                  defaultValue={props.formValues.grade}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="year">Year</label>
                <input
                  type="text"
                  className="form-control"
                  id="year"
                  placeholder="Year"
                  {...register("year")}
                  defaultValue={props.formValues.year}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="numOfPages">Number of Pages</label>
                <input
                  type="text"
                  className="form-control"
                  id="numOfPages"
                  placeholder="Number of Pages"
                  {...register("numOfPages")}
                  defaultValue={props.formValues.numOfPages}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <input
                  type="text"
                  className="form-control"
                  id="priority"
                  placeholder="Priority"
                  {...register("priority")}
                  defaultValue={props.formValues.priority}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  placeholder="Description"
                  {...register("description")}
                  defaultValue={props.formValues.description}
                ></textarea>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  multiple
                  id="countries"
                  fullWidth
                  options={countriesList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setSelectedCountries(newValue.map((e) => ({serial: e.serial, name: e.name})));
                  }}
                  value={selectedCountries}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Countries" placeholder="Countries" />
                  )}
                />
              </div>
            </div>

            <div className="col-12 mb-4">
              <NestedTable
                document="digitalResources"
                arrayName={digitalResources}
                setArrayName={setDigitalResources}
                rowData={rowDigitalResources}
                setRowData={setRowDigitalResources}
                title="Digital Resources"
                import={
                  <Tooltip title={"Import"}>
                    <IconButton onClick={() => props.importExcel("digitalResources", "Digital Resources")}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                }
              />
            </div>
            <div className="col-12 mb-4">
              <NestedTable
                document="tutorials"
                arrayName={tutorials}
                setArrayName={setTutorials}
                rowData={rowTutorials}
                setRowData={setRowTutorials}
                title="Tutorials"
                import={
                  <Tooltip title={"Import"}>
                    <IconButton onClick={() => props.importExcel("tutorials", "Tutorials")}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                }
              />
            </div>
            <div className="col-12 mb-4">
              <NestedTable
                document="software"
                arrayName={software}
                setArrayName={setSoftware}
                rowData={rowSoftware}
                setRowData={setRowSoftware}
                title="Software"
                import={
                  <Tooltip title={"Import"}>
                    <IconButton onClick={() => props.importExcel("software", "Software")}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                }
              />
            </div>
            <div className="col-12 mb-4">
              <NestedTable
                document="specialInstructions"
                arrayName={specialInstructions}
                setArrayName={setSpecialInstructions}
                rowData={rowSpecialInstructions}
                setRowData={setRowSpecialInstructions}
                title="Special Instructions"
                import={
                  <Tooltip title={"Import"}>
                    <IconButton
                      onClick={() => props.importExcel("specialInstructions", "Special Instructions")}
                    >
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                }
              />
            </div>
            <div className="col-12 mb-4">
              <NestedTableLinks
                document="links"
                arrayName={links}
                setArrayName={setLinks}
                rowData={rowLinks}
                setRowData={setRowLinks}
                title="Links"
                import={
                  <Tooltip title={"Import"}>
                    <IconButton onClick={() => props.importExcel("links", "Links")}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                }
              />
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              {!showSaveLoader ? (
                <button className="btn btn-lg btn-primary" type="submit">
                  <i className="fas fa-save"></i> Save
                </button>
              ) : (
                <CircularProgress size={30} className="pageLoader" />
              )}{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
