import React, {useState, useEffect} from "react";
import {Navbar, Nav} from "react-bootstrap";
import Scrollspy from "react-scrollspy";
import "../assets/css/styles.css";
import {isAuth, signout} from "../auth/helpers";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {Link, withRouter} from "react-router-dom";
import axios from "axios";
// import Recaptcha from 'react-recaptcha';

const Layout = ({children, match, history}) => {
  const [footerList, setFooterList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/footer`
          ),
        ])
        .then((response) => {
          setFooterList(response[0]?.data?.data[0]);
        });
    };
    fetchData();
  }, []);
  //Search component ---------------END--------------
  // specifying your onload callback function

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        style={{background: "white", top: "4%"}}
        className="container top-menu"
      >
        <Navbar.Brand href="/#home">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo.jpg`}
            alt="L'info Pour Tous"
            style={{height: "9rem"}}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {isAuth() && isAuth().role !== "admin" ? <Nav className="px-3">Hello {isAuth().name}</Nav> : null}
          <Nav className="ml-md-auto">
            <Scrollspy
              items={["introduction", "whyUs", "takeTour", "testimonials", "contactUs", "takeTour"]}
              currentClassName="current"
              className="ml-md-auto navbar-nav text-center"
            >
              {!isAuth() || isAuth().role === "admin" ? (
                <Nav>
                  <Nav.Link href="/#introduction">Home</Nav.Link>
                  <Nav.Link href="/#whyUs">About Us</Nav.Link>
                  <Nav.Link href="/#takeTour">Take a Tour</Nav.Link>
                  <Nav.Link href="/#testimonials">Testimonial</Nav.Link>
                  <Nav.Link href="/#contactUs">Contact Us</Nav.Link>
                  <Nav.Link href="/signin">Log in</Nav.Link>
                  <Nav.Link href="/register1">
                    <button type="button" className="btn btn-secondary">
                      REGISTER
                    </button>
                  </Nav.Link>
                </Nav>
              ) : (
                <Nav>
                  {isAuth().role === "teacher" ? (
                    <Nav>
                      <Nav.Link href={`/teachersBook/${isAuth()._id}`}>My Books</Nav.Link>
                      {/* <Nav.Link href={`/quizList`} className={isAuth().email!=="wissam@rightclick.education"&&"d-none"}>My Quizzes</Nav.Link>
                    <Nav.Link href={`/classList`} className={isAuth().email!=="wissam@rightclick.education"&&"d-none"}>My Classes</Nav.Link> */}
                      <Nav.Link href={`/quizList`}>My Quizzes</Nav.Link>
                      <Nav.Link href={`/classList`}>My Classes</Nav.Link>
                    </Nav>
                  ) : (
                    <Nav.Link href={`/books/${isAuth()._id}`}>My Books</Nav.Link>
                  )}
                  {/* //wissam@rightclick.education */}
                  <Nav.Link onClick={() => signout(() => history.push("/"))}>
                    <button type="button" className="btn btn-secondary w-100">
                      Sign out
                    </button>
                  </Nav.Link>
                </Nav>
              )}
            </Scrollspy>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {children}
      <footer>
        <div className="container">
          <div className="row border-bottom border-primary pb-3 mb-3">
            <div className="col-sm mb-3">
              <h2 className="text-white p-0">Sign up to our newsletter !</h2>
            </div>
            <div className="col-sm newsletterCont">
              <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />
            </div>
          </div>
          <div className="row border-bottom border-primary pb-3 mb-3">
            <ul className="d-flex justify-content-center footerMenu">
              <li>
                <a href="/#introduction">Home</a>
              </li>
              <li>
                <a href="/#whyUs">About Us</a>
              </li>
              <li>
                <a href="/#takeTour">Take Tour</a>
              </li>
              <li>
                <a href="/#testimonials">Testimonial</a>
              </li>
              <li>
                <a href="/#contactUs">Contact</a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-md-4 pl-0">
              <div className="footerColHeader">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo_admin.jpg`}
                  alt="L'info Pour Tous"
                />
              </div>
              <div>{footerList.about}</div>
            </div>
            <div className="col-md-4">
              <div className="footerColHeader">Get in touch</div>
              <div>
                <ul>
                  <li>Tel: {footerList.telephone} </li>
                  <li>
                    Email: <a href={`mailto:${footerList.email}`}>{footerList.email}</a>
                  </li>
                  <li>{footerList.address}</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 pr-0">
              <div className="footerColHeader">Find us here</div>
              <div className="footer_socialMediaCont">
                <a href={footerList.facebook} target="_blank">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href={footerList.instagram} target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href={footerList.twitter} target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href={footerList.linkedin} target="_blank">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href={footerList.youtube} target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="lastDiv">
        <div className="container">
          © 2022-2023 LINFOPOURTOUS. By{" "}
          <a href="http://www.dsoft-lb.com/" target="_blank" className="text-white fw-bold">
            D S O F T
          </a>
        </div>
      </div>
    </>
  );
};
export default withRouter(Layout);
