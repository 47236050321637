import React, {useState, useEffect} from "react";
import Layout from "../../core/Layout";
import {CircularProgress} from "@material-ui/core";
import {getCookie} from "../../auth/helpers";
import axios from "axios";

const TeachersBook = (props) => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/teachers/get_books/${props.match.params.id}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setBooks(response.data.books);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <div className="container pt-5 mt-5">
        <div id="register2" className="py-5 my-5">
          <div className="d-flex flex-column pt-5 mt-5">
            <div className="text-center">
              <h3 className="text-secondary mb-0">Registered books</h3>
              <h2 className="text-primary mb-5">Select your book</h2>
            </div>
            <div className="row">
              {books.length ? (
                books.map((e) => (
                  <a
                    href={`/teacherBookDetails/${e.serial}`}
                    className="text-center col-6 col-sm-4 col-md-2 mb-5"
                    key={e.serial}
                  >
                    <img src={`${process.env.REACT_APP_FILES_PATH}/Books/${e.cover}`} alt="" width="96%" />
                    <br />
                    <p style={{fontSize: "1.5rem"}}>{e.name}</p>
                  </a>
                ))
              ) : isLoading === false ? (
                <p className="text-center">
                  Our team will contact you shortly to complete your account setup, we appreciate your
                  patience
                </p>
              ) : (
                <CircularProgress size={30} className="pageLoader mx-auto" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default TeachersBook;
