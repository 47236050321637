import React, {useState, useEffect} from "react";
import Layout from "../../core/Layout";
import moment from "moment";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";

const Register2Frontend = (props) => {
  const [books, setBooks] = useState([]);
  const [checkedBooksIds, setCheckedBooksIds] = useState([]);
  //const [checkedBooks, setCheckedBooks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/booksList`,
        {
          responseType: "json",
        }
      ).then((response) => {
        setBooks(response?.data?.data);
      });
    };
    fetchData();
  }, []);

  const handleChecked = (e) => {
    setCheckedBooksIds(e.target.value);
    // if(e.target.checked===true){
    //   setCheckedBooksIds([...checkedBooksIds,e.target.value])
    // }else{
    //   setCheckedBooksIds(checkedBooksIds.filter(eSub=>eSub!==e.target.value))
    // }
  };
  const handleClickImg = (e) => {
    document.getElementById(`book${e}`).checked = true;
    setCheckedBooksIds(`${e}`);
  };
  const handleSubmitUser = async () => {
    let checkedBooks = books.filter((e) => checkedBooksIds.includes(e.serial.toString()));
    if (checkedBooks.length) {
      let dataToSubmit = {
        type: props.location.state.type,
        email: props.location.state.email,
        name: props.location.state.name,
        notes: "",
        password: props.location.state.password,
        phone: props.location.state.phone,
        school: props.location.state.school,
        books: checkedBooks.map((e) => ({
          ticketNumber: props.location.state.ticketNumber,
          booksId: e.serial,
          name: e.name,
          booksId: e.serial,
          from: moment().format("YYYY-MM-DD"),
          grade: "Grade 5",
          serial: 0,
          to: moment().add(1, "years").format("YYYY-MM-DD"),
        })),
      };

      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/user/new`,
        data: dataToSubmit,
      })
        .then((response) => {
          props.history.push({
            pathname: "/register3",
            state: dataToSubmit,
          });
        })
        .catch((error) => {
          props.history.push({
            pathname: "/register1",
            state: {error: error.response.data.msg},
          });
          console.log(error.response.data.msg);
        });
    } else {
      toast.error("Please select a book");
    }
  };

  return (
    <Layout>
      <ToastContainer />
      <div className="container pt-5 mt-5">
        <div id="register2" className="py-5 my-5">
          <div className="d-flex flex-column pt-5 mt-5">
            <div className="text-center">
              <h3 className="text-secondary mb-0">Register book</h3>
              <h2 className="text-primary mb-5">Select your book</h2>
            </div>
            <div className="row">
              {books.map((e) => (
                <div className="text-center col-sm-3" key={e.serial}>
                  <img
                    src={`${process.env.REACT_APP_FILES_PATH}/Books/${e.cover}`}
                    alt=""
                    width="96%"
                    onClick={() => handleClickImg(e.serial)}
                  />
                  <br />
                  <input
                    className="form-check-input"
                    type="radio"
                    value={e.serial}
                    onChange={handleChecked}
                    name="book"
                    id={`book${e.serial}`}
                  />
                  <br />
                  <p>{e.name}</p>
                </div>
              ))}
            </div>
            <div className="col-12 text-center mt-5">
              <button className="btn btn-secondary" type="button" onClick={handleSubmitUser}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Register2Frontend;
