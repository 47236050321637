import React, {useState, useEffect} from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {CircularProgress} from "@material-ui/core";
import {ToastContainer, toast} from "react-toastify";
// import ReactMomentCountDown from 'react-moment-countdown';
import moment from "moment";
import axios from "axios";

const StudentsQuiz = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [studentQuizDetails, setStudentQuizDetails] = useState({});
  const [quizDetails, setQuizDetails] = useState({});
  const [studentDetails, setStudentDetails] = useState({});
  const [questionsList, setQuestionsList] = useState([]);
  const [answersList, setAnswersList] = useState([]);
  const [dataToSubmit, setDataToSubmit] = useState([]);
  const [quizTime, setQuizTime] = useState({});
  const [timeToStart, setTimeToStart] = useState();
  const [timeToFinish, setTimeToFinish] = useState();
  const [submit, setSubmit] = useState(0);

  const studentQuizUrlId = String(props.match.params.quizId); //students_quizzes serial - studentId+quizId+classId

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/students`
          ),
        ])
        .then((response) => {
          setStudentQuizDetails(
            response[0]?.data?.data.find((e) => {
              const calculatedIds = String(Number(e.studentId) + Number(e.quizId) + Number(e.classId));
              return e.serial + calculatedIds === studentQuizUrlId;
            })
          );
        });
    };
    fetchData();
  }, [submit]);

  useEffect(() => {
    const fetchData = async () => {
      if (studentQuizDetails !== {}) {
        const studentId = studentQuizDetails.studentId;
        const quizId = studentQuizDetails.quizId;
        const classId = studentQuizDetails.classId;
        await axios
          .all([
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/myclass/students/byClassId/${classId}`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/quizs/${quizId}`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/quiz/question/${quizId}`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/answers`
            ),
          ])
          .then((response) => {
            setStudentDetails(response[0].data.find((e) => e.serial == studentId));
            setQuizDetails(response[1].data);
            setTimeToStart(moment(response[1].data.fromDate).format("X") - moment(new Date()).format("X"));
            setTimeToFinish(moment(response[1].data.toDate).format("X") - moment(new Date()).format("X"));
            setQuizTime({
              quizDate: moment(response[1].data.fromDate).format("DD MMM YYYY"),
              startTime: moment(response[1].data.fromDate).format("HH:mm"),
              endTime: moment(response[1].data.toDate).format("HH:mm"),
            });
            setQuestionsList(response[2].data);
            setDataToSubmit(
              response[2].data.length
                ? response[2].data.map((e) => ({
                    questionId: e.serial,
                    students_quizId: studentQuizDetails.serial,
                  }))
                : []
            );
            setAnswersList(response[3]?.data?.data);
            setIsLoading(false);
          });
      }
    };
    fetchData();
  }, [studentQuizDetails]);

  useEffect(() => {
    if (!timeToStart || timeToStart < 0) {
      return;
    }
    const intervalId = setInterval(() => {
      setTimeToStart(timeToStart - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeToStart]);

  useEffect(() => {
    if (!timeToFinish || timeToFinish < 0) {
      return handleSaveForm();
    }
    setSubmit(1);
    const intervalId = setInterval(() => {
      setTimeToFinish(timeToFinish - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeToFinish]);

  const handleCheckedAnswers = (e, emark, ecorrect) => {
    if (e.target.checked) {
      const questionId = e.target.name;
      const answerId = e.target.value;
      const mark = ecorrect === 1 ? emark : 0;
      setDataToSubmit(
        dataToSubmit.map((e) => {
          if (e.questionId == questionId) {
            e.answerId = answerId;
            e.mark = mark;
          }
          return e;
        })
      );
    }
  };
  const handleNotes = (e, questionId) => {
    setDataToSubmit(
      dataToSubmit.map((eSub) => {
        if (eSub.questionId == questionId) {
          eSub.notes = e.target.value;
        }
        return eSub;
      })
    );
  };
  const handleSaveForm = async () => {
    if (submit === 1) {
      setDataToSubmit(
        dataToSubmit.map((e) => {
          if (!e.notes) {
            e.notes = "";
          }
          if (!e.answerId) {
            e.answerId = null;
          }
          if (!e.mark) {
            e.mark = 0;
          }
          return e;
        })
      );

      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/quiz/students/answer`,
        data: {data: dataToSubmit},
      });

      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/quiz/students/${studentQuizDetails.serial}`,
        data: {
          quizId: studentQuizDetails.quizId,
          studentId: studentQuizDetails.studentId,
          classId: studentQuizDetails.classId,
          mark: dataToSubmit.length ? dataToSubmit.map((e) => e.mark).reduce((a, b) => a + b) : 0,
        },
      })
        .then((response) => {
          toast.success("Successfully Submitted");
          setSubmit(0);
          // history.push({ pathname: "/", state: { type: "Pending Request!",message: response.data } });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const fmtMSS = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
  };
  return (
    <>
      <ToastContainer />
      {!isLoading ? (
        <div className="container">
          <div className="d-flex align-items-center my-5">
            <a href="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo.jpg`}
                alt="L'info Pour Tous"
                style={{height: "9rem"}}
              />
            </a>

            <div className="ml-auto">
              <div className="text-capitalize text-right mb-3">
                <h4>Hi {studentDetails ? studentDetails.name : ""}</h4>
                <strong className="text-primary">Quiz time:</strong> {quizTime.quizDate}
                <br />
                <strong className="text-primary">from:</strong> {quizTime.startTime} &nbsp;{" "}
                <strong className="text-primary">to:</strong> {quizTime.endTime}
                <br />
                {timeToFinish > 0 && timeToStart <= 0 ? (
                  <p>
                    <strong className="text-primary">Time Left:</strong> {fmtMSS(timeToFinish)}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row my-5">
            <h2 className="text-primary mb-5">Quiz: {quizDetails ? quizDetails.name : ""}</h2>
            {timeToFinish > 0 && timeToStart <= 0 && studentQuizDetails.mark === null ? (
              <div className="col-12">
                <ul className="list-unstyled">
                  {questionsList.length ? (
                    questionsList.map((e) => {
                      const questionAnswers = answersList.length
                        ? answersList.filter((answer) => answer.question_id === e.question_id)
                        : [];
                      return (
                        <>
                          <li className="media">
                            <img
                              className="mr-3 quiz-img"
                              width="100"
                              src={process.env.REACT_APP_FILES_PATH + "/Questions/" + e.image}
                              alt="Generic placeholder image"
                            />
                            <div className="media-body">
                              <h5 className="mb-3 text-primary">
                                {e.question} ({e.mark} pts)
                              </h5>
                              {questionAnswers.length
                                ? questionAnswers.map((answer) => (
                                    <div className="form-check ml-5">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={e.serial}
                                        id={`answer-${answer.serial}`}
                                        value={answer.serial}
                                        onChange={(event) =>
                                          handleCheckedAnswers(event, e.mark, answer.correct)
                                        }
                                      />
                                      <label className="form-check-label" htmlFor={`answer${answer.serial}`}>
                                        {answer.answer}
                                      </label>
                                    </div>
                                  ))
                                : null}
                              <div className="m-3 ml-5">
                                <label htmlFor={`note-${e.serial}`} className="form-label">
                                  Additional Notes
                                </label>
                                <textarea
                                  className="form-control"
                                  id={`note-${e.serial}`}
                                  rows="3"
                                  onBlur={(event) => handleNotes(event, e.serial)}
                                ></textarea>
                              </div>
                            </div>
                          </li>
                          <li className="dropdown-divider my-4"></li>
                        </>
                      );
                    })
                  ) : (
                    <li>No Questions Available</li>
                  )}
                </ul>
                <div className="d-flex flex-row-reverse">
                  <input
                    type="button"
                    onClick={handleSaveForm}
                    value="Submit Quiz"
                    disabled={submit === 0 ? true : false}
                    className="btn btn-secondary ml-auto"
                  />
                </div>
              </div>
            ) : null}

            {timeToStart > 0 && !studentQuizDetails.mark != null ? (
              <h3 className="text-center m-5">
                Your quiz will start in
                <br />
                <br />
                <span className="text-primary">{timeToStart}</span> seconds
              </h3>
            ) : null}
            {timeToFinish <= 0 && timeToStart <= 0 && !studentQuizDetails.mark != null ? (
              <h3 className="text-center m-5">
                Times up!
                <br />
                <br />
                Your responses are automatically submitted
              </h3>
            ) : null}
            {studentQuizDetails.mark != null ? (
              <>
                <h3 className="text-center m-5">Quiz Done!</h3>
                <h5 className="text-center m-1" style={{paddingLeft: "5rem"}}>
                  For More Challenges, Follow Us On Our Social Media Network
                </h5>

                <div className="col-md-4 text-center pt-5" style={{margin: "0 auto"}}>
                  <div className="footer_socialMediaCont" style={{paddingLeft: "8rem"}}>
                    <a href="//www.facebook.com/LinfoPourTous" target="_blank">
                      <i className="fab fa-facebook" style={{color: "#074464"}}></i>
                    </a>
                    <a href="//www.instagram.com/LinfoPourTous" target="_blank">
                      <i className="fab fa-instagram" style={{color: "#074464"}}></i>
                    </a>
                    <a href="//twitter.com/Linfopourtous/" target="_blank">
                      <i className="fab fa-twitter" style={{color: "#074464"}}></i>
                    </a>
                    <a href="//www.linkedin.com/in/wissamdaccache" target="_blank">
                      <i className="fab fa-linkedin" style={{color: "#074464"}}></i>
                    </a>
                    <a href="//www.youtube.com/linfopourtousonline" target="_blank">
                      <i className="fab fa-youtube" style={{color: "#074464"}}></i>
                    </a>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
      <footer>
        <div className="container">
          <div className="row border-bottom border-primary pb-3 mb-3">
            <div className="col-sm mb-3">
              <h2 className="text-white p-0">Sign up to our newsletter !</h2>
            </div>
            <div className="col-sm newsletterCont">
              <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />
            </div>
          </div>
          <div className="row border-bottom border-primary pb-3 mb-3">
            <ul className="d-flex justify-content-center footerMenu">
              <li>
                <a href="/#introduction">Home</a>
              </li>
              <li>
                <a href="/#whyUs">About Us</a>
              </li>
              <li>
                <a href="/#takeTour">Take Tour</a>
              </li>
              <li>
                <a href="/#testimonials">Testimonial</a>
              </li>
              <li>
                <a href="/#contactUs">Contact</a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="footerColHeader pl-0">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo_admin.jpg`}
                  alt="L'info Pour Tous"
                />
              </div>
              <div>
                A leading Publishing House since 1999 to deliver ICT and Robotics K12 curriculum aligned to
                develop Skills in IOT – AI and Machine Learning
              </div>
            </div>
            <div className="col-md-4">
              <div className="footerColHeader">Get in touch</div>
              <div>
                <ul>
                  <li>Tel: +961 3 380633 </li>
                  <li>
                    Email: <a href="mailto:support@zero1.education">support@zero1.education</a>
                  </li>
                  <li>Beirut, Mkalles, Librairie Antoine Building</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footerColHeader pr-0">Find us here</div>
              <div className="footer_socialMediaCont">
                <a href="//www.facebook.com/LinfoPourTous" target="_blank">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="//www.instagram.com/LinfoPourTous" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="//twitter.com/Linfopourtous/" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="//www.linkedin.com/in/wissamdaccache" target="_blank">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="//www.youtube.com/linfopourtousonline" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="lastDiv">
        <div className="container">
          © 2021 LINFOPOURTOUS. By{" "}
          <a href="http://www.dsoft-lb.com/" target="_blank" className="text-white text-bold">
            D S O F T
          </a>
        </div>
      </div>
    </>
  );
};
export default StudentsQuiz;
