import React, {useState, useEffect} from "react";
import {TextField} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomToolbar from "../../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.min.css";

const NestedTable = (props) => {
  const token = getCookie("token");
  const [importXlsx, setImportXlsx] = useState(false); //Import Excel

  const handleImportXlsx = () => {
    setImportXlsx(true);
  };

  const add1Row = () => {
    props.setArrayName([...props.arrayName, {serial: 0, name: "", email: "", status: "new"}]);
  };

  const handleChange = async (e, tableMeta) => {
    const {name, value} = e.target;
    const serial = tableMeta.rowData[0];
    const status = tableMeta.rowData[1];
    const rowIndex = tableMeta.rowIndex;

    if (status === "old") {
      let data = {...props.arrayName.filter((e) => e.serial === serial)[0], [name]: value};
      delete data.serial;
      delete data.status;
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass/students/${serial}`,
        headers: {Authorization: `Bearer ${token}`},
        data: data,
      })
        .then((response) => {
          // toast.success("Succesfully Updated");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      props.setArrayName(
        props.arrayName.map((e, i) => {
          if (i === rowIndex && name === "name") e.name = value;
          if (i === rowIndex && name === "email") e.email = value;
          return e;
        })
      );
    }
  };
  //   useEffect(()=>{
  // console.log("props.arrayName",props.arrayName)
  //   },[props.arrayName])
  return (
    <div>
      <ToastContainer />
      <MUIDataTable
        data={props.arrayName}
        title={props.title}
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "status",
            options: {
              display: false,
            },
          },
          {
            name: "name",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div className="form-group">
                    <label htmlFor={`${props.title}Input`}>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${props.title}Input`}
                      name="name"
                      defaultValue={value}
                      onBlur={(e) => handleChange(e, tableMeta)}
                    />
                  </div>
                );
              },
            },
          },
          {
            name: "email",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div className="form-group">
                    <label htmlFor={`${props.title}Input`}>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${props.title}Input`}
                      name="email"
                      defaultValue={value}
                      onBlur={(e) => handleChange(e, tableMeta)}
                    />
                  </div>
                );
              },
            },
          },
        ]}
        options={{
          filter: false,
          rowsPerPage: 100,
          rowsPerPageOptions: [20, 50, 100],
          customToolbar: () => {
            return <CustomToolbar listener={add1Row} importXlsx={handleImportXlsx} />;
          },
          textLabels: {
            body: {
              noMatch: !props.isLoading && "Sorry, there is no matching data to display",
            },
          },
          onRowsDelete: (rowsDeleted, dataRows) => {
            const idsToDelete = rowsDeleted.data.map((d) => props.arrayName[d.dataIndex].serial);
            axios
              .delete(
                `${
                  window?.location?.hostname?.includes("maroc")
                    ? process.env.REACT_APP_API_MAROC
                    : process.env.REACT_APP_API
                }/myclass/students/${idsToDelete}`,
                {
                  responseType: "json",
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                //toast.success(response.data.Message);
              });
          },
        }}
      />
    </div>
  );
};

export default NestedTable;
