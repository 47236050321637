import React, {useState, useEffect} from "react";
import "../../assets/css/styles.css";
import {getCookie} from "../../auth/helpers";
import {CircularProgress} from "@material-ui/core";
import {Tabs, Tab} from "react-bootstrap";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import Layout from "../../core/Layout";

const TeacherBookDetails = ({match}) => {
  const [dataGET, setDataGET] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const token = getCookie("token");

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/booksList/${match.params.id}`,
        {
          responseType: "json",
        }
      ).then((response) => {
        if (response.data.Message) toast.error(response.data.Message);
        else setDataGET(response.data);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <div className="container pt-5 mt-5">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          newestOnTop={false}
          hideProgressBar={true}
          closeOnClick
        />
        <div className="row my-5 py-5">
          <h2 className="text-primary my-5">My Digital Resources</h2>
          {Object.keys(dataGET).length ? (
            <div className="col-3">
              <a href={`${window?.location?.origin}/backend/public/Books/${dataGET.serial}`} target="_blank">
                <img
                  src={`${window?.location?.origin}/backend/public/Books/${dataGET.cover}`}
                  alt=""
                  style={{width: "100%"}}
                />
              </a>
              <div className="fw-bold text-center mt-3">
                <a
                  href={`${window?.location?.origin}/backend/public/Books/${dataGET.serial}`}
                  target="_blank"
                ></a>
              </div>
              <div className="mt-5"></div>
            </div>
          ) : null}
          {Object.keys(dataGET).length ? (
            <div className="col-9">
              <Tabs defaultActiveKey="flipbook" id="uncontrolled-tab">
                {dataGET.chapters.length > 0 ? (
                  <Tab eventKey="flipbook" title="FlipBook">
                    <div className="mt-5">
                      <h4>Chapters</h4>
                      <ul>
                        {dataGET.chapters.map((e) => (
                          <li key={e.serial}>
                            <a
                              href={`${window?.location?.origin}/backend/public/Books-chapters/${e.serial}`}
                              target="_blank"
                            >
                              {e.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Tab>
                ) : null}
                {dataGET.name ? (
                  <Tab eventKey="description" title="Description">
                    <div className="mt-5">
                      <h4 className="mb-0">{dataGET.name}</h4>
                      <small className="mb-4 d-block">
                        {dataGET.author} - {dataGET.year}
                      </small>
                      <p style={{whiteSpace: "pre-wrap"}}>{dataGET.description}</p>
                    </div>
                  </Tab>
                ) : null}
                {dataGET.digitalResources.length > 0 ? (
                  <Tab eventKey="digitalResources" title="Digital resources">
                    <div className="mt-5 ">
                      <ul>
                        {dataGET.digitalResources.map((e) => (
                          <li key={e.serial}>
                            <a href={e.link} target="_blank">
                              {e.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Tab>
                ) : null}
                {dataGET.tutorials.length > 0 ? (
                  <Tab eventKey="tutorials" title="Tutorials">
                    <div className="mt-5 row">
                      {dataGET.tutorials.map((e) => (
                        <div className="col-4" key={e.serial}>
                          <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                              className="m-2 embed-responsive-item"
                              src={e.link}
                              allowFullScreen
                            ></iframe>
                          </div>
                          <p style={{fontSize: "1.5rem"}}>{e.name}</p>
                        </div>
                      ))}
                    </div>
                  </Tab>
                ) : null}
                {dataGET.software.length > 0 ? (
                  <Tab eventKey="software" title="Software">
                    <div className="mt-5">
                      <ul>
                        {dataGET.software.map((e) => (
                          <li key={e.serial}>
                            <a href={e.link} target="_blank">
                              {e.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Tab>
                ) : null}
                {dataGET.specialInstructions.length > 0 ? (
                  <Tab eventKey="teacherTools" title="Teacher tools">
                    <div className="mt-5">
                      <ul>
                        {dataGET.specialInstructions.map((e) => (
                          <li key={e.serial}>
                            <a href={e.link} target="_blank">
                              {e.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Tab>
                ) : null}

                {dataGET.extraResources.length > 0 ? (
                  <Tab eventKey="extraResources" title="Extra Resources">
                    {dataGET.extraResources.map((category) => (
                      <div className="mt-5">
                        <h4>{category.name}</h4>
                        <ul style={{listStyle: "none"}}>
                          {category?.resources?.map((resource) => (
                            <li key={resource.serial} style={{marginBottom: 10}}>
                              <>
                                <img
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 20,
                                  }}
                                  src={`${window?.location?.origin}/backend/public/ExtraResources/${resource.icon}`}
                                />
                                {resource.attachment ? (
                                  <a
                                    href={`${window?.location?.origin}/backend/public/ExtraResources/${resource.attachment}`}
                                    target="_blank"
                                  >
                                    {resource.description}
                                  </a>
                                ) : (
                                  <a href={resource.link} target="_blank">
                                    {resource.description}
                                  </a>
                                )}
                              </>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </Tab>
                ) : null}
              </Tabs>
            </div>
          ) : isLoading === false ? (
            <div className="col-12 text-center mt-5">
              <p>
                Our team will contact you shortly to complete your account setup, we appreciate your patience
              </p>
              <button className="btn btn-secondary" type="button">
                Register a Book
              </button>
            </div>
          ) : (
            <CircularProgress size={30} className="pageLoader mx-auto" />
          )}
        </div>
      </div>
    </Layout>
  );
};
export default TeacherBookDetails;
