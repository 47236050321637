import React, {useState, useEffect} from "react";
import CustomToolbar from "../../../../components/CustomToolbar";
import {getCookie} from "../../../../auth/helpers";
import MUIDataTable from "mui-datatables";
import {ToastContainer, toast} from "react-toastify";
import axios from "axios";

const NestedTable = (props) => {
  const token = getCookie("token");

  const add1Row = () => {
    props.setArrayName([...props.arrayName, {answer: "", correct: 0, status: "new"}]);
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/answers/${props.questionId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        props.setArrayName(response.data.map((e) => ({...e, status: "old"})));
      });
    };
    fetchData();
  }, []);

  const handleChange = async (e, tableMeta) => {
    const serial = tableMeta.rowData[0];
    const status = tableMeta.rowData[1];
    const rowIndex = tableMeta.rowIndex;
    const {name, value} = e.target;
    if (status === "old") {
      let data = {...props.arrayName.filter((e) => e.serial === serial)[0], [name]: value};
      delete data.serial;
      delete data.status;
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/answers/${serial}`,
        headers: {Authorization: `Bearer ${token}`},
        data: data,
      })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      props.setArrayName(
        props.arrayName.map((e, i) => {
          if (i === rowIndex && name === "answer") e.answer = value;
          return e;
        })
      );
    }
  };

  const handleChecked = async (e, tableMeta) => {
    const serial = tableMeta.rowData[0];
    const status = tableMeta.rowData[1];
    const rowIndex = tableMeta.rowIndex;
    const {name, checked} = e.target;
    if (status === "old") {
      let data = {...props.arrayName.filter((e) => e.serial === serial)[0], [name]: checked === true ? 1 : 0};
      delete data.serial;
      delete data.status;
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/answers/${serial}`,
        headers: {Authorization: `Bearer ${token}`},
        data: data,
      })
        .then((response) => {
          props.setArrayName(
            props.arrayName.map((e, i) => {
              if (e.serial === response.data.serial) e.correct = response.data.correct;
              return e;
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      props.setArrayName(
        props.arrayName.map((e, i) => {
          if (i === rowIndex && name === "correct") e.correct = checked === true ? 1 : 0;
          return e;
        })
      );
    }
  };

  // useEffect(()=>{
  //   console.log("props.arrayName",props.arrayName)
  // },[props.arrayName])

  return (
    <>
      <ToastContainer />
      <MUIDataTable
        data={props.arrayName}
        title="Answers"
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "status",
            options: {
              display: false,
            },
          },
          {
            name: "answer",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div className="form-group">
                    <label htmlFor={`${props.title}Input`}>Answer</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${props.title}Input`}
                      name="answer"
                      defaultValue={value}
                      onBlur={(e) => handleChange(e, tableMeta)}
                    />
                  </div>
                );
              },
            },
          },
          {
            name: "correct",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`${props.title}Correct`}
                      name="correct"
                      defaultChecked={value === 1 ? true : false}
                      onClick={(e) => handleChecked(e, tableMeta)}
                    />
                  </div>
                );
              },
            },
          },
        ]}
        options={{
          filter: false,
          customToolbar: () => {
            return <CustomToolbar listener={add1Row} />;
          },
          textLabels: {
            body: {
              noMatch: !props.isLoading && "Sorry, there is no matching data to display",
            },
          },
          onRowsDelete: (rowsDeleted, dataRows) => {
            const idsToDelete = rowsDeleted.data.map((d) => props.arrayName[d.dataIndex].serial);
            axios
              .delete(
                `${
                  window?.location?.hostname?.includes("maroc")
                    ? process.env.REACT_APP_API_MAROC
                    : process.env.REACT_APP_API
                }/answers/${idsToDelete}`,
                {
                  responseType: "json",
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                toast.success(response.data.Message);
              });
          },
        }}
      />
    </>
  );
};

export default NestedTable;
