import React, {useEffect} from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import {getCookie, isAuth} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import axios from "axios";
import {countries} from "../../../../data/countries";
import {useState} from "react";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const adminUser = isAuth();
  const {register, handleSubmit, watch, setValue} = useForm({
    defaultValues: {
      user_id: props?.formValues?.user_id,
      boys_section: props?.formValues?.boys_section,
      girls_section: props?.formValues?.girls_section,
      users: props?.formValues?.users,
      mBot2: props?.formValues?.mBot2,
      Microbit: props?.formValues?.Microbit,
      Kitronik: props?.formValues?.Kitronik,
      Arduino: props?.formValues?.Arduino,

      mBot2_offered: props?.formValues?.mBot2_offered,
      Microbit_offered: props?.formValues?.Microbit_offered,
      Kitronik_offered: props?.formValues?.Kitronik_offered,
      Arduino_offered: props?.formValues?.Arduino_offered,

      educational_company_serial: props?.formValues?.educational_company_serial,
    },
  });

  const [countriesList, setCountriesList] = useState(countries);
  const [educationalCompanies, setEducationalCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const watchEducationalCompany = watch(["educational_company_serial"]); // you can also target specific fields by their names

  const handleOnSubmit = async (data) => {
    const formData = new FormData();

    for (var key in data) {
      if (data[key]) {
        if (typeof data[key] == "boolean") {
          formData.append(key, data[key] ? 1 : 0);
        } else {
          formData.append(key, data[key]);
        }
      } else {
        if (key != "date_offered") formData.append(key, 0);
      }
    }

    if (props?.title.substring(0, 4) === "Edit") {
      formData.append("_method", "PUT");
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/schools/${props?.formValues?.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props?.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/schools`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
        .then((response) => {
          props?.handleClose();

          try {
            props.getSchools();
          } catch (e) {
            console.log(e);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const fetchEducationalCompanies = async () => {
      axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/educationalCompanies`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        const data = response?.data?.data;
        setEducationalCompanies(data);
      });
    };

    const fetchUsers = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/users?profile=2`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        const data = response?.data?.data;
        setUsers(data);
      });
    };

    if (educationalCompanies.length == 0) fetchEducationalCompanies();
    if (users.length == 0) fetchUsers();
  }, []);

  useEffect(() => {
    let id = watchEducationalCompany[0];

    if (id) {
      let country = educationalCompanies.find((e) => e.serial == id)?.country;
      if (country) setValue("country", country);
    }
  }, [watchEducationalCompany]);

  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props?.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props?.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          {adminUser.profile == 1 ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="user_id">Educational representative</label>
                  {users.length > 0 ? (
                    <select className="form-select" id="user_id" {...register("user_id")}>
                      <option value={"0"}>-None-</option>
                      {users.map((e) => (
                        <option value={e.id} key={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select className="form-select" id="user_id">
                      <option value={"0"}>-None-</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">School Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  defaultValue={props?.formValues?.name}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="educational_company_serial">Educational Company </label>
                {educationalCompanies.length > 0 ? (
                  <select
                    className="form-select"
                    id="educational_company_serial"
                    {...register("educational_company_serial")}
                  >
                    <option value={"0"}>-None-</option>
                    {educationalCompanies.map((e) => (
                      <option value={e.serial} key={e.serial}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select className="form-select" id="educational_company_serial">
                    <option value={"0"}>-None-</option>
                  </select>
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <select
                  className="form-select"
                  id="country"
                  {...register("country")}
                  defaultValue={props?.formValues?.country}
                >
                  {countriesList.map((e) => (
                    <option value={e.name} key={e.serial}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Address"
                  {...register("address")}
                  defaultValue={props?.formValues?.address}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="email">School Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="email"
                  {...register("email")}
                  defaultValue={props?.formValues?.email}
                />
              </div>
            </div>
            <div className="col-sm-6"></div>

            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="academic_director1">Academic Director (Boys)</label>
                <input
                  type="text"
                  className="form-control"
                  id="academic_director1"
                  placeholder="Full name"
                  {...register("academic_director1")}
                  defaultValue={props?.formValues?.academic_director1}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="academic_director1_email">Academic Director (Boys) Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="academic_director1_email"
                  placeholder="Full name"
                  {...register("academic_director1_email")}
                  defaultValue={props?.formValues?.academic_director1_email}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="academic_director1_whatsapp">Academic Director (Boys) Whatsapp</label>
                <input
                  type="text"
                  className="form-control"
                  id="academic_director1_whatsapp"
                  placeholder="Full name"
                  {...register("academic_director1_whatsapp")}
                  defaultValue={props?.formValues?.academic_director1_whatsapp}
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="academic_director2">Academic Director (Girls)</label>
                <input
                  type="text"
                  className="form-control"
                  id="academic_director2"
                  placeholder="Full name"
                  {...register("academic_director2")}
                  defaultValue={props?.formValues?.academic_director2}
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="academic_director2_email">Academic Director (Girls) Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="academic_director2_email"
                  placeholder="Full name"
                  {...register("academic_director2_email")}
                  defaultValue={props?.formValues?.academic_director2_email}
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="academic_director2_whatsapp">Academic Director (Girls) Whatsapp</label>
                <input
                  type="text"
                  className="form-control"
                  id="academic_director2_whatsapp"
                  placeholder="Full name"
                  {...register("academic_director2_whatsapp")}
                  defaultValue={props?.formValues?.academic_director2_whatsapp}
                />
              </div>
            </div>

            {/* <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="educational_representative_name">Educational representative name</label>
                <input
                  type="text"
                  className="form-control"
                  id="educational_representative_name"
                  placeholder="name"
                  {...register("educational_representative_name")}
                  defaultValue={props?.formValues?.educational_representative_name}
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="educational_representative_email"> Educational representative email</label>
                <input
                  type="text"
                  className="form-control"
                  id="educational_representative_email"
                  placeholder="email"
                  {...register("educational_representative_email")}
                  defaultValue={props?.formValues?.educational_representative_email}
                />
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="educational_representative_whatsapp">
                  Educational representative whatsapp
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="educational_representative_whatsapp"
                  placeholder="whatsapp"
                  {...register("educational_representative_whatsapp")}
                  defaultValue={props?.formValues?.educational_representative_whatsapp}
                />
              </div>
            </div> */}

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="school_type">School type</label>
                <select
                  className="form-select"
                  id="school_type"
                  {...register("school_type")}
                  defaultValue={props?.formValues?.school_type}
                >
                  <option value={"national"}>National</option>

                  <option value={"international"}>International</option>

                  <option value={"national_international"}>National/International</option>
                </select>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="adoption">Adoption</label>
                <select
                  className="form-select"
                  id="adoption"
                  {...register("adoption")}
                  defaultValue={props?.formValues?.adoption}
                >
                  <option value={null}>N/A</option>

                  <option value={"righ_click"}>Right Click </option>
                  <option value={"zero_one"}>ZERO1</option>
                  <option value={"passeport_informatique"}>Passeport Informatique</option>
                  <option value={"zakaa"}>ZAKAA</option>
                </select>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="periods_per_week">Number of periods per week</label>
                <input
                  type="text"
                  className="form-control"
                  id="periods_per_week"
                  placeholder="Periods"
                  {...register("periods_per_week")}
                  defaultValue={props?.formValues?.periods_per_week}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="labs">Number of LABS</label>
                <input
                  type="text"
                  className="form-control"
                  id="labs"
                  placeholder="Labs"
                  {...register("labs")}
                  defaultValue={props?.formValues?.labs}
                />
              </div>
            </div>

            <div className="col-sm-2 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="boys_section" {...register("boys_section")} className="mx-3" />
                <label htmlFor="boys_section">Boys section</label>
              </div>
            </div>
            <div className="col-sm-2 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="girls_section" {...register("girls_section")} className="mx-3" />
                <label htmlFor="girls_section">Girls section</label>
              </div>
            </div>

            <div className="col-sm-2 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="users" {...register("users")} className="mx-3" />
                <label htmlFor="users">Users (using our books)</label>
              </div>
            </div>

            {/* mBot2 – Microbit – Kitronik – Arduino */}
            <div className="col-sm-12">
              <p>Hardware used</p>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="mBot2" {...register("mBot2")} className="mx-3" />
                <label htmlFor="mBot2">mBot2</label>
              </div>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="Microbit" {...register("Microbit")} className="mx-3" />
                <label htmlFor="Microbit">Microbit</label>
              </div>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="Kitronik" {...register("Kitronik")} className="mx-3" />
                <label htmlFor="Kitronik">Kitronik</label>
              </div>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="Arduino" {...register("Arduino")} className="mx-3" />
                <label htmlFor="Arduino">Arduino</label>
              </div>
            </div>

            <div className="col-sm-12">
              <p>Hardware offered</p>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input type="checkbox" id="mBot2_offered" {...register("mBot2_offered")} className="mx-3" />
                <label htmlFor="mBot2_offered">mBot2</label>
              </div>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="Microbit_offered"
                  {...register("Microbit_offered")}
                  className="mx-3"
                />
                <label htmlFor="Microbit_offered">Microbit</label>
              </div>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="Kitronik_offered"
                  {...register("Kitronik_offered")}
                  className="mx-3"
                />
                <label htmlFor="Kitronik_offered">Kitronik</label>
              </div>
            </div>
            <div className="col-sm-3 align-self-end">
              <div className="form-group">
                <input
                  type="checkbox"
                  id="Arduino_offered"
                  {...register("Arduino_offered")}
                  className="mx-3"
                />
                <label htmlFor="Arduino_offered">Arduino</label>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="date_offered">Date Offered</label>
                <input
                  type="date"
                  className="form-control"
                  id="date_offered"
                  placeholder="Date"
                  {...register("date_offered")}
                  defaultValue={props.formValues.date_offered}
                />
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="text">Remarks</label>
                <textarea
                  className="form-control"
                  id="remarks"
                  rows="3"
                  placeholder="Remarks"
                  {...register("remarks")}
                  defaultValue={props?.formValues?.remarks}
                ></textarea>
              </div>
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button className="btn btn-lg btn-primary" type="submit">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props?.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
