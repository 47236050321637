import React, {useState, useEffect} from "react";
import {getCookie} from "../../../auth/helpers";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import {Container, Dialog, Slide, TextField, CircularProgress} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";

//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import {MuiThemeProvider} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import ListOfTickets from "./Components/ListOfTickets.js";
import moment from "moment";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Ticket = () => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [openListOfTickets, setOpenListOfTickets] = useState(false); //for modal
  const [listOfTicketsTitle, setListOfTicketsTitle] = useState("");
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [RowID, setRowID] = useState(0); //current row
  const [modal_Title, setmodal_Title] = useState("Add"); //modal title
  const [formTitle, setFormTitle] = useState("Add Ticket");
  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [pagingInfo, setPagingInfo] = useState({page: 0, limit: 20, skip: 0, count: 20}); //Pagination Info
  const [searchEntry, setSearchEntry] = useState(""); //searchEntry
  const [ticketsId, setTicketsId] = useState([]);

  useEffect(() => {
    fetchData();
  }, [open, pagingInfo.page, pagingInfo.limit]);

  const fetchData = async () => {
    await axios(
      `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/tickets?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,
      {
        responseType: "json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      setPagingInfo({...pagingInfo, count: response.data.count});
      setItems(response?.data?.data);
      setItemsBackup(response?.data?.data);
      return setIsLoading(false);
    });
  };

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Batch Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              onClick={() => {
                handleAdd("Edit Ticket - " + tableMeta.rowData[1], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "counts",
      label: "Number of Tickets to Generate",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              onClick={() => {
                handleOpenListOfTickets("List of Tickets - " + tableMeta.rowData[1], tableMeta.rowData[0]);
              }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD MMM YYYY");
        },
      },
    },
    {
      name: "expiry",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD MMM YYYY");
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleAdd("Add Ticket");
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/tickets/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setItems(response?.data?.data);
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
    // serverSide: true,
    // count:pagingInfo.count, // Use total number of items
    // page: pagingInfo.page,
    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPagingInfo({...pagingInfo,page:tableState.page,skip:tableState.page*pagingInfo.limit});
    //   }else if(action === "changeRowsPerPage"){
    //     setPagingInfo({...pagingInfo,limit:tableState.rowsPerPage});
    //   }
    // }
  };

  const handleFilter = () => {
    setFilterDialog(true);
  };

  const handleClickOpen = (rowID = 1, modal_Title = "Add") => {
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, ticketId) => {
    if (ticketId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/tickets/${ticketId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          setFormValues(response.data);
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        name: "",
        date: "",
        expiry: "",
        counts: "",
      });
      setFormTitle(title);
      setOpen(true);
    }

    //setTicketId(ticketId);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenListOfTickets = async (title, id) => {
    setListOfTicketsTitle(title);
    setOpenListOfTickets(true);
    setTicketsId(id);
  };
  const handleCloseListOfTickets = () => {
    setOpenListOfTickets(false);
  };

  //Search component ---------------START--------------
  const handleChangeSearch = (e, newValue) => {
    setSearchEntry(e.target.value);
    // if(newValue.length===0) setItems(itemsBackup); else{
    //   let valueToSearch=[]
    //   newValue.forEach(newValueEntry=>{
    //     valueToSearch.push(...itemsBackup.filter((e,i) => {
    //       if(!valueToSearch.map(eSearch=>eSearch.serial).includes(e.serial)){
    //         if (e.name.toLowerCase().includes(newValueEntry.toLowerCase())){
    //           return true;
    //         }
    //       }
    //     }))
    //   })
    //   setItems(valueToSearch)
    // }
  };
  const handleSubmitSearch = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  const handleSubmitSearchTicketData = (e) => {
    if (e.key === "Enter") {
      fetchTicketData();
    }
  };

  const fetchTicketData = () => {
    axios(
      `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/getTicketDetailData/${searchTicketValue}`,
      {
        responseType: "json",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      setShowTicketDetail(true);
      const data = response.data[0];
      if (data) {
        setTicketData({
          ticket_number: data.ticket_number,
          studentName: data.studentName,
          bookName: data.bookName,
          RegistrationDate: data.registration_date,
        });
      } else {
        setShowTicketDetail(false);
        toast.error("Ticket Number Not Found !");
      }
    });
  };

  const [showTicketDetail, setShowTicketDetail] = useState(false);
  const [searchTicketValue, setSearchTicketValue] = useState("");
  const [ticketData, setTicketData] = useState({
    ticket_number: "",
    studentName: "",
    bookName: "",
    RegistrationDate: "",
  });
  //Search component ---------------END--------------
  return (
    <AdminLayout>
      <div className="ml-5 mr-5 mt-3 mb-3 d-flex">
        <div style={{width: "90%"}}>
          <input
            type="text"
            className="form-control w-100"
            id="searchValue"
            placeholder="Search By Ticket Number Then Hit Enter"
            value={searchTicketValue}
            onChange={(e) => setSearchTicketValue(e.target.value)}
            onKeyUp={handleSubmitSearchTicketData}
          />
        </div>
        <div style={{width: "10%"}}>
          <button
            className="btn btn-success bg-primary accounts-links ml-4 float-right"
            onClick={() => {
              fetchTicketData();
            }}
          >
            Search
          </button>
        </div>
      </div>
      {showTicketDetail ? (
        <div className="ml-5 mr-5 mt-3">
          <div className="p-2 bg-white d-flex" style={{fontSize: "0.7em"}}>
            <div className="w-100">
              <span>Ticket Number: {ticketData.ticket_number}</span>
            </div>
            <div className="w-100">
              <span>Student Name: {ticketData.studentName}</span>
            </div>
            <div className="w-100">
              <span>Book Name: {ticketData.bookName}</span>
            </div>
            <div className="w-100">
              <span>Registration Date: {ticketData.RegistrationDate}</span>
            </div>
            <div className="w-100">
              <button
                className="btn btn-danger accounts-links ml-4 float-right"
                onClick={() => {
                  setShowTicketDetail(false);
                  setSearchTicketValue("");
                }}
              >
                {" "}
                Close{" "}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <ToastContainer />
      <div className="ml-5 mr-5 pt-2">
        {/* <Autocomplete
        multiple
        freeSolo
        limitTags={3}
        id="tags-standard"
        options={[]}
        getOptionLabel={(option) => option}
        onChange={handleChangeSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Data"
            label="Filter by Name"
          />
        )}
      /> */}

        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable
              title={
                <>
                  <input
                    type="text"
                    className="form-control w-100"
                    id="searchValue"
                    placeholder="Search By Ticket Number Then Hit Enter"
                    value={searchEntry}
                    onChange={handleChangeSearch}
                    onKeyUp={handleSubmitSearch}
                  />
                </>
              }
              data={items}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AddFormDialog title={formTitle} handleClose={handleClose} formValues={formValues} />
          </Dialog>
          <Dialog
            fullScreen
            open={openListOfTickets}
            onClose={handleCloseListOfTickets}
            TransitionComponent={Transition}
          >
            <ListOfTickets
              title={listOfTicketsTitle}
              handleClose={handleCloseListOfTickets}
              ticketsId={ticketsId}
            />
          </Dialog>
          {/*********************** FILTER start ****************************/}
          {/* <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} />
        </Dialog> */}
        </div>
      </div>
    </AdminLayout>
  );
};
export default Ticket;
