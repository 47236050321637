import React, {useState, useEffect} from "react";
import {getCookie} from "../../../../auth/helpers";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import CustomToolbar from "../../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import axios from "axios";

const NestedTable = (props) => {
  const token = getCookie("token");
  const [booksList, setBooksList] = useState([]);
  const [allEntries, setAllEntries] = useState(0);
  const add1Row = () => {
    const dateNow = new Date();
    // const dateAfterYear = new Date(
    //   new Date().setFullYear(new Date().getFullYear() + 1)
    // );

    const nextYear = new Date().getFullYear() + 1;

    const dateAfterYear = new Date(nextYear, 7, 15);

    props.setArrayName([
      ...props.arrayName,
      {
        serial: null,
        booksId: "",
        name: "",
        fromDate: moment(dateNow).format("YYYY-MM-DD"),
        toDate: moment(dateAfterYear).format("YYYY-MM-DD"),
      },
    ]);
  };
  const handleChangeName = (e, newValue, currentSerial) => {
    let savedNewEntry = 0;
    if (currentSerial) {
      props.setArrayName(
        props.arrayName.map((e) => {
          if (e.serial === currentSerial) {
            e.booksId = newValue.serial;
            e.name = newValue.name;
          }
          return e;
        })
      );
    } else {
      props.setArrayName(
        props.arrayName.map((e, i) => {
          console.log("e.booksId", e.booksId);
          if (e.serial === null && !e.booksId && savedNewEntry === 0) {
            e.booksId = newValue.serial;
            e.name = newValue.name;
            savedNewEntry = 1;
          }
          if (e.serial === null && e.booksId === newValue.serial) {
            e.booksId = newValue.serial;
            e.name = newValue.name;
          }
          return e;
        })
      );
    }
  };
  const handleChangeTextfield = (e, currentSerial) => {
    const {name, value} = e.target;
    if (currentSerial) {
      props.setArrayName(
        props.arrayName.map((e) => {
          if (e.serial === currentSerial) {
            if (name === "fromDate") e.fromDate = value;
            else if (name === "toDate") e.toDate = value;
          }
          return e;
        })
      );
    } else {
      props.setArrayName(
        props.arrayName.map((e) => {
          if (e.serial === null) {
            if (name === "fromDate") e.fromDate = value;
            else if (name === "toDate") e.toDate = value;
          }
          return e;
        })
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/books`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        const selectedBooksId = props.arrayName.map((e) => parseInt(e.booksId));
        setBooksList(response?.data?.data.filter((e) => !selectedBooksId.includes(e.serial)));
      });
    };
    fetchData();
  }, [props.arrayName]);

  const handleSelectAll = () => {
    const selectedBooksId = props.arrayName.map((e) => parseInt(e.booksId));
    const filteredBooksList = booksList.filter((e) => !selectedBooksId.includes(e.serial)); //to make sure no duplicated data
    props.setArrayName([
      ...props.arrayName,
      ...filteredBooksList.map((e) => ({
        booksId: e.serial,
        fromDate: "",
        name: e.name,
        toDate: "",
      })),
    ]);
    setAllEntries(1);
  };
  const handleRemoveAll = () => {
    props.setArrayName([]);
    setAllEntries(0);
  };

  return (
    <>
      <div className="form-inline my-3">
        {allEntries === 0 ? (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleSelectAll();
            }}
          >
            <i className="fa fa-square"></i> Select All {props.title}
          </a>
        ) : (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleRemoveAll();
            }}
          >
            <i className="fa fa-check-square"></i> Remove All {props.title}
          </a>
        )}
      </div>
      <MUIDataTable
        data={props.arrayName}
        title={props.title}
        columns={[
          {
            name: "serial",
            options: {
              display: false,
            },
          },
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Autocomplete
                    defaultValue={{name: value || ""}}
                    id={`${props.title}Input`}
                    name="name"
                    options={booksList || {}}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, newValue) => handleChangeName(e, newValue, tableMeta.rowData[0])}
                    renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                    renderInput={(params) => <TextField {...params} label={`Add new ${props.title}`} />}
                  />
                );
              },
            },
          },
          {
            name: "fromDate",
            label: "From",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const defaultValue = value ? moment(value).format("YYYY-MM-DD") : "";
                return (
                  <TextField
                    id="fromDate"
                    label="From"
                    name="fromDate"
                    type="date"
                    fullWidth
                    defaultValue={defaultValue}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => handleChangeTextfield(e, tableMeta.rowData[0])}
                  />
                );
              },
            },
          },
          {
            name: "toDate",
            label: "To",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const defaultValue = value ? moment(value).format("YYYY-MM-DD") : "";
                return (
                  <TextField
                    id="toDate"
                    label="To"
                    name="toDate"
                    type="date"
                    fullWidth
                    defaultValue={defaultValue}
                    InputLabelProps={{shrink: true}}
                    onChange={(e) => handleChangeTextfield(e, tableMeta.rowData[0])}
                  />
                );
              },
            },
          },
        ]}
        options={{
          filter: false,
          rowsPerPage: 20,
          rowsPerPageOptions: [20, 50, 100],
          customToolbar: () => {
            return <CustomToolbar listener={add1Row} />;
          },
          textLabels: {
            body: {
              noMatch: !props.isLoading && "Sorry, there is no matching data to display",
            },
          },
          onRowsDelete: (rowsDeleted, dataRows) => {
            const idsToDelete = rowsDeleted.data.map((d) => props.arrayName[d.dataIndex].booksId);
            const rowsToKeep = props.arrayName.filter((e) => !idsToDelete.includes(e.booksId));
            props.setArrayName(rowsToKeep);
          },
        }}
      />
    </>
  );
};

export default NestedTable;
