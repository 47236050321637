import React, {useState, useEffect} from "react";
import {PDFViewer, Page, Text, View, Document, StyleSheet, Image, Link} from "@react-pdf/renderer";
import {CircularProgress} from "@material-ui/core";
// import ReactMomentCountDown from 'react-moment-countdown';
import moment from "moment";
import axios from "axios";

const styles = StyleSheet.create({
  pdfContainer: {
    width: "100%",
    height: "calc(100vh - 80px)",
  },
  page: {
    // fontFamily: 'Roboto',
    fontSize: 10,
    padding: 20,
  },
  tableCont: {
    paddingBottom: 20,
  },
  itemDetails: {
    flexDirection: "row",
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  strong: {
    fontWeight: 600,
    color: "#000",
  },
  logoCont: {
    flexDirection: "row",
    borderBottom: "1 solid #0083C9",
    paddingBottom: 10,
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoContSub1: {
    width: "20%",
  },
  mediaBody: {
    width: "80%",
    borderBottom: "1 solid #999",
    color: "#333",
    paddingBottom: 10,
    marginBottom: 20,
    marginLeft: "10%",
  },
  logoContSub2: {
    width: "80%",
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center+",
  },
  footerSub: {
    borderBottom: "1 solid #333",
    padding: "3 5",
  },
  colorGrey: {
    color: "#666",
  },
  signature: {
    marginTop: 50,
    backgroundColor: "#0083C9",
    padding: "5",
    color: "#fff",
    textAlign: "center",
    position: "fixed",
    bottom: 0,
  },
  header2: {
    fontSize: 20,
    color: "#0083C9",
    marginBottom: 20,
  },
  ml10: {
    marginLeft: 10,
  },
  question: {
    flexDirection: "row",
    marginBottom: 10,
  },
  mb5: {
    marginBottom: 5,
  },
  danger: {
    color: "#dc3545",
  },
  success: {
    color: "#28a745",
  },
});

const StudentsQuizDone = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [studentQuizDetails, setStudentQuizDetails] = useState({});
  const [quizDetails, setQuizDetails] = useState({});
  const [studentDetails, setStudentDetails] = useState({});
  const [questionsList, setQuestionsList] = useState([]);
  const [answersList, setAnswersList] = useState([]);
  const [studentAnswers, setStudentAnswers] = useState([]);
  const [dataToSubmit, setDataToSubmit] = useState([]);
  const [quizTime, setQuizTime] = useState({});
  const studentQuizUrlId = String(props.match.params.quizId); //students_quizzes serial - studentId+quizId+classId

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/students`
          ),
        ])
        .then((response) => {
          setStudentQuizDetails(
            response[0]?.data?.data.find((e) => {
              const calculatedIds = String(Number(e.studentId) + Number(e.quizId) + Number(e.classId));
              return e.serial + calculatedIds === studentQuizUrlId;
            })
          );
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (studentQuizDetails !== {}) {
        const studentId = studentQuizDetails.studentId;
        const quizId = studentQuizDetails.quizId;
        const classId = studentQuizDetails.classId;
        await axios
          .all([
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/myclass/students/byClassId/${classId}`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/quizs/${quizId}`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/quiz/question/${quizId}`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/answers`
            ),
            axios.get(
              `${
                window?.location?.hostname?.includes("maroc")
                  ? process.env.REACT_APP_API_MAROC
                  : process.env.REACT_APP_API
              }/quiz/students/answer/${studentQuizDetails.serial}`
            ),
          ])
          .then((response) => {
            setStudentDetails(response[0].data.find((e) => e.serial == studentId));
            setQuizDetails(response[1].data);
            setQuizTime({
              quizDate: moment(response[1].data.fromDate).format("DD MMM YYYY"),
              startTime: moment(response[1].data.fromDate).format("HH:mm"),
              endTime: moment(response[1].data.toDate).format("HH:mm"),
            });
            setQuestionsList(response[2].data);
            setDataToSubmit(
              response[2].data.length
                ? response[2].data.map((e) => ({
                    questionId: e.serial,
                    students_quizId: studentQuizDetails.serial,
                  }))
                : []
            );
            setAnswersList(response[3]?.data?.data);
            setStudentAnswers(response[4].data);
            setIsLoading(false);
          });
      }
    };
    fetchData();
  }, [studentQuizDetails]);

  return (
    <>
      <PDFViewer style={styles.pdfContainer}>
        <Document>
          <Page style={styles.page} wrap>
            <View style={styles.logoCont} fixed>
              <View style={styles.logoContSub1}>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo.jpg`}
                  alt=""
                  style={{width: 150}}
                />
              </View>
              <View style={styles.logoContSub2}>
                <View style={styles.row}>
                  <Text>{studentDetails ? studentDetails.name : ""} scored:&nbsp; </Text>
                  <Text style={styles.danger}>
                    {studentQuizDetails.mark}/
                    {questionsList.length ? questionsList.map((e) => e.mark).reduce((a, b) => a + b) : null}
                  </Text>
                </View>

                <Text>
                  from: {quizTime.startTime} - to: {quizTime.endTime}
                </Text>
              </View>
            </View>
            <View style={styles.body}>
              <View style={styles.header2}>
                <Text>Quiz: {quizDetails.name}</Text>
              </View>
              <View style={styles.listContainer}>
                {questionsList.length ? (
                  questionsList.map((e) => {
                    const questionAnswers = answersList.length
                      ? answersList.filter((answer) => answer.question_id === e.question_id)
                      : [];
                    const currentStudentAnswer = studentAnswers
                      ? studentAnswers.find((eSub) => eSub.questionId === e.serial)
                      : {};
                    const submittedAnswer =
                      questionAnswers && currentStudentAnswer
                        ? questionAnswers.find((e) => e.serial === currentStudentAnswer.answerId)
                        : {};
                    const correctAnswer = questionAnswers ? questionAnswers.find((e) => e.correct === 1) : {};

                    return (
                      <View style={styles.itemDetails}>
                        {/* <View style={styles.logoContSub1}>
                        <Image src={`${process.env.REACT_APP_FILES_PATH}/Questions/${e.image}`} alt="" style={{width:100}} />
                      </View> */}
                        <View style={styles.mediaBody}>
                          <View style={styles.question}>
                            <Text style={styles.strong}>Question: </Text>
                            <Text>{e.question} </Text>
                            <Text
                              style={
                                submittedAnswer && submittedAnswer.correct > 0
                                  ? styles.success
                                  : styles.danger
                              }
                            >
                              {" "}
                              ({currentStudentAnswer ? currentStudentAnswer.mark : 0}/{e.mark})
                            </Text>
                          </View>
                          <View style={styles.ml10}>
                            <Text style={styles.mb5}>
                              Submitted Answer: {submittedAnswer ? submittedAnswer.answer : null}{" "}
                              {submittedAnswer && submittedAnswer.correct > 0 ? (
                                <Text style={styles.success}>True</Text>
                              ) : (
                                <Text style={styles.danger}>False</Text>
                              )}
                            </Text>
                            <Text style={styles.mb5}>
                              Correct Answer: {correctAnswer ? correctAnswer.answer : null}
                            </Text>
                            <Text style={styles.mb5}>
                              Additional Notes: {currentStudentAnswer ? currentStudentAnswer.notes : ""}
                            </Text>
                          </View>
                        </View>
                      </View>
                    );
                  })
                ) : (
                  <View style={{...styles.header2, ...styles.textCenter}}>
                    <Text>No Questions Available</Text>
                  </View>
                )}
              </View>

              {studentQuizDetails.mark === null ? (
                <View style={{...styles.header2, ...styles.textCenter}}>
                  <Text>Quiz not completed!</Text>
                </View>
              ) : null}
            </View>
            <View style={styles.signature} fixed>
              <Text>
                © 2021 LINFOPOURTOUS. By <Link src="http://www.dsoft-lb.com/">D S O F T</Link>
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      {/*       
      
      <div className="container">

        
        <div className="row my-5">
          <h2 className="text-primary mb-5">
            Quiz: {quizDetails ? quizDetails.name : ""} 
          </h2>
          {studentQuizDetails.mark!=null?
          <div className="col-12">
            <ul className="list-unstyled">
              {questionsList.length ? (
                questionsList.map((e) => {
                  const questionAnswers = answersList.length? answersList.filter((answer) => answer.question_id === e.question_id): [];
                  const currentStudentAnswer=studentAnswers?studentAnswers.find(eSub=>eSub.questionId===e.serial):{}
                  const submittedAnswer=questionAnswers&&currentStudentAnswer?questionAnswers.find(e=>e.serial===currentStudentAnswer.answerId):{}
                  const correctAnswer=questionAnswers?questionAnswers.find(e=>e.correct===1):{}


                  return (
                    <>
                      <li className="media">
                        <img
                          className="mr-3"
                          width="100"
                          src={
                            process.env.REACT_APP_FILES_PATH +
                            "/Questions/" +
                            e.image
                          }
                          alt="Generic placeholder image"
                        />
                        <div className="media-body">
                          <h5 className="mb-3 text-primary">Question: {e.question} <span className="text-danger">({currentStudentAnswer?currentStudentAnswer.mark:0}/{e.mark})</span></h5>

                          <p className="ml-5">
                            <strong>Submitted Answer:</strong> {submittedAnswer?submittedAnswer.answer:null} {submittedAnswer&&submittedAnswer.correct>0?<i className="fas fa-check text-success"></i>:<i className="fas fa-times text-danger"></i>}
                            <br/>
                            <strong>Correct Answer:</strong> {correctAnswer?correctAnswer.answer:null}<br/>
                            <strong>Additional Notes:</strong> {currentStudentAnswer?currentStudentAnswer.notes:""}
                          </p>
                          </div>
                      </li>
                      <li className="dropdown-divider my-4"></li>
                    </>
                  );
                })
              ) : (
                <li>No Questions Available</li>
              )}
            </ul>
            </div>:null}
              
          {studentQuizDetails.mark===null?<h2 className="text-center m-5">Quiz not completed!</h2>:null}

        </div>
      </div>

       */}
    </>
  );
};
export default StudentsQuizDone;
