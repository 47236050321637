import React, {useState, useEffect} from "react";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Container, Dialog, Slide, TextField, Chip, CircularProgress} from "@material-ui/core";
import {getCookie} from "../../../auth/helpers";
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";

//import FilterComponent from "components/CustomComponents/FilterComponent.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyClass = (props) => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [formTitle, setFormTitle] = useState("Add My Class");
  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [pagingInfo, setPagingInfo] = useState({page: 0, limit: 20, skip: 0, count: 20}); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry
  const [teachersList, setTeachersList] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const [gradesList, setGradesList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.count});
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [open, pagingInfo.page, pagingInfo.limit, searchEntry]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/teachers`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/grades`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/students`,
            {headers: {Authorization: `Bearer ${token}`}}
          ),
        ])
        .then((response) => {
          setTeachersList(response[0]?.data?.data);
          setGradesList(response[1]?.data?.data);
          setStudentsList(response[2]?.data?.data);
        });
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <a
                href="#"
                onClick={(e) => e.preventDefault()}
                onClick={() => {
                  handleAdd("Edit My Class - " + tableMeta.rowData[1], tableMeta.rowData[0]);
                }}
              >
                {value}
              </a>
            </div>
          );
        },
      },
    },
    {
      name: "teacher_id",
      label: "Teacher",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const teacher = teachersList.filter((e) => e.serial === value);
          return teacher.length ? teacher[0].name : "";
        },
      },
    },
    {
      name: "grade_id",
      label: "Grade",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const grade = gradesList.filter((e) => e.serial === value);
          return grade.length ? grade[0].name : "";
        },
      },
    },
    {name: "school"},
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleAdd("Add My Class");
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/myclass/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, myclassId) => {
    if (myclassId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass/${myclassId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          setFormValues(response.data);
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        teacher_id: null,
        grade_id: null,
        school: "",
      });
      setFormTitle(title);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Search component ---------------END--------------
  return (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5 pt-5">
        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AddFormDialog
              title={formTitle}
              handleClose={handleClose}
              formValues={formValues}
              setFormValues={setFormValues}
              teachersList={teachersList}
              studentsList={studentsList}
              gradesList={gradesList}
            />
          </Dialog>
        </div>
      </div>
    </AdminLayout>
  );
};
export default MyClass;
