import React, {useState, useEffect} from "react";
import CustomToolbar from "../../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../../components/datatable-theme.js";
import {Container, Dialog, Slide, TextField, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import {getCookie} from "../../../../auth/helpers";
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";

import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuizDetails = (props) => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); //for modal
  const [formTitle, setFormTitle] = useState("Add Questions");
  const [filterDialog, setFilterDialog] = useState(false);
  const [itemsBackup, setItemsBackup] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [pagingInfo, setPagingInfo] = useState({page: 0, limit: 20, skip: 0, count: 20}); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry
  const [chaptersList, setChaptersList] = useState([]);
  const [answersList, setAnswersList] = useState([]);
  const [quizStudentsList, setQuizStudentsList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/quiz/students/byquiz/${props.serial}`
          ),
        ])
        .then((response) => {
          setQuizStudentsList(response[0].data);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .all([
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/answers`
          ),
          axios.get(
            `${
              window?.location?.hostname?.includes("maroc")
                ? process.env.REACT_APP_API_MAROC
                : process.env.REACT_APP_API
            }/no-auth/chapters`
          ),
        ])
        .then((response) => {
          setAnswersList(response[0]?.data?.data);
          setChaptersList(
            response[1]?.data?.data.length
              ? response[1]?.data?.data.filter((e) => e.serial == props.chapterId)
              : []
          );
        });
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "serial",
      options: {display: false},
    },
    {
      name: "quizId",
      options: {display: false},
    },
    {
      name: "studentId",
      options: {display: false},
    },
    {
      name: "classId",
      options: {display: false},
    },
    {name: "name", label: "Student Name"},
    {name: "email", label: "Student Email"},
    {
      name: "mark",
      label: "Status",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <strong className="text-success">Completed</strong>
          ) : (
            <strong className="text-danger">Not done</strong>
          );
        },
      },
    },
    {name: "mark", label: "Mark"},
    {
      name: "mark",
      label: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const quizIdForLink =
            String(tableMeta.rowData[0]) +
            (Number(tableMeta.rowData[1]) + Number(tableMeta.rowData[2]) + Number(tableMeta.rowData[3]));
          return value ? (
            <a
              href={`${process.env.PUBLIC_URL}/student/quiz/done/${quizIdForLink}`}
              target="_blank"
              title="View Completed Quiz"
            >
              <i className="fas fa-eye text-primary"></i>
            </a>
          ) : null;
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: pagingInfo.limit,
    rowsPerPageOptions: [20, 50, 100],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleAdd("Add Questions");
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/questions/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setItems(response?.data?.data.filter((e) => e.chapter_id + "" === props.chapterId));
          setItemsBackup(response?.data?.data.filter((e) => e.chapter_id + "" === props.chapterId));
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
  };
  const handleFilter = () => {
    setFilterDialog(true);
  };

  const [formValues, setFormValues] = useState({});
  const handleAdd = async (title, questionsId) => {
    if (questionsId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/questions/${questionsId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          setFormValues(response.data);
          setFormTitle(title);
        })
        .then((response) => {
          setOpen(true);
        });
    } else {
      setFormValues({
        image: "",
        question: "",
        chapter_id: "",
      });
      setFormTitle(title);
      setOpen(true);
    }

    //setWhyusId(questionsId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Search component ---------------END--------------
  return (
    <>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <ToastContainer />
      <div className="m-5 pt-5">
        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={quizStudentsList} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}
      </div>
    </>
  );
};
export default QuizDetails;
