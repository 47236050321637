import React, {useState, useEffect} from "react";
import {getCookie} from "../../../auth/helpers";
import AdminLayout from "../../../core/AdminLayout";
import CustomToolbar from "../../../components/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {datatableTheme} from "../../../components/datatable-theme.js";
import {Container, Dialog, Slide, TextField, CircularProgress} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {ToastContainer, toast} from "react-toastify";

// import FilterComponent from "components/CustomComponents/FilterComponent.js";
import AddFormDialog from "./Components/AddFormDialog.js";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Students = () => {
  const token = getCookie("token");
  const [isLoading, setIsLoading] = useState(true);
  const [openAddForm, setOpenAddForm] = useState(false); //for modal
  const [studentId, setStudentId] = useState(); //modal title
  const [formTitle, setFormTitle] = useState("Add"); //modal title
  const [ticketsList, setTicketsList] = useState([]);
  const [items, setItems] = useState([]); //table items
  const [itemsBackup, setItemsBackup] = useState([]);
  const [pagingInfo, setPagingInfo] = useState({page: 0, limit: 20, skip: 0, count: 20}); //Pagination Info
  const [searchEntry, setSearchEntry] = useState([]); //searchEntry
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/students?limit=${pagingInfo.limit}&skip=${pagingInfo.skip}&searchEntry=${searchEntry}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setPagingInfo({...pagingInfo, count: response.data.count});
        setItems(response?.data?.data);
        setItemsBackup(response?.data?.data);
        return setIsLoading(false);
      });
    };
    fetchData();
  }, [openAddForm, pagingInfo.page, pagingInfo.limit, searchEntry]);

  useEffect(() => {
    const fetchData = async () => {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/tickets/details/all`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        setTicketsList(response.data);
      });
    };
    fetchData();
  }, []);

  const columns = [
    {
      name: "serial",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <a
                href="#"
                onClick={(e) => e.preventDefault()}
                onClick={() => {
                  handleAdd(`Edit Student - ${value}`, tableMeta.rowData[0]);
                }}
              >
                {value}
              </a>
            </div>
          );
        },
      },
    },
    {
      name: "Ticket's Number",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const ticket = ticketsList ? ticketsList.filter((e) => e.student_id === tableMeta.rowData[0]) : [];
          return ticket.length ? ticket[0].ticket_number : "";
        },
      },
    },
    {name: "email"},
    {name: "school"},
    {name: "phone"},
    {name: "notes"},
    {name: "login_count", label: "Login Counts"},
    {name: "last_login", label: "Last Login"},
    {
      name: "verified",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          value === 1 ? (
            <i style={{position: "relative", left: "35%"}} className="fas fa-check text-success"></i>
          ) : (
            <i style={{position: "relative", left: "35%"}} className="fas fa-times text-danger"></i>
          ),
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 300,
    rowsPerPageOptions: [300, 400, 500],
    selectToolbarPlacement: "replace",
    customToolbar: () => {
      return (
        <CustomToolbar
          listener={() => {
            handleAdd("Add New Student");
          }}
        />
      );
    },
    onRowsDelete: (rowsDeleted, dataRows) => {
      const idsToDelete = rowsDeleted.data.map((d) => items[d.dataIndex].serial); // array of all ids to to be deleted
      axios
        .delete(
          `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/students/${idsToDelete}`,
          {
            responseType: "json",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setItems(response?.data?.data);
          toast.success(response.data.Message);
        });
    },
    textLabels: {
      body: {
        noMatch: !isLoading && "Sorry, there is no matching data to display",
      },
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    // serverSide: true,
    // count:pagingInfo.count, // Use total number of items
    // page: pagingInfo.page,
    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPagingInfo({...pagingInfo,page:tableState.page,skip:tableState.page*pagingInfo.limit});
    //   }else if(action === "changeRowsPerPage"){
    //     setPagingInfo({...pagingInfo,limit:tableState.rowsPerPage});
    //   }
    // }
  };

  const handleAdd = async (title, studentId) => {
    if (studentId) {
      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/students/${studentId}`,
        {
          responseType: "json",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          setFormValues(response.data);
          setFormTitle(title);
        })
        .then((response) => {
          setOpenAddForm(true);
        });
    } else {
      setFormValues({
        name: "",
        email: "",
        password: "",
        school: "",
        phone: "",
        notes: "",
        grade: "",
        books: [],
      });
      setFormTitle(title);
      setOpenAddForm(true);
    }

    //setBookId(bookId);
  };
  const handleCloseAddForm = () => setOpenAddForm(false);

  //Search component ---------------START--------------
  const handleChangeSearch = (e, newValue) => {
    if (newValue.length === 0) setItems(itemsBackup);
    else {
      let valueToSearch = [];
      newValue.forEach((newValueEntry) => {
        valueToSearch.push(
          ...itemsBackup.filter((e, i) => {
            if (!valueToSearch.map((eSearch) => eSearch.serial).includes(e.serial)) {
              if (e.name.toLowerCase().includes(newValueEntry.toLowerCase())) {
                return true;
              }
            }
          })
        );
      });
      setItems(valueToSearch);
    }
  };
  //Search component ---------------END--------------
  return (
    <AdminLayout>
      <ToastContainer />
      <div className="m-5 pt-5">
        {/* <Autocomplete
        multiple
        freeSolo
        limitTags={3}
        id="tags-standard"
        options={[]}
        getOptionLabel={(option) => option}
        onChange={handleChangeSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Data"
            label="Filter by Name"
          />
        )}
      /> */}

        {!isLoading ? (
          <MuiThemeProvider theme={datatableTheme}>
            <MUIDataTable title="" data={items} columns={columns} options={options} />
          </MuiThemeProvider>
        ) : (
          <CircularProgress size={30} className="pageLoader" />
        )}

        <div>
          <Dialog fullScreen open={openAddForm} onClose={handleCloseAddForm} TransitionComponent={Transition}>
            <AddFormDialog
              title={formTitle}
              handleClose={handleCloseAddForm}
              studentId={studentId}
              formValues={formValues}
            />
          </Dialog>
          {/*********************** FILTER start ****************************/}
          {/* <Dialog
          onClose={() => setFilterDialog(false)}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={filterDialog}
        >
          <FilterComponent setOpenDialog={setFilterDialog} />
        </Dialog> */}
        </div>
      </div>
    </AdminLayout>
  );
};
export default Students;
