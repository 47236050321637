import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";

import {toast} from "react-toastify";

import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormGroup,
  Grid,
  Input,
  Modal,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {getCookie} from "../../../auth/helpers";

const ImportExcelModal = (props) => {
  const token = getCookie("token");

  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [file, setFile] = useState("");

  const handleChangeFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFile(file);
    };
  };

  const saveForm = () => {
    if (file) {
      setSaveIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", props.importExcelFor);
      formData.append("book_id", props.bookID);
      props.setImporting(true);
      axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/upload/books/excel`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          props.setImporting(false);

          if (response.data.success == 1) {
            toast.success(`Uploaded successfully`);
            props.onClose(true);
          } else {
            toast.error(response.data.error_message);
          }
          setSaveIsLoading(false);
        })
        .catch((error) => {
          props.setImporting(false);
          setSaveIsLoading(false);
          console.log(error);
        });
    } else {
      toast.error(`Please Fill All The Fields`);
    }
  };

  const exportExcel = () => {
    window
      .open(
        `${process.env.REACT_APP_FILES_PATH}/BooksExcel/${
          props.importExcelFor == "links" ? "template2" : "template1"
        }.xlsx`,
        "_blank"
      )
      .focus();
  };

  useEffect(() => {
    if (props.visible) {
      setFile("");
    }
  }, [props.visible]);

  return (
    <div>
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">
            {props.title} for {props.importExcelForTitle}
          </h4>
        </Toolbar>
      </AppBar>
      <div className="row m-5 justify-content-center">
        <div className="col-9 mb-4">
          <div className="form-group">
            <input
              type="file"
              className="form-control"
              id="file"
              placeholder="Excel"
              onChange={handleChangeFile}
            />
          </div>
        </div>

        <div className="col-3 mb-4">
          <button
            type="button"
            className="btn btn-lg btn-secondary"
            onClick={() => {
              exportExcel();
            }}
          >
            Download Template
          </button>
        </div>
      </div>
      <div className="row m-5">
        <div className="col-3 mb-4">
          <button type="button" className="btn btn-lg btn-primary" onClick={saveForm}>
            Upload Excel
          </button>
        </div>
      </div>
    </div>
  );
};
ImportExcelModal.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    visible: PropTypes.node,
    raceID: PropTypes.node,
    onClose: PropTypes.node,
  }).isRequired,
}).isRequired;

ImportExcelModal.displayName = "ImportExcelModal";
export default ImportExcelModal;
