import React, {useState, useEffect} from "react";
import {TextField, AppBar, Toolbar} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ToastContainer, toast} from "react-toastify";
import {getCookie} from "../../../../auth/helpers";
import {useForm} from "react-hook-form";
import NestedTable from "./NestedTable.js";
import axios from "axios";

const AddFormDialog = (props) => {
  const token = getCookie("token");
  const [students, setStudents] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const {register, handleSubmit, reset} = useForm();
  const [teacher, setTeacher] = useState([]);
  const [grade, setGrade] = useState([]);
  const [rowStudents, setRowStudents] = useState({});
  const [addMore, setAddMore] = useState(0);
  const [API1submitted, setAPI1submitted] = useState(0);
  const [myclassId, setMyclassId] = useState(props.formValues.serial);

  useEffect(() => {
    const defaultTeacher = props.teachersList.filter((e) => e.serial === props.formValues.teacher_id);
    setTeacher(defaultTeacher.length ? defaultTeacher[0] : {});
    const defaultGrade = props.gradesList.filter((e) => e.serial === props.formValues.grade_id);
    setGrade(defaultGrade.length ? defaultGrade[0] : {});
    setStudents(
      props.formValues.myclass_students
        ? props.formValues.myclass_students.map((e) => {
            e.status = "old";
            return e;
          })
        : []
    );
  }, []);
  const handleOnSubmit = async (data, e) => {
    if (props.formValues.serial) {
      await axios({
        method: "put",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass/${props.formValues.serial}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, teacher_id: teacher.serial, grade_id: grade.serial},
      })
        .then((response) => {
          props.setFormValues({});
          setAPI1submitted(1);
          if (e.nativeEvent.submitter.name === "submitAndClose") props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/myclass`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {...data, teacher_id: teacher.serial, grade_id: grade.serial},
      })
        .then((response) => {
          setMyclassId(response.data.serial);
          props.setFormValues({});
          setAPI1submitted(1);
          if (e.nativeEvent.submitter.name === "submitAndClose") props.handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // return console.log("students",students)
      if (API1submitted === 1) {
        const dataToAdd = students
          .filter((e) => e.status === "new" && e.name != "")
          .map((e) => {
            delete e.serial;
            delete e.status;
            e.myclass_id = myclassId;
            return e;
          });

        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/myclass/students`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {data: dataToAdd},
        })
          .then((response) => {
            props.setFormValues({});
            reset({name: "", school: ""});
            setGrade([]);
            setTeacher([]);
            setStudents([]);
            setAPI1submitted(0);
            toast.success(response.data.message);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    fetchData();
  }, [API1submitted]);
  return (
    <>
      <ToastContainer />
      <AppBar className="position-relative">
        <Toolbar>
          <button type="button" className="close" onClick={props.handleClose}>
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">{props.title}</h4>
        </Toolbar>
      </AppBar>
      <div style={{padding: "50px"}}>
        <form onSubmit={handleSubmit(handleOnSubmit)} encType="multipart/form-data">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="teacher"
                  fullWidth
                  options={props.teachersList}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={(event, newValue) => setTeacher({serial: newValue.serial, name: newValue.name})}
                  value={teacher}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Teacher" placeholder="Teacher" />
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Autocomplete
                  id="grade"
                  fullWidth
                  options={props.gradesList}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={(event, newValue) => setGrade({serial: newValue.serial, name: newValue.name})}
                  value={grade}
                  filterSelectedOptions
                  renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Grade" placeholder="Grade" />
                  )}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name")}
                  defaultValue={props.formValues.name}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="school">School</label>
                <input
                  type="text"
                  className="form-control"
                  id="school"
                  placeholder="School"
                  {...register("school")}
                  defaultValue={props.formValues.school}
                />
              </div>
            </div>
            <div className="col-12 my-5">
              <NestedTable
                document="students"
                arrayName={students}
                setArrayName={setStudents}
                rowData={rowStudents}
                setRowData={setRowStudents}
                studentsList={props.studentsList}
                title="Students"
              />
            </div>
            <div className="col-12 d-flex flex-row-reverse">
              <button type="button" className="btn btn-lg btn-primary" type="submit" name="submitAndAdd">
                <i className="fas fa-save"></i> Save and Add more
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-primary" type="submit" name="submitAndClose">
                <i className="fas fa-save"></i> Save
              </button>{" "}
              &nbsp;&nbsp;
              <button type="button" className="btn btn-lg btn-secondary" onClick={props.handleClose}>
                <i className="fas fa-times"></i> Close
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFormDialog;
