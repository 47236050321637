import React, {useState, useEffect} from "react";
import Layout from "../../core/Layout";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {ToastContainer, toast} from "react-toastify";
import {CircularProgress} from "@material-ui/core";
import axios from "axios";
import {countries} from "../../data/countries";
const Register1Frontend = ({history}) => {
  const [gradesList, setGradesList] = useState([]);
  const [grades, setGrades] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allGrades, setAllGrades] = useState(0);
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);
  const [countriesList, setCountriesList] = useState(countries);

  const gender = [{name: "Male"}, {name: "Female"}];

  const {register, handleSubmit, watch} = useForm({
    defaultValues: {
      type: "student",
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      if (history.location.state && history.location.state.error) toast.error(history.location.state.error);

      await axios(
        `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/get_grades`,
        {
          responseType: "json",
        }
      ).then((response) => {
        setGradesList(response.data.grades);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  const verifyRecaptcha = async (e) => {
    if (e) {
      setRecaptchaSuccess(true);
    }
    let result = await axios(
      {
        method: "post",
        url: "https://www.google.com/recaptcha/api/siteverify",
        params: {
          secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
          response: e,
        },
      },
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      }
    );
    let data = result.data || {};
    if (!data.success) {
      throw {
        success: false,
        error: "response not valid",
      };
    }
  };

  const handleOnSubmit = async (data) => {
    data = {...data, name: data.firstName + " " + data.lastName};

    if (watch("type") === "student") {
      await axios({
        method: "post",
        url: `${
          window?.location?.hostname?.includes("maroc")
            ? process.env.REACT_APP_API_MAROC
            : process.env.REACT_APP_API
        }/validateTicket`,
        data: {ticket_number: data.ticketNumber},
      })
        .then((response) => {
          if (response.data.valid === true) {
            history.push({
              pathname: "/register2",
              state: data,
            });
          } else {
            toast.error("Ticket number not valid");
          }
        })
        .catch((error) => {
          toast.error(error.response.data);
        });
    } else {
      if (grades.length) {
        let gradesArray = grades.map((e) => e.serial);
        await axios({
          method: "post",
          url: `${
            window?.location?.hostname?.includes("maroc")
              ? process.env.REACT_APP_API_MAROC
              : process.env.REACT_APP_API
          }/user/new`,
          data: {...data, grades: gradesArray},
        })
          .then((response) => {
            history.push({
              pathname: "/register3",
              state: data,
            });
          })
          .catch((error) => {
            toast.error(error.response.data.msg);
          });
      } else {
        toast.error("Grade is required");
      }
    }
  };
  const handleError = (errors) => {
    // if (errors.name) toast.error(errors.name.message);
    if (errors.firstName) toast.error(errors.firstName.message);
    else if (errors.lastName) toast.error(errors.lastName.message);
    if (errors.gender) toast.error(errors.gender.message);
    else if (errors.email) toast.error(errors.email.message);
    else if (errors.password) toast.error(errors.password.message);
    else if (errors.rePassword) toast.error(errors.rePassword.message);
    else if (errors.school) toast.error(errors.school.message);
    else if (errors.country) toast.error(errors.country.message);
    else if (errors.phone) toast.error(errors.phone.message);
    else if (errors.reEmail) toast.error(errors.reEmail.message);
  };
  const validationOptions = {
    // name: { required: "Name is required" },
    firstName: {required: "First Name is required"},
    lastName: {required: "Last Name is required"},
    gender: {required: "Gender is required"},
    email: {
      required: "Email is required",
      pattern: {
        value: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
        message: "Enter a valid email address",
      },
    },
    reEmail: {
      required: "Re-Type Email is required",
      validate: (e) => e === watch("email") || "Email fields don't match",
    },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
    rePassword: {
      required: "Retype Password",
      validate: (e) => e === watch("password") || "Password fields don't match",
    },
    school: {required: "School is required"},
    country: {required: "Country is required"},
  };
  //Search component ---------------END--------------
  return (
    <Layout>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        hideProgressBar={true}
        closeOnClick
      />
      {!isLoading ? (
        <div className="container pt-5 mt-5">
          <div id="register1" className="py-5 my-5">
            <div className="d-flex flex-column pt-5 mt-5">
              <form onSubmit={handleSubmit(handleOnSubmit, handleError)}>
                <div className="text-center">
                  <h3 className="text-secondary mb-0">Register book</h3>
                  <h2 className="text-primary mb-5 pb-5">Register a book and start learning!</h2>
                </div>
                <div className="row">
                  <div className="col-sm-4 mb-4 d-flex">
                    <div className="form-check px-5">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="typeTeacher"
                        value={"teacher"}
                        {...register("type")}
                      />
                      <label className="form-check-label" htmlFor="typeTeacher">
                        Teacher
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="typeStudent"
                        value={"student"}
                        {...register("type")}
                        defaultChecked={true}
                      />
                      <label className="form-check-label" htmlFor="typeStudent">
                        Student
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-md-center">
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder=""
                        {...register("firstName", validationOptions.firstName)}
                      />
                      <label htmlFor="firstName">First Name*</label>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder=""
                        {...register("lastName", validationOptions.lastName)}
                      />
                      <label htmlFor="lastName">Last Name*</label>
                    </div>
                  </div>
                  {watch("type") === "teacher" ? (
                    <div className="col-sm-4 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="registerPhone"
                          placeholder=""
                          {...register("phone", {
                            required: "Phone is required",
                          })}
                        />
                        <label htmlFor="registerPhone">Phone (Int. format)*</label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-sm-4 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="registerPhone"
                          placeholder=""
                          {...register("phone")}
                        />
                        <label htmlFor="registerPhone"> Phone (Int. format)*</label>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="registerEmail"
                        placeholder=""
                        {...register("email", validationOptions.email)}
                      />
                      <label htmlFor="registerEmail">Email *</label>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="reTypeRegisterEmail"
                        placeholder=""
                        {...register("reEmail", validationOptions.reEmail)}
                      />
                      <label htmlFor="reTypeRegisterEmail">Re-Type Email *</label>
                    </div>
                  </div>

                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="registerCountry"
                        {...register("country", validationOptions.country)}
                      >
                        <option selected>Open a country</option>
                        {countriesList.map((e) => (
                          <option value={e.name} key={e.serial}>
                            {e.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="registerCountry">Country *</label>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="password"
                        className="form-control"
                        id="registerPassword"
                        placeholder=""
                        {...register("password", validationOptions.password)}
                      />
                      <label htmlFor="registerPassword">Password*</label>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="password"
                        className="form-control"
                        id="registerPassword2"
                        placeholder=""
                        {...register("rePassword", validationOptions.rePassword)}
                      />
                      <label htmlFor="registerPassword2">Re-Type password*</label>
                    </div>
                  </div>

                  {watch("type") === "teacher" && (
                    <div className="col-sm-4 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="registerGender"
                          {...register("gender", validationOptions.gender)}
                        >
                          <option selected>Select a gender</option>
                          {gender.map((e) => (
                            <option value={e.name} key={e.serial}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="registerGender">Gender *</label>
                      </div>
                    </div>
                  )}

                  <div className="col-sm-4 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="registerSchoolName"
                        placeholder=""
                        {...register("school", validationOptions.school)}
                      />
                      <label htmlFor="registerSchoolName">School name (Full Description) *</label>
                    </div>
                  </div>
                  {watch("type") === "student" ? (
                    <div className="col-sm-4 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="registerTicketnumber"
                          placeholder=""
                          {...register("ticketNumber")}
                        />
                        <label htmlFor="registerTicketnumber">Ticket number *</label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-sm-4 mb-4">
                        <div className="form-group">
                          <Autocomplete
                            multiple
                            id="grades"
                            fullWidth
                            options={gradesList}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              const newValuesId = newValue.map((e) => e.serial);
                              setGradesList(gradesList.filter((e) => !newValuesId.includes(e.serial)));

                              setGrades(
                                newValue.map((e) => ({
                                  serial: e.serial,
                                  name: e.name,
                                }))
                              );
                            }}
                            value={grades}
                            filterSelectedOptions
                            renderOption={(option) => <div style={{fontSize: 13}}>{option.name}</div>}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" label="Grades" placeholder="Grades" />
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-sm-4 mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="assistant_director"
                            placeholder=""
                            {...register("assistant_director")}
                          />
                          <label htmlFor="assistant_director">Name of the Academic Director</label>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-sm-4 mb-4" id="recaptcha">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={verifyRecaptcha}
                    />
                  </div>

                  {watch("type") === "student" ? (
                    <div className="col-sm-4">
                      {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
                        <button className="btn btn-secondary" type="submit">
                          Check ticket validation
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn btn-secondary"
                        type="submit"
                        style={{width: "100%", lineHeight: 2.5}}
                        disabled={recaptchaSuccess ? false : true}
                      >
                        Check ticket validation
                      </button>
                    </div>
                  ) : (
                    <div className="col-sm-4">
                      {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
                        <button className="btn btn-secondary" type="submit">
                          Submit
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn btn-secondary"
                        type="submit"
                        style={{width: "100%", lineHeight: 2.5}}
                        disabled={recaptchaSuccess ? false : true}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
    </Layout>
  );
};
export default Register1Frontend;
