import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {authenticate, isAuth} from "./helpers";
import AdminLayout from "../core/AdminLayout";
import axios from "axios";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Signin = ({history}) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    buttonText: "Submit",
  });
  const {email, password, buttonText} = values;

  const handleChange = (name) => (event) => {
    setValues({...values, [name]: event.target.value});
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({...values, buttonText: "Submitting"});
    axios({
      method: "POST",
      url: `${
        window?.location?.hostname?.includes("maroc")
          ? process.env.REACT_APP_API_MAROC
          : process.env.REACT_APP_API
      }/login`,
      data: {email, password},
    })
      .then((response) => {
        authenticate(response, () => {
          setValues({
            ...values,
            name: "",
            email: "",
            password: "",
            buttonText: "Submitted",
          });
          const user = isAuth();
          user && user.role === "admin" && user.profile == 1
            ? history.push("/admin/users")
            : user && user.role === "admin" && user.profile == 2
            ? history.push("/admin/representativeVisits")
            : history.push("/");
        });
      })
      .catch((error) => {
        setValues({...values, buttonText: "Submit"});
        toast.error(error?.response?.data?.error);
      });
  };

  const signinForm = () => (
    <form>
      <div className="form-group mb-3">
        <label className="text-muted">Email</label>
        <input onChange={handleChange("email")} value={email} type="email" className="form-control" />
      </div>

      <div className="form-group mb-3">
        <label className="text-muted">Password</label>
        <input
          onChange={handleChange("password")}
          value={password}
          type="password"
          className="form-control"
        />
      </div>
      <div>
        <button className="btn btn-primary" onClick={clickSubmit}>
          {buttonText}
        </button>
      </div>
    </form>
  );
  // <Link to="/" className="nav-link d-inline-block sidebar_pageTitle" style={{padding:"0 45px"}}><img src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo.jpg`} alt="" /></Link>
  return (
    <AdminLayout>
      <div className="col-md-6 offset-md-3">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          newestOnTop={false}
          hideProgressBar={true}
          closeOnClick
        />
        {isAuth() && isAuth().role === "admin" ? <Redirect to="/" /> : null}

        <h3 className="p-5 text-center">
          <Link to="/" className="nav-link d-inline-block sidebar_pageTitle" style={{padding: "0 45px"}}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/LinfoPourTous_logo_admin.jpg`} alt="" />
          </Link>
          <br />
          <br />
          Sign in
        </h3>
        {signinForm()}
      </div>
    </AdminLayout>
  );
};

export default Signin;
